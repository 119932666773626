import omit from 'lodash/omit';
import omitBy from 'lodash/omitBy';
import map from 'lodash/map';
import * as QueryString from 'query-string';
import { getAhApiPing } from '../store/api/ah-api';
import { LoginResponse } from '../survey-tool/declarations/auth-api';
import { getConfigAlwaysHttps } from './app-config';
import { logError } from './log';

const HTTPS_PROTOCOL = 'https:';
const getLocation = () => window?.location ?? {};
const getProtocol = () => (getConfigAlwaysHttps() ? HTTPS_PROTOCOL : getLocation().protocol || HTTPS_PROTOCOL);

export function isPureprofileComDomain(url: string) {
  return /^https:\/\/[^/]+\.pureprofile\.com\//.test(url);
}

export function stripProtocol(url: string) {
  if (typeof url !== 'string') {
    return url;
  }
  if (url.startsWith('https://')) {
    return url.slice('https://'.length);
  }
  if (url.startsWith('http://')) {
    return url.slice('http://'.length);
  }
  return url;
}

export function ensureProtocol(url: string, protocol: string = getProtocol()): string {
  if (!url) {
    return url;
  }
  const hasProtocol = /^https?:/.test(url);
  if (hasProtocol) {
    return url;
  }
  const p = protocol || getProtocol();
  const hasTwoSlashes = url.startsWith('//');
  if (hasTwoSlashes) {
    return p + url;
  }
  const hasOneSlash = url.startsWith('/');
  if (hasOneSlash) {
    return `${p}//${getLocation().host}${url}`;
  }
  // hasNoSlashes
  return `${p}//${url}`;
}

// NOTE: this doesn't return query mark (?)
export function queryObjToString(obj: any): string {
  return QueryString.stringify(omitBy(obj, (val: any) => val == null || val === ''));
}

export function getQueryObj() {
  return QueryString.parse(getLocation().search) as Record<string, string | undefined>;
}

export function setQueryObj(obj: any): void {
  const location = getLocation();
  const queryString = QueryString.stringify(obj);
  try {
    window.history.replaceState(
      {},
      '',
      [
        location.protocol,
        '//',
        location.host,
        location.pathname,
        queryString ? `?${queryString}` : '',
        location.hash,
      ].join('')
    );
  } catch (err: any) {
    logError(err);
  }
}

export function isWindowRootUrl(): boolean {
  return window.location.pathname === '/';
}

export function isWindowOnboardingUrl(): boolean {
  return window.location.pathname === '/onboarding' || window.location.pathname.startsWith('/onboarding/');
}

export function hasOnboardingParametersInQuery(): boolean {
  const query = getQueryObj();
  return !!(query.provider && query.userId);
}

export function joinSearchObj(obj: any) {
  const arr: string[] = [];
  for (const key in obj) {
    if (obj[key]) {
      arr.push(`${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`);
    }
  }
  return arr.join('&');
}

export function addQuery(url: string, obj: any) {
  if (!url) {
    return url;
  }
  const joinChar = url.includes('?') ? '&' : '?';
  const queryStrToAdd = joinSearchObj(obj);
  return url + joinChar + queryStrToAdd;
}

export function preserveWindowQuery(url: string, addToQuery?: Record<string, string>) {
  if (!url) {
    return url;
  }
  const hasQuery = url.includes('?');
  if (!hasQuery && !addToQuery) {
    return url + window.location.search;
  }
  const joinChar = hasQuery ? '&' : '?';
  const queryInUrl = QueryString.parseUrl(url);
  const keysInUrl = Object.keys(queryInUrl.query);
  const queryInWindow = QueryString.parse(window.location.search);
  if (addToQuery) {
    for (const key of Object.keys(addToQuery)) {
      if (!queryInWindow[key]) {
        queryInWindow[key] = addToQuery[key];
      }
    }
  }
  const queryInWindowWithoutKeysInUrl = omit(queryInWindow, keysInUrl);
  const queryStr = map(
    queryInWindowWithoutKeysInUrl,
    (val, key) => `${encodeURIComponent(key)}=${encodeURIComponent(val as any)}`
  ).join('&');
  return queryStr ? `${url}${joinChar}${queryStr}` : url;
}

export async function checkAndUseQueryRedirect(loginResponse: LoginResponse): Promise<boolean> {
  const { redirect } = getQueryObj();
  if (!redirect || !isPureprofileComDomain(redirect)) {
    return false;
  }
  // ping ah-api with the token, so it can save its cookies, this is imporant for redirecting to external payment urls
  if (loginResponse) {
    await getAhApiPing(loginResponse);
  }
  // redirect to the url
  redirectIframeSafe(redirect);
  return true;
}

export function redirectIframeSafe(url: string) {
  const isInIframe = window.self !== window.top;
  if (isInIframe) {
    try {
      window.top!.location.href = url;
    } catch (ignoreErr) {
      window.open(url);
    }
  } else {
    window.location.href = url;
  }
}
