import * as types from '../actions/action-types';
import { SurveyThemeAction } from '../actions/survey-theme-actions';
import { PartialSurveyToolStyleObject } from '../../survey-tool/default-styles/style-declaration';
import SurveyToolDefaultStyleDefinition from '../../survey-tool/default-styles/default';

export function surveyThemeLoadedReducer(state = false, action: SurveyThemeAction) {
  switch (action.type) {
    case types.LOAD_SURVEY_THEME_NOT_FOUND:
    case types.LOAD_SURVEY_THEME_CSS:
      return true;
    default:
      return state;
  }
}

export function surveyThemeReducer(
  state: PartialSurveyToolStyleObject = SurveyToolDefaultStyleDefinition,
  action: SurveyThemeAction
): PartialSurveyToolStyleObject {
  switch (action.type) {
    case types.LOAD_SURVEY_THEME_CSS:
      return action.surveyTheme ? action.surveyTheme : state;
    default:
      return state;
  }
}
