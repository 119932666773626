/* eslint-disable no-param-reassign */

import i18n from 'i18next';
import { initReactI18next, useTranslation, UseTranslationOptions } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import Axios from 'axios';
import debounce from 'lodash/debounce';
import { logError } from './utils/log';
import { appEnv, authApiHost } from './app-config';

const appName = 'pureprofile-app' as const;
export type Namespace = typeof appName | 'survey-tool' | 'languages';

interface AuthApiLanguage {
  code: string;
  fallback_code?: string;
  name: string;
}

const EnglishLanguage: AuthApiLanguage = { code: 'en', name: 'English' };

const i18nextReloadResources = debounce(() => i18n.reloadResources(), 5000);

function initI18N(languages: AuthApiLanguage[]) {
  i18n
    // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    // learn more: https://github.com/i18next/i18next-http-backend
    // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
      ns: appName,
      lowerCaseLng: true, // we store them like this in the db
      supportedLngs: languages.map((l) => l.code),
      fallbackLng: (code: string | undefined) => {
        const language = languages.find((l) => l.code === code);
        if (!language) {
          // default to the first language returned by auth-api
          return languages[0].fallback_code ?? languages[0].code;
        }
        return language.fallback_code ?? [];
      },
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
      debug: appEnv === 'development',
      saveMissing: true,
      saveMissingTo: 'fallback',
      backend: {
        loadPath: `${authApiHost}/api/v1/translations/load/{{ns}}/{{lng}}`,
        addPath: `${authApiHost}/api/v1/translations/add/{{ns}}/{{lng}}`,
        crossDomain: true,
        withCredentials: true,
        customHeaders: {
          'X-App-Name': appName,
        },
        parsePayload: (namespace: any, key: any, fallbackValue: any) => {
          i18nextReloadResources();
          // log.info(`missing namespace=${namespace} key=${key} fallbackValue=${fallbackValue}`);
          return { [key]: fallbackValue || '' };
        },
      },
    });
}

const domain = window.location.hostname;
Axios.get<AuthApiLanguage[]>(
  `${authApiHost}/api/v1/translations/languages/supported?domain=${encodeURIComponent(domain)}`
)
  .then((response) => response.data)
  .catch((err) => {
    logError(err);
    return [] as AuthApiLanguage[];
  })
  .then((supportedLngs) => {
    if (supportedLngs.length === 0) {
      supportedLngs.push(EnglishLanguage);
    }
    initI18N(supportedLngs);
  });

export default i18n;

export function useAppTranslation(ns?: Namespace, options?: UseTranslationOptions<any>) {
  return useTranslation(ns, options);
}
