export function formatDateShort(date: string, locale: string) {
  const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric' };
  const newDate = new Date(date).toLocaleDateString(locale, options);
  return newDate.replace(',', '');
}

export function formatMonth(month: number, year: number, locale: string) {
  const newDate = new Date(year, month, 15);
  const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long' };
  return newDate.toLocaleDateString(locale, options);
}

export function addDays(date: string, days: number) {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + days);
  return newDate.toISOString().split('T')[0];
}

export function formatDateYMD(year: number, month: number, day: number) {
  const date = new Date(year, month - 1, day + 1);
  return date.toISOString().split('T')[0];
}

export function getCurrentMonthName(locale: string) {
  return new Date().toLocaleString(locale, { month: 'long' });
}

export function stripTime(date: Date) {
  return date.setHours(0, 0, 0, 0);
}

export function getDaysDifference(date1: Date | string | null, date2: Date | string | null) {
  let d1: Date;
  if (typeof date1 === 'string') {
    d1 = new Date(date1);
  } else if (date1 == null) {
    d1 = new Date();
  } else {
    d1 = date1;
  }

  let d2: Date;
  if (typeof date2 === 'string') {
    d2 = new Date(date2);
  } else if (date2 == null) {
    d2 = new Date();
  } else {
    d2 = date2;
  }

  const timeDiff = d2.getTime() - d1.getTime();
  return Math.ceil(timeDiff / (1000 * 3600 * 24));
}
