import { AdminApiSurveyTheme } from './admin-api';

/* eslint camelcase:0 */

export interface CampaignInsightResult {
  count: number;
  percentage: number;
  text: string;
  uuid: string;
  selectedByUser?: boolean;
}

export interface CampaignInsight {
  active?: boolean;
  image?: {
    guid: string;
    url: string;
    height: number;
    width: number;
  };
  question_guid?: string;
  question_text?: string;
  results?: CampaignInsightResult[];
  segments?: number;
  show_percentages?: boolean;
  total_responses?: number;
  type?: string;
}

type DISQUALIFY_REASON =
  | 'cookie-match'
  | 'client-fingerprint-match'
  | 'server-fingerprint-match'
  | 'vpn-detected'
  | 'out-of-campaign-region';

export interface CampaignThankYou {
  campaign_type: CampaignType;
  message?: string;
  has_rating?: boolean;
  image?: {
    url: string;
  };
  competition?: Competition;
  competition_entry?: CompetitionEntryPublicJson;
  transaction?: Transaction;
  insight?: CampaignInsight;
  skip_thank_you?: boolean;
  is_welcome?: boolean;
  text?: string;
  tracking_pixel?: string;
  disqualify_reason?: DISQUALIFY_REASON;
  thankyou_template?: CampaignThankYouTemplate;
  theme?: InvitationThankYouTheme;
  followup_campaign_guid?: string;
  // this url can be configured globally on a campaign
  completion_url?: string;
  // these urls can be configured directly on a payment
  open_in_new_tab?: string;
  redirect_url?: string;
  // speciality for onboarding flow
  is_final_onboarding_campaign?: boolean;
}

export interface CampaignThankYouTemplate {
  texts?: Array<{
    participation_status: ExtendedParticipationStatusName;
    name?: string;
    text?: string;
    callout?: string;
  }>;
}

export interface Competition {
  competition_entry_only: boolean;
  confirmation_message: string;
  confirmation_title: string;
  image: string;
  image_url: string;
  invitation_message: string;
  invitation_title: string;
  landing_page_url: string;
  terms_conditions_url: string;
  ticket_value: number;
  tickets_for_entry: number;
  uuid: string;
}

export interface CompetitionEntryPublicJson {
  uuid: string;
  confirmation_title: string;
  confirmation_message: string;
  image_url?: string;
  tickets_for_entry: number;
}

interface Transaction {
  createdAt: string;
  uuid: string;
  value: number;
}

interface InvitationThankYouTheme {
  color_secondary?: string;
  font_color_primary?: string;
  font_color_secondary?: string;
}

export interface CampaignInvitation {
  callOut: string;
  duration: number | null;
  endDate?: string;
  image: CampaignInvitationImage;
  isPaid: boolean;
  name: string;
  payoutMax: number;
  payoutMin: number;
  questionsLength: number | null;
  questionsMax: number | null;
  questionsMin: number | null;
  startDate?: string;
  text: string;
  theme?: InvitationThankYouTheme;
}

export interface CampaignInvitationImage {
  url: string;
}

export interface CampaignIcon {
  url: string;
  guid: string;
  mime: string;
  width: number;
  height: number;
}

export interface CampaignCategory {
  hex: string;
  guid: string;
  name: string;
  text: string;
  type: string;
  action_text: string;
  icon: CampaignIcon;
}

export type CampaignType = 'cpost' | 'esurvey' | 'gav' | 'gemail' | 'gvv' | 'gwv' | 'poll' | 'pupd' | 'simple' | 'next';

export type ParticipationStatusName =
  | 'started'
  | 'screened'
  | 'skipped'
  | 'completed'
  | 'viewed'
  | 'shown_invite'
  | 'qualified'
  | 'quota_full'
  | 'disqualified'
  | 'suppressed';

export type ExtendedParticipationStatusName = ParticipationStatusName | 'unavailable' | 'expired';

export const ParticipationFinalStatuses: ExtendedParticipationStatusName[] = [
  'completed',
  'screened',
  'quota_full',
  'qualified',
  'disqualified',
  'skipped',
  'unavailable',
  'expired',
  'suppressed',
];

export const isFinalParticipationStatus = (status?: ExtendedParticipationStatusName) =>
  status ? ParticipationFinalStatuses.includes(status) : false;

// this is common result for both listing and detail services
// if we need to add to this interface, we need to edit ah-api appropriately
export interface CampaignCommon {
  adminId?: number;
  partnerId?: string; // for campaigns supplied by thirdparty vendors (cint)
  guid: string;
  type: CampaignType;
  is_welcome: boolean;
  category: CampaignCategory;
  survey_theme?: string;
  invitation: CampaignInvitation;
  participationStatus?: ParticipationStatusName; // only if participation exists
  repeatable?: boolean;
  shouldResume?: boolean; // might not be present
  goResult?: CampaignGoResult; // attached for some campaign types only
  // true when user has reported this campaign as broken and he shouldn't be able to do anything with it
  reportedBroken?: boolean;
  projectType: ProjectType;
  typeOfCampaign: TypeOfCampaign;
  // dev info
  demandSource?: string;
  isPmp?: boolean;
  buyerId?: string | number;
}

type ProjectType = 'full_service' | 'sample_only' | 'platform' | null;

export type TypeOfCampaign =
  | 'ad_hoc'
  | 'tracking'
  | 'brand_impact'
  | 'mobile_diary'
  | 'multistage_study'
  | 'website_survey'
  | 'poll'
  | null;

// this is listing from /api/v1/:instanceCode/campaigns/paged service
export interface CampaignListing extends CampaignCommon {
  priority: number;
  skip_invitation: boolean;
  position: number; // position of the campaign in the feed
  subaccountUuid?: string; // if subaccount was applied
  subaccountName?: string; // if subaccount was applied
  participationStatusDate?: string; // only if participation exists
  transactionPayout?: number; // if any transaction is saved against this campaign
  userRating?: number; // rating given by this user
  avgRating?: number; // average rating for the campaign
  is_final_onboarding_campaign?: true; // sometimes sent in onboarding routine
}

export interface CampaignsData {
  campaigns: CampaignListing[];
  page: number;
  pageSize: number;
  pages: number;
  results: number;
}

// this is detail from /api/v1/:instanceCode/campaigns/:campaignUuid service
export interface CampaignDetail extends CampaignCommon {
  checkLocation: boolean;
  skip_invitation: boolean;
  payout: number;
  platforms: string[];
  public_url: string;
  questionsLength: number;
  target: {
    is_welcome: boolean;
    startdate: string;
    enddate?: string;
  };
  videoid?: string;
  survey_theme?: string;
  survey_theme_obj?: AdminApiSurveyTheme;
}

// this is result from /api/v1/:instanceCode/campaigns/:campaignGuid/go service
export interface CampaignGoResult {
  campaign_status: ExtendedParticipationStatusName | undefined;
  action?: CampaignAction;
  payout?: number;
  progress?: CampaignProgress;
  question?: CampaignQuestion;
  thank_you?: CampaignThankYou;
  follow?: string;
  refresh_profile?: true; // notify front-end to reload profile after getting this
}

export interface CampaignAction {
  type: 'dobblk'; // add more types here if required
  age?: number; // provided by dobblk action
}

export interface CampaignProgress {
  answered: number;
  questions: number;
  total: number;
}

export interface CampaignQuestion {
  auto_advance_on_column_selection: boolean | null;
  backward_motion_in_grid: boolean | null;
  display_grid_completion_screen: boolean | null;
  actions?: CampaignQuestionAction[];
  guid: string;
  type: string;
  control_type: string;
  allow_decimal: boolean;
  hide_answer_text?: boolean;
  name: string;
  text: string;
  descr: string;
  answers: CampaignQuestionAnswer[];
  grid_questions?: CampaignQuestion[];
  videoid: string;
  image?: CampaignImage;
  char_limit: number;
  min_num: number;
  max_num: number;
  image_type: string;
  max_fields: number;
  max_chars: number;
  min_chars: number;
  min_answers?: number;
  max_answers?: number;
  html: string;
  scale_type: string;
  scale_starts_from_zero: boolean | null;
  scale_label_display: string;
  min_date: number;
  max_date: number;
  date_type: string;
  freq_type: CampaignQuestionFreqType;
  freq_period: string[];
  freq_min: number;
  freq_max: number;
  spend_period: string[];
  spend_min: number;
  spend_max: number;
  order: number;
}

export type CampaignQuestionFreqType = 'single' | 'minmax';

export interface CampaignQuestionAction {
  type: string;
  url?: string;
  age?: number;
  campaign_question_guid: string;
  question_guid: string;
}

export interface CampaignImage {
  url: string;
  height: number;
  width: number;
}

export interface CampaignQuestionAnswer {
  guid: string;
  text: string;
  order: number;
  has_text: boolean;
  is_exclusive?: boolean;
  image: CampaignImage;
}
