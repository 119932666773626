import * as types from '../actions/action-types';
import initialState from './initial-state';
import { RedemptionAvailabityAction } from '../actions/redemption-actions';

interface RedemptionsState {
  minimum: number;
}
export default function redemptionAvailabilityReducer(
  state: RedemptionsState = initialState.availability,
  action: RedemptionAvailabityAction
) {
  switch (action.type) {
    case types.LOAD_REDEMPTIONS_AVAILABILITY_SUCCESS:
      return action.availability;

    default:
      return state;
  }
}
