/* eslint-disable */
import { FC } from 'react';
import '../../styles/css/index.scss';
import './error500.scss';
import { spacing } from '../../survey-tool/styles/settings';
import decorator from '../../styles/style.settings.decorator';
import { useAppNavigate } from '../../utils/browser-navigate';
import { useAppSelector } from '../../store';
import { PPButton } from '../../survey-tool/components/pp-button';
import { useAppTranslation } from '../../i18n';
import { getQueryObj } from '../../utils/uri';

interface Error500Props {
  title?: string;
}

const Error500: FC<Error500Props> = (props) => {
  const { t } = useAppTranslation();
  const navigate = useAppNavigate();
  const panelInfo = useAppSelector((state) => state.panelInfo ?? {});
  const colorCta = panelInfo?.app_theme?.color_cta || '#1AC0B4';
  const query = getQueryObj();
  const title = query.title || props.title || t('defaultErrorTitle', 'Oops! An unknown error occurred!');
  const description =
    query.desc ??
    query.description ??
    t('pleaseTryAgain', 'Please try again. If the issue persists please contact support.');
  const btnText = query.btn ?? query.btnText ?? t('takeMeBackToMyFeed', 'Take me back to my feed');
  return (
    <div id="error-500">
      <div className="error-container">
        <div className="error-message">
          <svg id="sad-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 260 94" style={styles.svg}>
            <path
              className="path path-1"
              d="M54.9 5.9h-45L6.7 42.7l3.1-.6c6.2-1.1 11-1.7 14.5-1.7 8.9 0 15.9 2.1 21 6.3 4.9 4.2 7.4 9.8 7.4 17.2 0 8.5-2.5 14.9-7.7 19.6-5.2 4.7-12.6 7-22 7-4.2 0-8.3-.4-12.1-1.3-3.2-.7-5.9-1.6-8.1-2.8l-1.2-.6"
              fill="none"
              stroke={colorCta}
              strokeDasharray="0,209.15419036183297"
              strokeDashoffset="208.9452451167"
              strokeMiterlimit="10"
            />
            <path
              className="path path-2"
              d="M213 3.5c-24.024 0-43.5 19.476-43.5 43.5s19.476 43.5 43.5 43.5 43.5-19.476 43.5-43.5S237.024 3.5 213 3.5z"
              fill="none"
              stroke={colorCta}
              strokeDasharray="0,273.6295901790341"
              strokeDashoffset="273.3562339451"
              strokeMiterlimit="10"
            />
            <path
              className="path path-3"
              d="M78 75.3C71.4 67.7 67.5 57.8 67.5 47 67.5 23 87 3.5 111 3.5S154.5 23 154.5 47 135 90.5 111 90.5c-7.1 0-13.9-1.7-19.8-4.8"
              fill="none"
              stroke={colorCta}
              strokeDasharray="0,256.65755694410393"
              strokeDashoffset="256.4011557883"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
            />
            <path
              className="path path-4"
              xmlns="http://www.w3.org/2000/svg"
              d="M129 65.3c-4.5-4.9-11-8-18.2-8-7.2 0-13.6 3.1-18.1 7.9"
              fill="none"
              stroke={colorCta}
              strokeWidth="6"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
            />
          </svg>
          <h3 style={styles.title}>{title}</h3>
          <h4 style={styles.description}>{description}</h4>
          <PPButton
            onClick={() => navigate('/')}
            sx={{
              margin: '20px 0',
              backgroundColor: panelInfo?.app_theme?.color_cta || decorator.getStyle().highlight,
              boxShadow: `0 2px 0 0 ${decorator.LightenDarkenColor(
                panelInfo?.app_theme?.color_cta || decorator.getStyle().highlight,
                -20
              )}`,
            }}
          >
            {btnText}
          </PPButton>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    fontSize: '1.4rem',
    fontWeight: 600,
    ':nth-child(1n) > *': {
      marginBottom: spacing.space4,
    },
  },
  svg: {
    maxWidth: '330px',
  },
  title: {
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '24px',
    textAlign: 'center',
    margin: '20px 0',
  },
  description: {
    fontSize: '16px',
    fontWeight: 300,
    lineHeight: '22px',
    textAlign: 'center',
    marginBottom: '60px',
  },
} as const;

export default Error500;
