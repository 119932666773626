import { UserBalanceResponse } from '../../declarations/ah-api';
import * as types from '../actions/action-types';

export default function userBalanceReducer(
  state: UserBalanceResponse = null as any as UserBalanceResponse,
  action: any
): UserBalanceResponse {
  switch (action.type) {
    case types.LOAD_USER_BALANCE_SUCCESS:
      return {
        ...state,
        ...(action.userBalance as UserBalanceResponse),
      };

    case types.UPDATE_USER_BALANCE:
      return {
        ...state,
        balance: action.userBalance as number,
      };

    default:
      return state;
  }
}
