import * as types from '../actions/action-types';
import { PaypalAction } from '../actions/paypal-actions';

export type PaypalEmail = string | null;

export default function paypalReducer(state: PaypalEmail = null, action: PaypalAction): PaypalEmail {
  switch (action.type) {
    case types.LOAD_PAYPAL_EMAIL_SUCCESS: {
      return action.email;
    }
    default:
      return state;
  }
}
