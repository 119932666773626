import * as types from '../actions/action-types';
import initialState from './initial-state';
import { AjaxStatusActions } from '../actions/ajax-status-actions';

const success = [
  'LOAD_CAMPAIGNS_SUCCESS',
  'LOAD_MORE_CAMPAIGNS_SUCCESS',
  'LOAD_TRANSACTIONS_SUCCESS',
  'LOAD_TRANSACTIONS_MONTH_SUCCESS',
  'GET_COMPETITIONS_FULFILLED',
  'GET_REDEMPTIONS_FULFILLED',
  'GET_OFFERS_AND_GAMES_TRANSACTIONS_FULFILLED',
];

function actionTypeEndsInSuccess(type: string) {
  return success.includes(type);
}

export default function ajaxStatusReducer(state: number = initialState.ajaxCallsInProgress, action: AjaxStatusActions) {
  if (action.type === types.BEGIN_AJAX_CALL) {
    return state + 1;
  }
  if (action.type === types.AJAX_CALL_ERROR || actionTypeEndsInSuccess(action.type)) {
    return state - 1;
  }

  return state;
}
