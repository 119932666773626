import { useMemo, FC } from 'react';
import { ThemeProvider, createTheme, ThemeOptions } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { lighten, darken, PaletteColor } from '@mui/material';
import { orange } from '@mui/material/colors';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import { useSurveyContextSelector } from './survey-context';
import { DeepReadonly } from './declarations/deep-readonly';
import { PanelInfo } from './declarations/ah-api';

declare module '@mui/material/styles' {
  interface Palette {
    wbBackgroundColor: Palette['primary'];
    wbHeaderColor: Palette['primary'];
    wbBtnColor: Palette['primary'];
    cbBackgroundColor: Palette['primary'];
    cbHeaderColor: Palette['primary'];
    cbPrimary: Palette['primary']; // cbBtnColor
    cbSecondary: Palette['primary'];
  }
  interface PaletteOptions {
    wbBackgroundColor: PaletteOptions['primary'];
    wbHeaderColor: PaletteOptions['primary'];
    wbBtnColor: PaletteOptions['primary'];
    cbBackgroundColor: PaletteOptions['primary'];
    cbHeaderColor: PaletteOptions['primary'];
    cbPrimary: PaletteOptions['primary']; // cbBtnColor
    cbSecondary: PaletteOptions['primary'];
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    cbPrimary: true;
    cbSecondary: true;
  }
  interface ButtonClasses {
    containedCbPrimary: ButtonClasses['containedPrimary'];
    containedCbSecondary: ButtonClasses['containedSecondary'];
  }
}

export const SurveyThemeProvider: FC = ({ children }) => {
  const panelInfo = useSurveyContextSelector((sc) => sc.args.panelInfo);
  const theme = useMemo(() => createTheme(getThemeOptionsFromPanelInfo(panelInfo)), [panelInfo]);
  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>{children}</LocalizationProvider>
    </ThemeProvider>
  );
};

const ppDefaults = {
  wb_background_color: '#FFFFFF', // white by default
  wb_header_text_color: '#1DC9BC', // primary color
  wb_regular_text_color: '#000000', // black by default
  wb_button_color: '#1DC9BC', // usually same as primary color
  wb_button_text_color: '#FFFFFF', // white by default, needs to contrast with wb_button_color

  cb_background_color: '#475370', // secondary color
  cb_header_text_color: '#1DC9BC', // color of header texts on colored background
  cb_regular_text_color: '#FFFFFF', // color of regular texts on colored background
  cb_button_color: '#1DC9BC', // color of a button used on colored background
  cb_button_text_color: '#FFFFFF', // needs to contrast with cb_button_color
};

export const getThemeOptionsFromPanelInfo = (panelInfo: DeepReadonly<PanelInfo> | null | undefined): ThemeOptions => {
  const wbBackgroundColor = panelInfo?.app_theme?.wb_background_color || ppDefaults.wb_background_color;
  const wbHeaderColor = panelInfo?.app_theme?.wb_header_text_color || ppDefaults.wb_header_text_color;
  const wbTextColor = panelInfo?.app_theme?.wb_regular_text_color || ppDefaults.wb_regular_text_color;

  const wbBtnColor = panelInfo?.app_theme?.wb_button_color || ppDefaults.wb_button_color;
  const wbBtnTextColor = panelInfo?.app_theme?.wb_button_text_color || ppDefaults.wb_button_text_color;

  const cbBackgroundColor = panelInfo?.app_theme?.cb_background_color || ppDefaults.cb_background_color;
  const cbHeaderColor = panelInfo?.app_theme?.cb_header_text_color || ppDefaults.cb_header_text_color;
  const cbTextColor = panelInfo?.app_theme?.cb_regular_text_color || ppDefaults.cb_regular_text_color;

  const cbBtnColor = panelInfo?.app_theme?.cb_button_color || ppDefaults.cb_button_color;
  const cbBtnTextColor = panelInfo?.app_theme?.cb_button_text_color || ppDefaults.cb_button_text_color;
  const secondaryColor = panelInfo?.app_theme.color_secondary || '#4169E1';

  const tonalOffset = 0.15;
  const fontSize = 14;
  const themeOptions: ThemeOptions = {
    typography: {
      fontFamily: '"Open Sans", sans-serif',
      fontSize,
      fontWeightMedium: 600,
    },
    palette: {
      primary: {
        main: wbHeaderColor,
        contrastText: wbBackgroundColor,
      },
      secondary: {
        main: cbBackgroundColor,
        contrastText: cbTextColor,
      },
      wbBackgroundColor: {
        light: lighten(wbBackgroundColor, tonalOffset),
        main: wbBackgroundColor,
        dark: darken(wbBackgroundColor, tonalOffset),
        contrastText: wbTextColor,
      },
      wbHeaderColor: {
        light: lighten(wbHeaderColor, tonalOffset),
        main: wbHeaderColor,
        dark: darken(wbHeaderColor, tonalOffset),
        contrastText: wbBackgroundColor, // since text will be used on this background, it should contrast with it
      },
      wbBtnColor: {
        light: lighten(wbBtnColor, tonalOffset),
        main: wbBtnColor,
        dark: darken(wbBtnColor, tonalOffset),
        contrastText: wbBtnTextColor,
      },
      cbBackgroundColor: {
        light: lighten(cbBackgroundColor, tonalOffset),
        main: cbBackgroundColor,
        dark: darken(cbBackgroundColor, tonalOffset),
        contrastText: cbTextColor,
      },
      cbHeaderColor: {
        light: lighten(cbHeaderColor, tonalOffset),
        main: cbHeaderColor,
        dark: darken(cbHeaderColor, tonalOffset),
        contrastText: cbBackgroundColor, // since text will be used on this background, it should contrast with it
      },
      cbPrimary: {
        light: lighten(cbBtnColor, tonalOffset),
        main: cbBtnColor,
        dark: darken(cbBtnColor, tonalOffset),
        contrastText: cbBtnTextColor,
      },
      cbSecondary: {
        light: lighten(cbBtnColor, tonalOffset),
        main: cbBtnColor,
        dark: darken(cbBtnColor, tonalOffset),
        contrastText: cbBtnTextColor,
      },
      error: {
        main: '#F22C44',
        contrastText: '#FFFFFF',
      },
      warning: {
        main: orange.A400,
      },
      info: {
        main: secondaryColor,
        contrastText: '#FFFFFF',
      },
      success: {
        main: '#2DCC70',
        contrastText: '#FFFFFF',
      },
      tonalOffset,
    },
    components: {
      MuiAutocomplete: {
        styleOverrides: {
          inputRoot: {
            marginTop: '8px',
            '&.MuiOutlinedInput-root': {
              padding: 0,
            },
          },
        },
      },
      MuiButton: {
        defaultProps: {
          disableFocusRipple: true,
          disableRipple: true,
          disableTouchRipple: true,
          variant: 'contained',
        },
        styleOverrides: {
          root: {
            fontSize,
            textTransform: 'none',
            boxShadow: 'none',
            '&:hover': {
              boxShadow: 'none',
            },
            '&.Mui-disabled': {
              opacity: 0.5,
              cursor: 'not-allowed',
              pointerEvents: 'auto',
            },
          },
          containedPrimary: ({ theme }) => containedBtn(theme.palette.wbBtnColor),
          containedSecondary: ({ theme }) => containedBtn(reverseColor(theme.palette.wbBtnColor, tonalOffset)),
          containedCbPrimary: ({ theme }) => containedBtn(theme.palette.cbPrimary),
          containedCbSecondary: ({ theme }) => containedBtn(reverseColor(theme.palette.cbPrimary, tonalOffset)),
        },
      },
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
          disableTouchRipple: true,
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            // unset shadow on hover
            '&:hover': {
              backgroundColor: 'unset',
            },
            '&.Mui-checked:hover': {
              backgroundColor: 'unset',
            },
            border: '0px',
            padding: '9px',
            '&:focus': {
              border: '1px solid',
              padding: '8px',
            },
          },
          colorPrimary: ({ theme }) => ({
            color: theme.palette.wbBtnColor.main, // unchecked color
            '&.Mui-checked': {
              color: theme.palette.info.main, // checked color
            },
          }),
          colorSecondary: ({ theme }) => ({
            color: theme.palette.secondary.main, // unchecked color
            '&.Mui-checked': {
              color: theme.palette.secondary.main, // checked color
            },
          }),
        },
      },
      MuiTextField: {
        defaultProps: {
          autoComplete: 'off',
          margin: 'none',
          variant: 'outlined',
        },
      },
      MuiInput: {
        defaultProps: {
          autoComplete: 'off',
          disableUnderline: true,
          spellCheck: false,
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          outlined: {
            position: 'initial',
            transform: 'initial',
          },
        },
      },
      MuiSelect: {
        defaultProps: {
          variant: 'outlined',
          IconComponent: ExpandMoreTwoToneIcon,
          MenuProps: {
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            style: {
              marginTop: '1px',
              maxHeight: '400px',
            },
          },
        },
      },
      MuiSwitch: {
        styleOverrides: {
          root: {
            display: 'flex',
            width: '36px',
            height: '20px',
            padding: 0,
          },
          switchBase: ({ theme }) => ({
            padding: 2,
            '&.Mui-checked': {
              color: theme.palette.wbBtnColor.contrastText,
              transform: 'translateX(16px)',
              '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.wbBtnColor.main,
                borderColor: theme.palette.wbBtnColor.main,
                opacity: 1,
              },
            },
          }),
          thumb: {
            // this is the moving circle
            width: '16px',
            height: '16px',
            boxShadow: 'none',
          },
          track: {
            // this is style when unchecked
            borderColor: '#CBCBD1',
            backgroundColor: '#CBCBD1',
            borderWidth: '1px',
            borderStyle: 'solid',
            borderRadius: 20 / 2,
            opacity: 1,
          },
        },
      },
    },
  };
  return themeOptions;
};

const reverseColor = (clr: PaletteColor, tonalOffset: number): PaletteColor => ({
  main: clr.contrastText,
  light: lighten(clr.contrastText, tonalOffset),
  dark: darken(clr.contrastText, tonalOffset / 2),
  contrastText: clr.main,
});

const containedBtn = (clr: PaletteColor) => ({
  backgroundColor: clr.main,
  color: clr.contrastText,
  '&:hover': {
    backgroundColor: clr.light,
    color: clr.contrastText,
  },
  '&.Mui-disabled': {
    backgroundColor: clr.main,
    color: clr.contrastText,
    '&:hover': {
      backgroundColor: clr.main,
      color: clr.contrastText,
    },
  },
  '&.PP-loading': {
    color: clr.main,
    '&:hover': {
      color: clr.main,
    },
  },
  '&.PP-loading .MuiCircularProgress-root': {
    color: clr.contrastText,
    position: 'absolute',
  },
});
