/* eslint-disable react/jsx-no-useless-fragment */

import { FC, useEffect } from 'react';
import { useAppTranslation } from './i18n';
import { useAppSelector } from './store';
import { getQueryObj, setQueryObj } from './utils/uri';

export const LanguageChecker: FC = ({ children }) => {
  const { i18n } = useAppTranslation();
  const userProfile = useAppSelector((s) => s.userProfile);

  // lang in query
  const query = getQueryObj();
  if (query.lang) {
    if (i18n.language !== query.lang) {
      i18n.changeLanguage(query.lang);
    }
    delete query.lang;
    setQueryObj(query);
  }

  // lang in profile
  useEffect(() => {
    if (userProfile?.languageCode && i18n.language !== userProfile.languageCode) {
      i18n.changeLanguage(userProfile.languageCode);
    }
  }, [i18n, userProfile?.languageCode]);

  return <>{children}</>;
};
