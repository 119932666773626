import { clr } from './settings';

export const headerHeight = 60;

export const footerHeight = 60;

export const link = {
  color: `${clr.link} !important`,
  ':hover': {
    cursor: 'pointer',
    textDecoration: 'underline',
  },
};

export const input = {
  border: `1px solid ${clr.dimBase2}`,
  'border-radius': '5px',
  'font-weight': '300',
  outline: '0',
  padding: '10px',
  width: '100%',
};
