// start:react-app-polyfill
// these must be the first lines in src/index.js
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
// end:react-app-polyfill

// https://www.i18next.com/translation-function/plurals
// https://github.com/eemeli/intl-pluralrules
import 'intl-pluralrules';

import './utils/log';
import './styles/css/index.scss';
import 'react-toastify/dist/ReactToastify.css';

import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';

import { store } from './store';
import App from './App';
import reportWebVitals from './reportWebVitals';

// attach React to the window object, in case it's not already present
window.React ??= React;

// support advanced formats https://day.js.org/docs/en/plugin/advanced-format
dayjs.extend(advancedFormat);

render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
