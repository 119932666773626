import { combineReducers } from '@reduxjs/toolkit';
import ajaxCallsInProgress from './ajax-status-reducer';
import bankData from './bank-data-reducer';
import campaignsData from './campaign-reducer';
import competitions from './competitions-reducer';
import offersAndGames from './offers-and-games-reducer';
import redemptionTrans from './redemption-trans-reducer';
import { domainInfoReducer } from './domain-info-reducer';
import { feedReducer } from './feed-reducer';
import instanceInfo from './instance-info-reducer';
import notification from './payment-notification-reducer';
import paypalEmail from './paypal-reducer';
import redemptionResult from './redeem-reducer';
import redemptionAvailability from './redemption-availability-reducer';
import redemptions from './redemption-reducer';
import { panelInfoLoadedReducer as panelInfoLoaded, panelInfoReducer as panelInfo } from './panel-info-reducer';
import routeType from './route-type-reducer';
import {
  surveyThemeLoadedReducer as surveyThemeLoaded,
  surveyThemeReducer as surveyTheme,
} from './survey-theme-reducer';
import surveyThemes from './survey-themes-reducer';
import transactionsFiltering from './transaction-filtering-reducer';
import transactionsInfo from './transaction-reducer';
import transactionsMonth from './transactions-month-reducer';
import userBalance from './user-balance-reducer';
import userProfile from './user-profile-reducer';
import { onboardingDataReducer as onboardingData } from '../../onboarding/onboarding-data';

const rootReducer = combineReducers({
  ajaxCallsInProgress,
  bankData,
  campaignsData,
  competitions,
  domainInfo: domainInfoReducer,
  feed: feedReducer,
  instanceInfo,
  notification,
  offersAndGames,
  onboardingData,
  panelInfo,
  panelInfoLoaded,
  paypalEmail,
  redemptionAvailability,
  redemptionResult,
  redemptions,
  redemptionTrans,
  routeType,
  surveyTheme,
  surveyThemeLoaded,
  surveyThemes,
  transactionsFiltering,
  transactionsInfo,
  transactionsMonth,
  userBalance,
  userProfile,
});

export default rootReducer;
