import cloneDeep from 'lodash/cloneDeep';
import * as types from '../actions/action-types';
import initialState from './initial-state';
import { TransactionListing, TransactionsMonth, TransactionsMonthListing } from '../api/ah-api';
import { TransactionMonthActions, TransactionMonthDetailsActions } from '../actions/transactions-month-actions';

export default function transactionReducer(
  state: TransactionsMonth[] = initialState.transactionsInfo.transactions,
  action: TransactionMonthActions & TransactionMonthDetailsActions
): TransactionsMonth[] {
  switch (action.type) {
    case types.LOAD_TRANSACTIONS_MONTH_SUCCESS: {
      return (action.transactions as TransactionsMonthListing[]).map((x) => ({ transaction: x }));
    }

    case types.LOAD_TRANSACTIONS_BY_PERIOD_SUCCESS: {
      const date = new Date((action.transactions[0] as TransactionListing).date);
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      const index = state.findIndex(
        (x: TransactionsMonth) => x.transaction.month === month && x.transaction.year === year
      );

      const clonedState = cloneDeep(state);
      clonedState[index].transactionDetails = action.transactions as TransactionListing[];
      clonedState[index].loading = false;
      return clonedState;
    }

    case types.LOAD_TRANSACTIONS_BY_PERIOD_REQUEST: {
      const transactionMonth = action.transactionMonth.transaction;
      const index = state.findIndex(
        (x: TransactionsMonth) =>
          x.transaction.month === transactionMonth.month && x.transaction.year === transactionMonth.year
      );
      const clonedState = cloneDeep(state);
      clonedState[index].loading = true;
      return clonedState;
    }

    case types.LOAD_TRANSACTIONS_BY_PERIOD_ERROR: {
      const transactionMonth = action.transactionMonth.transaction;
      const index = state.findIndex(
        (x: TransactionsMonth) =>
          x.transaction.month === transactionMonth.month && x.transaction.year === transactionMonth.year
      );
      const clonedState = cloneDeep(state);
      clonedState[index].loading = false;
      return clonedState;
    }

    default:
      return state;
  }
}
