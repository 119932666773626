/* eslint key-spacing:0 */
/* eslint no-multi-spaces:0 */
const greenLight = '#9cfff8';
const green = '#20D6C9';
const green2 = '#1dc9bc';
const green3 = '#7fc613';
const green4 = '#4EC35D';
const aqua = '#337ab7';
const blue = '#4169E1';
const blue2 = '#2B3446';
const blue3 = '#2F51B7';
const blue4 = '#2351dc';
const blue5 = '#423bae';
const redLight = '#FFECE2';
const red = '#FF4337';
const red2 = '#EF4100';
const extraLightGrey = '#EEE';
const lightGrey = '#F9F9FC';
const grey = 'grey';
const grey2 = '#DDD';
const grey3 = '#C9CDD7';
const grey4 = '#EDEDED';
const grey5 = '#CECED0';
const grey6 = '#808080';
const grey7 = '#666';
const grey8 = '#909090';
const grey9 = '#CCC';
const grey10 = '#F0F0F0';
const grey11 = '#a4a4a4';
const grey12 = '#d7d7d3';
const grey13 = '#e5e4da';
const black = '#000000';
const white = '#FFFFFF';
const snow = '#EEE9EE';
const lightBlack = '#333333';
const extraLightBlack = '#626262';
const yellow = '#FFC000';
const beige = '#F6F5F0';
const shadow = '#999';
const twitterBlue = '#00abf0'; // eslint-disable-line
const faceBookBlue = '#3c599b'; // eslint-disable-line
const linkedinBlue = '#0d638f'; // eslint-disable-line
const google = '#af3c31'; // eslint-disable-line

export const clr = {
  primaryLight: greenLight,
  primary: green,
  primaryDark: green2,
  lightDark: extraLightBlack,
  success: green3,
  green4,
  negative: white,
  balance: grey3,
  closeSurveyHover: blue2,
  boxShadow: shadow,
  highlight: blue,
  focus: blue4,
  disable: blue5,
  link: aqua,
  extraLightBase: white,
  lightBase2: lightGrey,
  lightBase: extraLightGrey,
  mediumBase: snow,
  dimBase: grey2,
  dimBase2: grey5,
  dimBase3: grey7,
  dimBase4: grey8,
  dimBase5: grey9,
  dimBase6: grey4,
  dimBase7: grey10,
  dimBase8: grey11,
  dimBase9: grey12,
  dimBase10: grey13,
  dark: black,
  base: lightBlack,
  footer: extraLightGrey,
  footerText: lightBlack,
  dropDownArrow: blue3,
  deleteIcon: blue3,
  dropDownBorder: grey4,
  answerBorder: grey5,
  validationError: red,
  backgroundError: redLight,
  bottomValidationError: red2,
  label: grey6,
  select: grey7,
  star: grey,
  starActive: yellow,
  offWhite: beige,
  facebook: faceBookBlue,
  twitter: twitterBlue,
  linkedIn: linkedinBlue,
  google,
};

export const removeSpinner = {
  '::-webkit-inner-spin-button, ::-webkit-outer-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0,
  },
};

const spacingFactor = 6;
export const spacing = {
  space0: `${spacingFactor / 2}px`, // 3
  space1: `${spacingFactor}px`, // 6
  space2: `${spacingFactor * 2}px`, // 12
  space3: `${spacingFactor * 3}px`, // 18
  space4: `${spacingFactor * 4}px`, // 24
  space5: `${spacingFactor * 5}px`, // 30
  space6: `${spacingFactor * 6}px`, // 36
  space8: `${spacingFactor * 7}px`, // 42
  space9: `${spacingFactor * 8}px`, // 48
  space14: `${spacingFactor * 14}px`, // 84
};

export const breakpoints = {
  x: {
    full: '',
    wide: '50.00rem',
    narrow: '31.25rem',
  },
};

export const smartPhoneScreenSizeMax = 767;
export const tabletScreenSizeMax = 991;
export const desktopScreenSize = 769;
const mos = `@media only screen`;

export const screenSize = {
  smartphone: `${mos} and (max-width: ${smartPhoneScreenSizeMax}px)`,
  tablet: `${mos} and (min-width: ${smartPhoneScreenSizeMax + 1}px) and (max-width: ${tabletScreenSizeMax}px)`,
  desktopOrTablet: `${mos} and (min-width: ${smartPhoneScreenSizeMax + 1}px)`,
  desktopOnly: `${mos} and (min-width: ${tabletScreenSizeMax + 1}px)`,
};
