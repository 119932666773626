interface FeedAction {
  payload: Record<keyof FeedState, FeedState[keyof FeedState]>;
  type: string;
}

interface FeedState {
  filter: string;
}

const initialState = {
  filter: 'all',
};

export function feedReducer(state: FeedState = initialState, action: FeedAction) {
  switch (action.type) {
    case 'feed/setFilter':
      return {
        ...state,
        filter: action.payload.filter,
      };

    default:
      return state;
  }
}
