import * as Redux from '@reduxjs/toolkit';
import * as types from './action-types';

export interface AjaxStatusActions extends Redux.Action {
  type: string;
}

export function beginAjaxCall(): AjaxStatusActions {
  return { type: types.BEGIN_AJAX_CALL };
}

export function ajaxCallError(): AjaxStatusActions {
  return { type: types.AJAX_CALL_ERROR };
}
