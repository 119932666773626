/* eslint-disable no-console */
/* eslint-disable prefer-rest-params */

import Axios, { AxiosError } from 'axios';
import omit from 'lodash/omit';
import isEqual from 'lodash/isEqual';
import { appEnv, isLocalDevelopment } from '../app-config';
import { retrieveToken } from '../auth-token';
import { ensureProtocol, getQueryObj, preserveWindowQuery } from './uri';

const getAhApiHost = () => {
  if (window?.PP_USER_PROFILE?.ahApiHost) {
    return window.PP_USER_PROFILE.ahApiHost;
  }
  let ahApiHost = 'pp-us-ah-api.pureprofile.com';
  if (appEnv === 'staging') {
    ahApiHost = 'staging-ah-api-us.pureprofile.com';
  } else if (appEnv === 'development') {
    ahApiHost = 'dev-ah-api-us.pureprofile.com';
  }
  return ahApiHost;
};

const sent: string[] = [];

export function isUseless(data: ISubmitData, dataString: string): boolean {
  if (!data) {
    return true;
  }
  if (data.colno === 0 && data.lineno === 0 && !data.source && data.message === 'Script error.') {
    return true;
  }
  if (Array.isArray(data.arguments) && data.arguments[0] === 'AxiosError: Request aborted') {
    return true;
  }
  if (data.axiosErr && (data.axiosErr.code === 'ERR_NETWORK' || data.axiosErr.code === 'ECONNABORTED')) {
    return true;
  }
  if (isEqual(omit(data, ['href']), { arguments: ['AxiosError: Network Error'] })) {
    return true;
  }
  return false;
}

export function isReload(data: ISubmitData, dataString: string): boolean {
  if (
    data &&
    typeof data.name === 'string' &&
    data.name === 'ChunkLoadError' &&
    typeof data.source === 'string' &&
    data.source.startsWith(window.location.origin) &&
    typeof getQueryObj().refresh === 'undefined' // do not refresh multiple times
  ) {
    window.location.href = preserveWindowQuery(
      `${window.location.origin}${window.location.pathname}?refresh=${Date.now()}`
    );
    return true;
  }
  return false;
}

function isExcluded(data: ISubmitData, dataString: string): boolean {
  if (!data || !dataString) {
    return true;
  }
  if (isEqual(omit(data, 'href'), { colno: 0, lineno: 0, source: '', message: 'Script error.' })) {
    return true;
  }
  if (typeof data.source === 'string' && data.source.startsWith('https://googleads.g.doubleclick.net/')) {
    return true;
  }
  if (typeof data.arguments?.[0] === 'string') {
    if (
      data.arguments[0].startsWith('Asset') &&
      data.arguments[0].includes('got suspended, please visit https://anytrack.io for more details.')
    ) {
      return true;
    }
    if (data.arguments[0].startsWith('Pinterest Tag Error:')) {
      return true;
    }
  }
  return sent.includes(dataString);
}

interface ISubmitData {
  href: string;
  [other: string]: any;
  arguments?: any[];
  axiosErr?: AxiosError;
}

export function submitData(method: keyof Console, data: ISubmitData) {
  const dataString = JSON.stringify(data);
  if (isUseless(data, dataString)) {
    return;
  }
  if (isReload(data, dataString)) {
    return;
  }
  if (isExcluded(data, dataString)) {
    return;
  }
  sent.push(dataString);
  Axios.request({
    method: 'POST',
    url: `${ensureProtocol(getAhApiHost())}/api/v1/public/client/${method}`,
    headers: { 'pp-token': retrieveToken()! },
    data,
  }).catch((err) => {
    // ignore
  });
}

// hook to console methods
function handleIntercepted(method: keyof Console, args: IArguments) {
  if (isLocalDevelopment) {
    return;
  }
  const data: ISubmitData = {
    href: window.location.href,
    arguments: Array.from(args).map((i) => i?.toString()),
  };
  submitData(method, data);
}
function takeOverConsole() {
  const { console } = window;
  if (!console) {
    return;
  }

  function intercept(method: keyof Console) {
    const original = console[method] as any;
    console[method] = function () {
      try {
        handleIntercepted(method, arguments);
      } catch (err) {
        // ignore
      }
      return original.apply
        ? // do this for normal browsers
          original.apply(console, arguments)
        : // do this for IE
          original(Array.prototype.slice.apply(arguments).join(' '));
    } as any;
  }

  const methods: Array<keyof Console> = [/* 'log', 'warn', */ 'error'];
  for (const method of methods) {
    intercept(method);
  }
}
takeOverConsole();

// hook to window.onerror
const previousWindowOnError = window.onerror;
window.onerror = function (...args) {
  if (isLocalDevelopment) {
    return;
  }
  const [message, source, lineno, colno, error] = args;
  const data: ISubmitData = {
    href: window.location.href,
    name: error?.name,
    message,
    source,
    stack: error?.stack,
    lineno,
    colno,
  };
  submitData('error', data);
  previousWindowOnError?.(...args);
};

// export logging utilities
export const log = {
  info: (msg: string) => console.log(msg),
  warn: (msg: string) => console.warn(msg),
  error: (msg: string) => console.error(msg),
  debug: (...args: any[]) => {
    if (appEnv === 'development') {
      console.debug(...args);
    }
  },
};
export const logInfo = log.info;
export const logWarn = log.warn;
export const logError = log.error;
