import { CampaignDetail, CampaignGoResult } from '../../survey-tool/declarations/campaign';
import { AuthApiCall, getAhApiInfo } from './auth-api';
import { AhApiCall, getPublicUrl, IGetFeed } from './ah-api';
import { OnboardingCompletedResult, OnboardingData } from '../../onboarding/onboarding-data';
import { TokenHolder } from './api-call';
import { storeToken } from '../../auth-token';
import { authApiHost } from '../../app-config';

export interface OnboardingRequestParams {
  panelUuid: string;
}

export async function getOnboardingCampaigns({ panelUuid }: OnboardingRequestParams) {
  const ahApiInfo = await getAhApiInfo({ panelUuid });
  const result = await AhApiCall({} as TokenHolder, {
    method: 'GET',
    url: `${getPublicUrl(ahApiInfo)}/panel/${panelUuid}/onboarding/campaigns`,
  });
  return result.data.data as IGetFeed;
}

export interface OnboardingCampaignRequestParams extends OnboardingRequestParams {
  campaignUuid: string;
}

export async function getOnboardingCampaignDetail({ panelUuid, campaignUuid }: OnboardingCampaignRequestParams) {
  const ahApiInfo = await getAhApiInfo({ panelUuid });
  const result = await AhApiCall({} as TokenHolder, {
    method: 'GET',
    url: `${getPublicUrl(ahApiInfo)}/panel/${panelUuid}/onboarding/campaigns/${campaignUuid}`,
  });
  return result.data.data as CampaignDetail;
}

export async function getOnboardingCampaignGoResult({ panelUuid, campaignUuid }: OnboardingCampaignRequestParams) {
  const ahApiInfo = await getAhApiInfo({ panelUuid });
  const result = await AhApiCall({} as TokenHolder, {
    method: 'GET',
    url: `${getPublicUrl(ahApiInfo)}/panel/${panelUuid}/onboarding/campaigns/${campaignUuid}/go`,
  });
  return result.data.data as CampaignGoResult;
}

export interface OnboardingCampaignAnswerRequestParams extends OnboardingCampaignRequestParams {
  questionUuid: string;
  data: any;
}

export async function postOnboardingCampaignAnswer({
  panelUuid,
  campaignUuid,
  questionUuid,
  data,
}: OnboardingCampaignAnswerRequestParams) {
  const ahApiInfo = await getAhApiInfo({ panelUuid });
  const result = await AhApiCall({} as TokenHolder, {
    method: 'POST',
    url: `${getPublicUrl(ahApiInfo)}/panel/${panelUuid}/onboarding/campaigns/${campaignUuid}/${questionUuid}`,
    data,
  });
  return result.data.data as CampaignGoResult;
}

export interface RetrieveOnboardingData {
  provider?: string;
  userId?: string;
  userProfile?: { ppToken?: string };
}

export async function retrieveOnboardingData(data: RetrieveOnboardingData) {
  let url = `${authApiHost}/api/v1/onboarding`;
  if (data.provider && data.userId) {
    url += `?provider=${encodeURIComponent(data.provider)}&userId=${encodeURIComponent(data.userId)}`;
  }
  if (data.userProfile?.ppToken) {
    storeToken(data.userProfile.ppToken);
  }
  const result = await AuthApiCall({
    method: 'GET',
    url,
  });
  return result.data as OnboardingCompletedResult | null;
}

export async function submitOnboardingData(data: OnboardingData) {
  if (data.userProfile?.ppToken) {
    storeToken(data.userProfile.ppToken);
  }
  const result = await AuthApiCall({
    method: 'POST',
    url: `${authApiHost}/api/v1/onboarding/completed`,
    data,
  });
  return result.data as OnboardingCompletedResult;
}

export async function resendOnboardingEmail(data: { provider: string; userId: string; email?: string }) {
  const result = await AuthApiCall<OnboardingCompletedResult>({
    method: 'POST',
    url: `${authApiHost}/api/v1/onboarding/resend`,
    data,
  });
  return result.data;
}
