import { hexToRgb, Theme } from '@mui/material';
import { adaptV4Theme, createTheme } from '@mui/material/styles';
import defaultsDeep from 'lodash/defaultsDeep';
import { PanelInfo } from '../survey-tool/declarations/ah-api';
import { getThemeOptionsFromPanelInfo } from '../survey-tool/survey-theme';

/*
 * this declare is required for @mui/styles module to work and build
 */
declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

export const getTheme = (panelInfo: PanelInfo | null | undefined) => {
  const themeOptions = getThemeOptionsFromPanelInfo(panelInfo);
  return createTheme(defaultsDeep(themeOptions, getOldTheme(panelInfo)));
};

const getOldTheme = (panelInfo: PanelInfo | null | undefined) =>
  adaptV4Theme({
    overrides: {
      MuiSvgIcon: {
        root: {
          fontSize: 21,
        },
      },
      MuiButton: {
        contained: {
          '&:active': {
            backgroundColor: hexToRgb('#e3e3e3'),
            boxShadow: undefined,
          },
          '&:hover': {
            backgroundColor: hexToRgb('#f7f7f7'),
            boxShadow: undefined,
          },
          '&$disabled': {
            backgroundColor: hexToRgb('#eee'),
            boxShadow: undefined,
            color: 'rgba(71, 83, 105, 0.3)',
          },
          '&$focusVisible': {
            boxShadow: undefined,
          },
          backgroundColor: hexToRgb('#eee'),
          boxShadow: undefined,
          color: hexToRgb('#475369'),
        },
        containedPrimary: {
          '&:active': {
            backgroundColor: hexToRgb('#2cc8bc'),
          },
          '&:hover': {
            backgroundColor: hexToRgb('#5cdfd5'),
          },
          '&$disabled': {
            backgroundColor: hexToRgb('#95e7e1'),
            color: 'rgba(51, 51, 51, 0.3)',
            boxShadow: undefined,
          },
        },
        containedSizeSmall: {
          padding: '5px 9px',
        },
        outlinedSecondary: {
          '&:active': {
            backgroundColor: hexToRgb('#252d3b'),
            color: hexToRgb('#fff'),
          },
          '&:focus': {
            border: '1px solid transparent',
          },
          '&:hover': {
            backgroundColor: hexToRgb('#475369'),
            color: hexToRgb('#fff'),
          },
          '&$disabled': {
            border: `1px solid ${hexToRgb('#cbcbd2')}`,
            color: 'rgba(71, 83, 105, 0.3)',
          },
          backgroundColor: hexToRgb('#fff'),
          border: `1px solid ${hexToRgb('#475369')}`,
          transition: [
            'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            'border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            'box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            'color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
          ].join(', '),
        },
        outlinedSizeSmall: {
          padding: '4px 9px',
        },
        root: {
          '&:active': {
            backgroundColor: hexToRgb('#e3e3e3'),
          },
          '&:focus': {
            boxShadow: `0 0 0 2px ${hexToRgb('#0075ff')}`,
          },
          '&:hover': {
            backgroundColor: hexToRgb('#f7f7f7'),
          },
          '&$disabled': {
            color: 'rgba(71, 83, 105, 0.3)',
          },
          color: hexToRgb('#475369'),
          fontWeight: 600,
          textTransform: undefined,
        },
        textSizeSmall: {
          padding: 5,
        },
      },
      MuiChip: {
        root: {
          borderRadius: 4,
          fontWeight: 600,
        },
        sizeSmall: {
          fontSize: '0.75rem',
        },
      },
      MuiCssBaseline: {
        '@global': {
          body: {
            backgroundColor: hexToRgb('#fff'),
          },
          button: {
            '&:focus': {
              outline: 0,
            },
          },
        },
      },
      MuiDialog: {
        paper: {
          borderRadius: 8,
        },
      },
      MuiDialogActions: {
        root: {
          justifyContent: 'flex-start',
          padding: 24,
        },
        spacing: {
          '& > :not(:first-of-type)': {
            marginLeft: 16,
          },
        },
      },
      MuiDialogContent: {
        dividers: {
          borderBottom: `1px solid ${hexToRgb('#e0e0e0')}`,
          borderTop: `1px solid ${hexToRgb('#e0e0e0')}`,
          padding: 24,
        },
        root: {
          padding: 24,
        },
      },
      MuiDialogTitle: {
        root: {
          padding: 24,
        },
      },
      MuiDivider: {
        root: {
          borderColor: hexToRgb('#dfdfdf'),
        },
      },
      MuiFormHelperText: {
        root: {
          color: hexToRgb('#646464'),
        },
      },
      MuiFormLabel: {
        asterisk: {
          '&$error': {
            color: undefined,
          },
        },
        root: {
          '&$disabled': {
            color: hexToRgb('#acacac'),
          },
          '&$error': {
            color: undefined,
          },
          color: hexToRgb('#333'),
          fontSize: 12,
          fontWeight: 600,
        },
      },
      MuiIconButton: {
        root: {
          '&:focus': {
            // boxShadow: '0 0 0 2px #0075ff',
            outline: 'none',
          },
          borderRadius: 4,
          fontSize: '16px !important',
          padding: 6,
          transition: [
            'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            'box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
          ].join(', '),
        },
      },

      MuiInputBase: {
        formControl: {
          'label + &': {
            marginTop: 8,
          },
        },

        input: {
          '&::-webkit-inner-spin-button': {
            margin: 0,
            WebkitAppearance: 'none',
          },
          '&::-webkit-input-placeholder': {
            color: hexToRgb('#8b8b8b'),
            opacity: undefined,
          },
          '&::-webkit-outer-spin-button': {
            margin: 0,
            WebkitAppearance: 'none',
          },
          '&[type="number"]': {
            MozAppearance: 'textfield',
          },
          '&$disabled': {
            '&::-webkit-input-placeholder': {
              color: hexToRgb('#acacac'),
            },
            color: hexToRgb('#515151'),
            opacity: undefined,
          },
          height: '1.35714286em',
        },
        inputMultiline: {
          lineHeight: '1.35714286em',
          minHeight: '1.35714286em',
          padding: undefined,
        },
        multiline: {
          padding: undefined,
        },
        root: {
          '&$disabled': {
            color: undefined,
          },
          color: hexToRgb('#333'),
          fontSize: 14,
          background: '#FFFFFF',
        },
      },
      MuiListItemIcon: {
        root: {
          minWidth: 32,
        },
      },
      MuiMenuItem: {
        root: {
          fontSize: 14,
        },
      },
      MuiOutlinedInput: {
        adornedEnd: {
          paddingRight: 12,
        },
        adornedStart: {
          paddingLeft: 12,
        },
        input: {
          padding: '8px 12px 9px',
        },
        // inputMarginDense: {
        //   paddingBottom: undefined,
        //   paddingTop: undefined,
        // },
        inputMultiline: {
          padding: undefined,
        },
        multiline: {
          padding: undefined,
        },
        notchedOutline: {
          borderColor: hexToRgb('#e0e0e0'),
        },
        root: {
          '&:hover $notchedOutline': {
            borderColor: hexToRgb('#8b8b8b'),
          },
          '&$disabled': {
            backgroundColor: hexToRgb('#f7f7f7'),
          },
          '&$disabled $notchedOutline': {
            borderColor: 'transparent',
          },
          '&$error$focused $notchedOutline': {
            borderColor: 'transparent',
            boxShadow: `0 0 0 2px ${hexToRgb('#f22c44')}`,
          },
          '&$focused $notchedOutline': {
            borderColor: 'transparent',
            borderWidth: undefined,
            boxShadow: `0 0 0 2px ${hexToRgb('#0075ff')}`,
          },
        },
      },
      MuiPaper: {
        outlined: {
          border: `1px solid ${hexToRgb('#e0e0e0')}`,
        },
      },
      MuiRadio: {
        colorSecondary: {
          '&$checked': {
            '&:hover': {
              backgroundColor: undefined,
            },
          },
        },
        root: {
          '&:hover': {
            backgroundColor: 'unset',
          },
          '&$checked': {
            '&:hover': {
              backgroundColor: undefined,
            },
          },
        },
      },
      MuiSwitch: {
        colorSecondary: {
          '&$checked': {
            '&:hover': {
              backgroundColor: undefined,
            },
          },
        },
        switchBase: {
          '&:hover': {
            backgroundColor: 'unset',
          },
          '&$checked': {
            '&:hover': {
              backgroundColor: undefined,
            },
          },
        },
      },
      MuiTableCell: {
        body: {
          color: hexToRgb('#333'),
        },
        head: {
          color: hexToRgb('#333'),
          fontSize: 12,
          lineHeight: 1.75,
        },
        root: {
          borderBottom: `1px solid ${hexToRgb('#e0e0e0')}`,
          fontSize: 14,
        },
      },
      MuiTab: {
        root: {
          '@media (min-width: 600px)': {
            fontSize: 14,
          },
          '&:focus': {
            outline: 'none',
          },
          fontSize: 14,
          textTransform: 'none',
        },
      },
      MuiTableRow: {
        root: {
          '&$hover': {
            '&:hover': {
              backgroundColor: hexToRgb('#f7f7f7'),
            },
          },
        },
      },
      MuiTabs: {
        flexContainer: {
          '&$flexContainerVertical': {
            '& + $indicator': {
              display: 'none',
            },
            '& > .MuiTab-root': {
              '& > .MuiTab-wrapper': {
                alignItems: 'flex-start',
              },
              '&.Mui-selected': {
                backgroundColor: hexToRgb('#eee'),
                borderColor: hexToRgb('#eee'),
              },
              '&.MuiTab-textColorInherit': {
                '&:not(.Mui-disabled)': {
                  opacity: 1,
                },
              },
              borderRadius: 4,
              color: hexToRgb('#333'),
              fontSize: 14,
              textTransform: 'none',
            },
            '& > button': {
              '&:focus': {
                outline: 'none',
              },
            },
          },
        },
      },
      MuiTooltip: {
        tooltip: {
          backgroundColor: 'rgba(34, 34, 34, 0.9)',
          borderRadius: 4,
          fontSize: 12,
        },
      },
      MuiTypography: {
        body1: {
          color: hexToRgb('#333'),
          fontSize: 14,
        },
        h6: {
          color: hexToRgb('#333'),
          fontSize: 17.5,
          lineHeight: 1.82857143,
        },
        subtitle2: {
          color: hexToRgb('#333'),
          fontSize: 14,
          fontWeight: 400,
        },
      },
      // PrivateNotchedOutline: {
      //   root: {
      //     transition: [
      //       'border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      //       'box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      //     ].join(', '),
      //   },
      // },
    },
    props: {
      MuiCard: {
        variant: 'outlined',
      },
      MuiFormControl: {
        margin: 'normal',
      },
      MuiFormHelperText: {
        variant: 'standard',
      },
      MuiFormLabel: {
        focused: false,
      },
      MuiInputAdornment: {
        disableTypography: true,
      },
      MuiInputBase: {
        inputProps: {
          spellCheck: false,
        },
      },
      MuiMenu: {
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'bottom',
        },
        elevation: 3,
        // getContentAnchorEl: null,
        transformOrigin: {
          horizontal: 'center',
          vertical: 'top',
        },
      },
      MuiOutlinedInput: {
        notched: false,
      },
      MuiTabs: {
        indicatorColor: 'primary',
      },
      MuiTooltip: {
        enterDelay: 500,
        placement: 'top',
      },
    },
  });
