import defaults from 'lodash/defaults';

/* eslint no-process-env:0 */
// never use process.env variables directly from the code, but use these exports
// so for other apps like news-connect it can be overriden by injecting an object to the window
// prior (or after) app loading

// collect all defaults from process.env
export const {
  REACT_APP_ALWAYS_HTTPS,
  REACT_APP_AUTH_API_HOST,
  REACT_APP_LOGIN_PAGE_URL,
  REACT_APP_PLATFORM_UUID,
  REACT_APP_OAUTH_GOOGLE,
  REACT_APP_OAUTH_FACEBOOK,
  REACT_APP_OAUTH_TWITTER,
  REACT_APP_REFERRAL_URL,
} = process.env;

// extend Window object and ensure PP_APP_CONFIG object exists on it with default values from process.env
declare global {
  interface Window {
    PP_APP_CONFIG: {
      REACT_APP_ALWAYS_HTTPS: boolean;
      REACT_APP_AUTH_API_HOST: string;
      REACT_APP_LOGIN_PAGE_URL: string;
      REACT_APP_PLATFORM_UUID: string;
      REACT_APP_OAUTH_GOOGLE: string | null; // null in non-pp apps
      REACT_APP_OAUTH_FACEBOOK: string | null; // null in non-pp apps
      REACT_APP_OAUTH_TWITTER: string | null; // null in non-pp apps
      REACT_APP_REFERRAL_URL: string | null; // null in non-pp apps
    };
  }
}

window.PP_APP_CONFIG = defaults(window.PP_APP_CONFIG, {
  REACT_APP_ALWAYS_HTTPS: typeof REACT_APP_ALWAYS_HTTPS === 'string' ? /true/i.test(REACT_APP_ALWAYS_HTTPS) : true,
  REACT_APP_AUTH_API_HOST,
  REACT_APP_LOGIN_PAGE_URL,
  REACT_APP_PLATFORM_UUID,
  REACT_APP_OAUTH_GOOGLE,
  REACT_APP_OAUTH_FACEBOOK,
  REACT_APP_OAUTH_TWITTER,
  REACT_APP_REFERRAL_URL,
});

// NOTE: use functions to access window.PP_APP_CONFIG in case it changes after this file is executed
export const getConfigAlwaysHttps = () => window.PP_APP_CONFIG.REACT_APP_ALWAYS_HTTPS;
export const getPureprofileGatewayUrl = () => window.PP_APP_CONFIG.REACT_APP_LOGIN_PAGE_URL;
export const getConfigPlatformUuid = () => window.PP_APP_CONFIG.REACT_APP_PLATFORM_UUID;
export const getConfigOauthGoogle = () => window.PP_APP_CONFIG.REACT_APP_OAUTH_GOOGLE;
export const getConfigOauthFacebook = () => window.PP_APP_CONFIG.REACT_APP_OAUTH_FACEBOOK;
export const getConfigOauthTwitter = () => window.PP_APP_CONFIG.REACT_APP_OAUTH_TWITTER;
export const getConfigReferralUrl = () => window.PP_APP_CONFIG.REACT_APP_REFERRAL_URL;
