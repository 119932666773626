import { AnyAction } from '@reduxjs/toolkit';
import { InstanceInfo } from '../../survey-tool/declarations/ah-api';
import { browserStorageSet } from '../../utils/storage';
import { LOAD_INSTANCE_AND_PANEL_INFO_SUCCESS } from '../actions/action-types';

export default function instanceInfoReducer(
  state: InstanceInfo = null as any as InstanceInfo,
  action: AnyAction
): InstanceInfo {
  switch (action.type) {
    case LOAD_INSTANCE_AND_PANEL_INFO_SUCCESS:
      browserStorageSet('instanceInfo', action.instanceInfo, 'local-storage');
      return action.instanceInfo;
    default:
      return state;
  }
}
