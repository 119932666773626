import { useEffect, FC } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTopWithoutRouter: FC = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return null;
};

export default ScrollToTopWithoutRouter;
