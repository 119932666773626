import { ThunkAction } from '@reduxjs/toolkit';
import pick from 'lodash/pick';
import { Dispatch } from 'react';
import { toast } from 'react-toastify';
import { getQueryObj, isWindowOnboardingUrl, isWindowRootUrl } from '../utils/uri';
import { RootState } from '../store';
import {
  getOnboardingCampaigns,
  resendOnboardingEmail,
  retrieveOnboardingData,
  submitOnboardingData,
} from '../store/api/onboarding-api';
import {
  ONBOARDING_ANSWER_SUBMITTED,
  ONBOARDING_CAMPAIGNS_LOADED,
  ONBOARDING_CAMPAIGN_COMPLETED,
  ONBOARDING_CLEAR_DATA,
  ONBOARDING_COMPLETED,
  ONBOARDING_ERROR,
  ONBOARDING_FETCH,
  ONBOARDING_LOADING,
} from '../store/actions/action-types';
import { CampaignGoResult, CampaignListing } from '../survey-tool/declarations/campaign';
import { isClientErr } from '../store/api/api-call';
import { UserProfile } from '../survey-tool/declarations/auth-api';
import { browserStorageGet, browserStorageRemove, browserStorageSet } from '../utils/storage';
import { logError } from '../utils/log';
import { storeToken } from '../auth-token';
import { browserNavigate } from '../utils/browser-navigate';

// returned from /flybuys/login for onboarding
interface OnboardingLoginData {
  panelUuid: string;
  provider: string;
  userId: string;
}

export interface OnboardingData extends OnboardingLoginData {
  completedCampaignUuids?: string[];
  answers?: OnboardingDataAnswer[];
  campaigns?: CampaignListing[];
  onboardingFetched?: boolean;
  onboardingCompletedResult?: OnboardingCompletedResult | null;
  onboardingCompletedResultLoading?: boolean;
  onboardingCompletedResultError?: string | null;
  onboardingCompletedResultErrorCode?: string | null;
  userProfile?: UserProfile;
}

export interface OnboardingDataAnswer {
  answeredAt: number;
  campaignUuid: string;
  questionUuid: string;
  answerPayload: any;
}

export interface OnboardingCompletedResult extends OnboardingLoginData {
  email: string;
  emailValidated: boolean;
  languageCode?: string;
}

function cleanOnboardingData(data: Partial<OnboardingData>) {
  const propsToPersist: Array<keyof OnboardingData> = [
    'panelUuid',
    'provider',
    'userId',
    'completedCampaignUuids',
    'answers',
    'userProfile',
  ];
  return pick(data, propsToPersist) as OnboardingData;
}

function isValidOnboardingData(data: OnboardingData): boolean {
  return (
    !!(typeof data === 'object' && data.panelUuid && data.provider && data.userId) ||
    !!(typeof data === 'object' && data.userProfile)
  );
}

export function getOnboardingData(): OnboardingData | null {
  if (!isWindowRootUrl() && !isWindowOnboardingUrl()) {
    return null;
  }

  let onboardingData = browserStorageGet<OnboardingData, OnboardingData>('onboarding', {} as OnboardingData);

  // extend with data in the query
  const queryObj = getQueryObj();
  if (queryObj.userId && queryObj.userId !== onboardingData.userId) {
    clearOnboardingData();
    onboardingData = {} as OnboardingData;
  }
  onboardingData = {
    ...onboardingData,
    ...queryObj,
  };

  // validate
  if (!isValidOnboardingData(onboardingData)) {
    clearOnboardingData();
    return null;
  }

  // clear from query if valid
  if (queryObj && Object.keys(queryObj).length > 0) {
    setTimeout(() => browserNavigate(window.location.pathname), 1);
  }

  // store & return
  return storeOnboardingData(onboardingData);
}

export function storeOnboardingData<T extends Partial<OnboardingData>>(data: T): T {
  browserStorageSet<OnboardingData>('onboarding', cleanOnboardingData(data));
  return data;
}

export function clearOnboardingData() {
  browserStorageRemove('onboarding');
}

export function onboardingDataReducer(
  state: OnboardingData | null = getOnboardingData(),
  action: any
): OnboardingData | null {
  if (!state) {
    return state;
  }
  switch (action.type) {
    case ONBOARDING_CLEAR_DATA:
      clearOnboardingData();
      return null;
    case ONBOARDING_CAMPAIGNS_LOADED:
      return {
        ...state,
        campaigns: action.campaigns,
      };
    case ONBOARDING_CAMPAIGN_COMPLETED: {
      const completedCampaignUuids = [...(state.completedCampaignUuids ?? [])];
      if (!completedCampaignUuids.includes(action.campaignUuid)) {
        completedCampaignUuids.push(action.campaignUuid);
      }
      return storeOnboardingData({
        ...state,
        completedCampaignUuids,
      });
    }
    case ONBOARDING_ANSWER_SUBMITTED: {
      const answerGiven = action.answer as OnboardingDataAnswer;
      let answers = state.answers ?? [];
      // filter out those for the same campaign & question
      answers = answers.filter(
        (a) => !(a.campaignUuid === answerGiven.campaignUuid && a.questionUuid === answerGiven.questionUuid)
      );
      // add new one at the end
      answers = answers.concat(answerGiven);
      // store
      return storeOnboardingData({ ...state, answers });
    }
    case ONBOARDING_FETCH:
      return {
        ...state,
        onboardingFetched: true,
      };
    case ONBOARDING_LOADING:
      return {
        ...state,
        onboardingCompletedResultLoading: true,
        onboardingCompletedResultError: null,
        onboardingCompletedResultErrorCode: null,
      };
    case ONBOARDING_COMPLETED: {
      const onboardingCompletedResult = action.onboardingCompletedResult as OnboardingCompletedResult;
      return {
        ...state,
        panelUuid: onboardingCompletedResult.panelUuid,
        provider: onboardingCompletedResult.provider,
        userId: onboardingCompletedResult.userId,
        onboardingCompletedResultLoading: false,
        onboardingCompletedResult,
      };
    }
    case ONBOARDING_ERROR: {
      if (action.code === 'email_already_used') {
        return {
          ...state,
          onboardingCompletedResultLoading: false,
          onboardingCompletedResultErrorCode: action.code,
        };
      }
      return {
        ...state,
        onboardingCompletedResultLoading: false,
        onboardingCompletedResultError: action.message,
      };
    }
    default:
      return state;
  }
}

export const fetchOnboardingCampaignsAction =
  (): ThunkAction<void, RootState, void, any> => async (dispatch, getState) => {
    try {
      const state = getState();
      const result = await getOnboardingCampaigns({
        panelUuid: state.onboardingData!.panelUuid,
      });
      const completedCampaignUuids = state.onboardingData?.completedCampaignUuids ?? [];
      result.campaigns = result.campaigns.filter((c) => {
        if (c.is_final_onboarding_campaign) {
          return true;
        }
        return !completedCampaignUuids.includes(c.guid);
      });
      dispatch({ type: ONBOARDING_CAMPAIGNS_LOADED, campaigns: result.campaigns });
    } catch (err) {
      handleErr(dispatch, err);
    }
  };

export const onboardingDataFetch = (): ThunkAction<void, RootState, void, any> => async (dispatch, getState) => {
  const state = getState();
  if (state.onboardingData?.onboardingFetched) {
    return;
  }
  try {
    const onboardingCompletedResult = await retrieveOnboardingData(state.onboardingData!);
    if (onboardingCompletedResult?.email) {
      dispatch({ type: ONBOARDING_COMPLETED, onboardingCompletedResult });
    }
  } catch (err) {
    handleOnboardingCompletedResultErr(dispatch, err);
  } finally {
    dispatch({ type: ONBOARDING_FETCH });
  }
};

export const onboardingCampaignCompleted =
  (campaignUuid: string, campaignGoResult: CampaignGoResult): ThunkAction<void, RootState, void, any> =>
  async (dispatch, getState) => {
    if (campaignGoResult.thank_you) {
      dispatch({ type: ONBOARDING_CAMPAIGN_COMPLETED, campaignUuid });
      if (campaignGoResult.thank_you.is_final_onboarding_campaign) {
        dispatch({ type: ONBOARDING_LOADING });
        try {
          const onboardingCompletedResult = await submitOnboardingData(cleanOnboardingData(getState().onboardingData!));
          dispatch({ type: ONBOARDING_COMPLETED, onboardingCompletedResult });
        } catch (err) {
          handleOnboardingCompletedResultErr(dispatch, err);
        }
      }
    }
  };

export const actionResendOnboardingEmail =
  (email?: string): ThunkAction<void, RootState, void, any> =>
  async (dispatch, getState) => {
    const state = getState();
    dispatch({ type: ONBOARDING_LOADING });
    try {
      const data = state.onboardingData!;
      if (data?.userProfile?.ppToken) {
        storeToken(data.userProfile.ppToken);
      }
      const onboardingCompletedResult = await resendOnboardingEmail({
        provider: data.provider,
        userId: data.userId,
        email,
      });
      dispatch({ type: ONBOARDING_COMPLETED, onboardingCompletedResult });
    } catch (err) {
      handleOnboardingCompletedResultErr(dispatch, err);
    }
  };

function handleErr(dispatch: Dispatch<any>, err: any) {
  if (isClientErr(err)) {
    toast.error(err.message);
  } else {
    logError(err);
    browserNavigate('/error');
  }
}

function handleOnboardingCompletedResultErr(dispatch: Dispatch<any>, err: any) {
  if (isClientErr(err)) {
    dispatch({ type: ONBOARDING_ERROR, code: err.data?.code, message: err.message });
  } else {
    logError(err);
    browserNavigate('/error');
  }
}
