/* eslint-disable react/jsx-no-bind */

import { useMemo, useState, FC } from 'react';

import Box from '@mui/material/Box';
import clsx from 'clsx';
import { isFinalParticipationStatus } from '../../declarations/campaign';
import { css } from '../../utils/aphrodite';
import { CloseButton } from './close-button';
import { GoHomeButton } from './gohome-button';
import { NextButton } from './next-button';
import { useSurveyContextSelector } from '../../survey-context';
import { SURVEY_BOX_CONTAINER_RADIUS } from '../../default-styles/default';

export const SurveyBoxGenericFooterBtnWidth = '250px';

export const SurveyBoxGenericFooter: FC = () => {
  const campaignGoResult = useSurveyContextSelector((ctx) => ctx.args.campaignGoResult);
  const closeButtonDef = useSurveyContextSelector((ctx) => ctx.args.closeButtonDef);
  const asCampaignPreview = useSurveyContextSelector((ctx) => ctx.args.asCampaignPreview);
  const asWidget = useSurveyContextSelector((ctx) => ctx.args.asWidget);
  const services = useSurveyContextSelector((ctx) => ctx.args.services);
  const currentAnswer = useSurveyContextSelector((ctx) => ctx.args.currentAnswer);
  const surveyToolStyleSheet = useSurveyContextSelector((ctx) => ctx.args.surveyToolStyleSheet);
  const thankYouPage = useSurveyContextSelector((ctx) => ctx.state.thankYouPage);
  const isThankYou = campaignGoResult ? isFinalParticipationStatus(campaignGoResult.campaign_status) : false;
  const isDobblk = campaignGoResult?.action?.type === 'dobblk';
  const [lastTabOpened, setLastTabOpened] = useState<string | null>(null);

  const renderFooterResult = useMemo(() => {
    if (!campaignGoResult) {
      return;
    }
    if (isThankYou) {
      if (thankYouPage === 'competition_invitation') {
        return <div style={{ height: '57.5px' }} />;
      }

      // urls on thank-you page will override any close buttons (PPD-2929)
      const thankYou = campaignGoResult.campaign_status === 'disqualified' ? null : campaignGoResult.thank_you;
      // these are both redirects of the current tab
      const redirectUrl = thankYou && (thankYou.completion_url || thankYou.redirect_url);
      if (redirectUrl) {
        const redirectOnClick = function () {
          window.location.assign(redirectUrl);
        };
        return <CloseButton onClick={redirectOnClick} label="Continue to campaign website" />;
      }
      if (thankYou) {
        const openTabUrl = thankYou.open_in_new_tab;
        if (openTabUrl && lastTabOpened !== openTabUrl) {
          const openTabOnClick = function () {
            window.open(openTabUrl, '_blank');
            delete (thankYou as unknown as any).open_in_new_tab;
            setLastTabOpened(openTabUrl);
          };
          return <CloseButton onClick={openTabOnClick} label="Continue to campaign website" />;
        }
      }

      if (closeButtonDef) {
        return <CloseButton onClick={closeButtonDef.onClick} label={closeButtonDef.label} />;
      }

      if (asCampaignPreview) {
        return <CloseButton />;
      }

      const btnToReturn: JSX.Element | null = services.getBackToFeedLink?.() ?? null;
      return btnToReturn;
    }

    if (isDobblk) {
      return <GoHomeButton />;
    }

    if (campaignGoResult && campaignGoResult.question && campaignGoResult.question.type === 'grid' && !currentAnswer) {
      return null;
    }

    return <NextButton />;
  }, [
    asCampaignPreview,
    campaignGoResult,
    closeButtonDef,
    currentAnswer,
    isDobblk,
    isThankYou,
    lastTabOpened,
    services,
    thankYouPage,
  ]);

  // render
  if (renderFooterResult == null || campaignGoResult == null) {
    return null;
  }

  const { progress } = campaignGoResult;
  const surveyBoxFooterStyles = [surveyToolStyleSheet.surveyBoxGenericFooter];

  if (asWidget) {
    surveyBoxFooterStyles.splice(1, 0, surveyToolStyleSheet.surveyBoxFooterFooterWidget);
  } else {
    surveyBoxFooterStyles.splice(1, 0, surveyToolStyleSheet.surveyBoxFooterFooter);
  }

  return (
    <Box
      x-name="surveyBoxFooter"
      className={clsx(css(...surveyBoxFooterStyles))}
      sx={{ borderBottomLeftRadius: SURVEY_BOX_CONTAINER_RADIUS, borderBottomRightRadius: SURVEY_BOX_CONTAINER_RADIUS }}
    >
      <Box x-name="surveyBoxFooterPercentage" className={css(surveyToolStyleSheet.surveyBoxFooterPercentage)}>
        {progress && <span>{progress.total}% complete</span>}
      </Box>
      {renderFooterResult}
    </Box>
  );
};
