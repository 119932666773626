import * as types from '../actions/action-types';
import { OffersAndGamesTransaction } from '../api/ah-api';

export default function offersAndGamesReducer(
  state: OffersAndGamesTransaction[] = [],
  action: { type: string; payload: { offersAndGames: OffersAndGamesTransaction[] } }
): OffersAndGamesTransaction[] {
  switch (action.type) {
    case types.GET_OFFERS_AND_GAMES_TRANSACTIONS_FULFILLED:
      return action.payload.offersAndGames;
    default:
      return state;
  }
}
