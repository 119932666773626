import { RootState } from '../store';

export function panelCodeSelector(state: RootState) {
  return state.panelInfo?.code;
}

export function panelNameSelector(state: RootState) {
  return state.panelInfo?.app_settings?.web_app_name || state.userProfile?.platformName || 'the app';
}

export function panelLanguagesSelector(state: RootState) {
  return state.domainInfo?.panels?.find((p) => p.uuid === state.panelInfo.guid)?.languages ?? [];
}

export function faviconUrlSelector(state: RootState) {
  return state.panelInfo?.app_theme?.logo_favicon_url;
}
