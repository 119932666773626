import * as types from '../actions/action-types';
import initialState from './initial-state';
import { BankData } from '../api/auth-api';
import { BankDataAction } from '../actions/bank-data-actions';

export default function bankDataReducer(state: BankData[] = initialState.bankData, action: BankDataAction): BankData[] {
  switch (action.type) {
    case types.LOAD_BANK_DATA_SUCCESS:
      return action.bankData;
    default:
      return state;
  }
}
