import * as types from '../actions/action-types';
import { TransactionsInfo } from '../api/ah-api';
import initialState from './initial-state';

type Competition = TransactionsInfo['transactions'][0];

export default function competitionsReducer(
  state: Competition[] = initialState.competitions,
  action: { payload: { competitions: Competition[] }; type: string }
): Competition[] {
  switch (action.type) {
    case types.GET_COMPETITIONS_FULFILLED:
      return action.payload.competitions;
    default:
      return state;
  }
}
