import { ComponentClass, useCallback, useMemo, useState, FC } from 'react';
import omit from 'lodash/omit';
import { createContext, useContextSelector } from 'use-context-selector';
import { SurveyArguments, SurveyContainerProps } from './survey-container';
import { ThankYouPageType } from './components/survey-controls/thank-you/thank-you';
import { DeepReadonly } from './declarations/deep-readonly';

export interface SurveyContext extends DeepReadonly<SurveyContainerProps> {
  state: DeepReadonly<SurveyContextState>;
  setState: (obj: Partial<SurveyContextState>) => void;
}

export interface SurveyContextState {
  container?: HTMLDivElement | null; // used for scrolling from next-button when you answer a question
  submitAnswerInProgress?: boolean;
  thankYouPage?: ThankYouPageType;
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
const surveyContext = createContext<SurveyContext>(null as any);

type NotSurveyContextRoots<T> = Exclude<T, SurveyContext | SurveyContext['args'] | SurveyContext['state']>;

export function useSurveyContextSelector<T>(selector: (ctx: SurveyContext) => NotSurveyContextRoots<T>) {
  return useContextSelector(surveyContext, selector);
}

export interface WithSurveyContext {
  context: SurveyContext;
}

export function withSurveyContext<P extends WithSurveyContext, S>(Component: ComponentClass<P, S>) {
  const Wrapper: FC = (props) => {
    const TypeLessComponent = Component as ComponentClass<any, any>;
    const context = useSurveyContextSelector((ctx) => ctx as any);
    return <TypeLessComponent {...props} context={context} />;
  };
  return Wrapper as unknown as ComponentClass<Omit<P, keyof WithSurveyContext>, S>;
}

export type SurveyContextProviderProps = Omit<SurveyContext, 'state' | 'setState'>;

export const SurveyContextProvider: FC<SurveyContextProviderProps> = (props) => {
  const [contextState, setContextState] = useState<SurveyContext['state']>({});
  const [counter, setCounter] = useState<number>(0);
  (props.args as SurveyArguments).modified = () => setCounter((c) => c + 1);

  const setState = useCallback(
    (obj: Partial<SurveyContextState>) =>
      setContextState((cs) => ({
        ...cs,
        ...(obj as any),
      })),
    []
  );

  const value = useMemo(
    () => ({
      ...omit(props, 'children'),
      state: contextState,
      setState,
    }),
    // counter dependency is required to refresh the context
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props, contextState, counter]
  );

  return <surveyContext.Provider value={value}>{props.children}</surveyContext.Provider>;
};
