import * as types from '../actions/action-types';
import { TransactionsInfo } from '../api/ah-api';

export type RedemptionTransaction = TransactionsInfo['transactions'][0];

export default function competitionsReducer(
  state: RedemptionTransaction[] = [],
  action: { payload: { redemptions: RedemptionTransaction[] }; type: string }
): RedemptionTransaction[] {
  switch (action.type) {
    case types.GET_REDEMPTIONS_FULFILLED:
      return action.payload.redemptions;
    default:
      return state;
  }
}
