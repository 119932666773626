import * as Redux from '@reduxjs/toolkit';
import { ThunkAction } from '@reduxjs/toolkit';
import * as AhApi from '../api/ah-api';
import { PartialSurveyToolStyleObject } from '../../survey-tool/default-styles/style-declaration';
import { RootState } from '../store';
import { PanelInfo } from '../../survey-tool/declarations/ah-api';
import { LOAD_SURVEY_THEME_CSS, LOAD_SURVEY_THEME_NOT_FOUND } from './action-types';

export interface SurveyThemeAction extends Redux.Action {
  surveyTheme?: PartialSurveyToolStyleObject;
}

export function loadSurveyTheme(panelInfo: PanelInfo): ThunkAction<Promise<void>, RootState, void, any> {
  return async function (dispatch: Redux.Dispatch<SurveyThemeAction>, getState: () => RootState) {
    if (panelInfo?.app_theme?.survey_theme_guid) {
      const surveyTheme = await AhApi.getSurveyTheme(panelInfo, panelInfo?.app_theme.survey_theme_guid);
      dispatch({ type: LOAD_SURVEY_THEME_CSS, surveyTheme });
    } else {
      dispatch({ type: LOAD_SURVEY_THEME_NOT_FOUND });
    }
  };
}
