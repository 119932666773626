import * as Redux from '@reduxjs/toolkit';
import { ThunkAction, ThunkDispatch } from '@reduxjs/toolkit';
import { createAction } from 'typesafe-actions';
import * as types from './action-types';
import { UserProfile, UserProfileMetadata, UserProfilePic } from '../../survey-tool/declarations/auth-api';
import { RootState } from '../store';
import * as AhApi from '../api/ah-api';
import * as AuthApi from '../api/auth-api';
import { ReferralState } from '../../declarations/interfaces/ReferralState';
import { UserProfilePostDto } from '../../declarations/interfaces/dtos/post/UserProfilePostDto';

declare global {
  interface Window {
    dataLayer: any[];
    PP_USER_PROFILE: {
      ahApiHost?: string;
      siteId?: UserProfileMetadata['siteId'];
    };
  }
}

// default empty
window.PP_USER_PROFILE ??= {};

export interface UserProfileAction extends Redux.Action {
  userProfile: UserProfile;
}

export type UserProfileThunkDispatch = ThunkDispatch<any, any, UserProfileAction>;

export const refreshProfile =
  (queryObj?: Record<string, string>): ThunkAction<Promise<UserProfile>, RootState, void, any> =>
  async (dispatch, _getState) => {
    const userProfile = await AuthApi.getProfile(queryObj);
    const referralResult = await AhApi.getReferralEligibility(userProfile);
    const profile = {
      ...userProfile,
      isEligibleToRefer: referralResult.referringEligibility.isEligibleToRefer,
    };
    window.PP_USER_PROFILE = {
      ahApiHost: userProfile.instanceUrl,
      siteId: userProfile.metadata?.siteId,
    };
    dispatch(loadProfileSuccess(profile));
    return profile;
  };

export function loadProfileSuccess(userProfile: UserProfile & ReferralState): UserProfileAction {
  return { type: types.LOAD_USER_PROFILE_SUCCESS, userProfile };
}

export function saveProfile(payload: UserProfilePostDto): ThunkAction<Promise<any>, RootState, void, any> {
  return async function (dispatch, getState) {
    const { userProfile } = getState();
    const response = await AuthApi.saveProfile(userProfile, payload);
    dispatch(saveProfileSuccess(response));
    return response;
  };
}

export function saveProfileMetadata(
  ...params: Parameters<typeof AuthApi.saveProfileMetadata>
): ThunkAction<Promise<any>, RootState, void, any> {
  return async function (dispatch, getState) {
    const response = await AuthApi.saveProfileMetadata(...params);
    dispatch(saveProfileSuccess(response));
    return response;
  };
}

export function saveProfileSuccess(userProfile: UserProfile): UserProfileAction {
  return { type: types.SAVE_USER_PROFILE_SUCCESS, userProfile };
}

export function uploadProfilePic(payload: any): ThunkAction<Promise<any>, RootState, void, any> {
  return async function (dispatch: Redux.Dispatch<any>) {
    return AuthApi.uploadProfilePic(payload);
  };
}

export function saveProfilePic(key: string, payload: any): ThunkAction<Promise<any>, RootState, void, any> {
  return async function (dispatch: Redux.Dispatch) {
    const response = await AuthApi.saveProfilePic(key, payload);

    const profilePics: UserProfilePic[] = [];

    profilePics.push({ key: response.key, url: response.url });

    dispatch(saveProfileSuccess({ profilePics } as UserProfile));
  };
}

export const updateEmail =
  (email: string): ThunkAction<Promise<void>, RootState, void, any> =>
  async (dispatch, getState) => {
    await AuthApi.changeUsername(email);
    dispatch(refreshProfile());
  };

export function deleteProfilePic(key: string): ThunkAction<Promise<any>, RootState, void, any> {
  return async function (dispatch: Redux.Dispatch<any>) {
    const response = await AuthApi.deleteProfilePic(key);
    const profilePics = [] as UserProfilePic[];
    dispatch(saveProfileSuccess({ profilePics } as UserProfile));
    return response;
  };
}

export function requestEmailVerification(): ThunkAction<Promise<any>, RootState, void, any> {
  return async function (dispatch: Redux.Dispatch<any>) {
    return AuthApi.requestEmailVerification();
  };
}

export const socialNetworkDisconnected = createAction(
  'SOCIAL_NETWORK_DISCONNECTED',
  (resolve) => (socialNetwork: AuthApi.OAuthProvider) =>
    ({
      type: 'SOCIAL_NETWORK_DISCONNECTED',
      payload: {
        socialNetwork,
      },
    }) as any
);

export const socialNetworkConnected = createAction(
  'SOCIAL_NETWORK_CONNECTED',
  (resolve) => (socialNetwork: AuthApi.OAuthProvider) =>
    ({
      type: 'SOCIAL_NETWORK_CONNECTED',
      payload: {
        socialNetwork,
      },
    }) as any
);

export const connectSocialNetwork =
  (
    socialNetwork: AuthApi.OAuthProvider, // string
    token: string,
    secret?: string
  ): ThunkAction<Promise<AuthApi.OAuthProvider>, RootState, void, any> =>
  async (dispatch, getState) => {
    if (token) {
      try {
        await AuthApi.socialConnect(socialNetwork, token, secret);
        dispatch(socialNetworkConnected(socialNetwork));
        return socialNetwork;
      } catch (err: any) {
        throw err.message;
      }
    } else {
      return Promise.reject(new Error('No token detected'));
    }
  };

export function disconnectSocialNetwork(
  socialNetwork: AuthApi.OAuthProvider
): ThunkAction<Promise<void>, RootState, void, any> {
  return async function (dispatch: Redux.Dispatch<any>, getState: () => RootState) {
    await AuthApi.socialDisconnect(socialNetwork);
    dispatch(socialNetworkDisconnected(socialNetwork));
  };
}

export function eligibilityToReferEnabled() {
  return { type: types.ELIGIBILITY_TO_REFER_ENABLED };
}

export function enableEligibilityToRefer(): ThunkAction<Promise<void>, RootState, void, any> {
  return async function (dispatch: Redux.Dispatch<any>) {
    dispatch(eligibilityToReferEnabled());
  };
}
