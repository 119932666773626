/* eslint-disable @typescript-eslint/no-explicit-any */

import { getCampaignSkipIdentifier } from '../../survey-tool/utils/campaign';
import * as types from '../actions/action-types';
import { CampaignActions } from '../actions/campaign-actions';
import { IGetFeed } from '../api/ah-api';

export interface CampaignsState extends IGetFeed {
  endOfFeed?: boolean;
}

function getEmptyState() {
  // we set campaigns to an empty array to prevent a lot of type-checking against null
  const emptyState: Partial<CampaignsState> = { campaigns: [] };
  return emptyState as CampaignsState;
}

export default function campaignReducer(
  state: CampaignsState = getEmptyState(),
  action: CampaignActions | any
): CampaignsState {
  switch (action.type) {
    case types.LOAD_CAMPAIGNS_SUCCESS: {
      const response = action.campaignsData as IGetFeed;
      const newCampaigns = response.campaigns;
      return {
        ...state,
        ...response,
        campaigns: newCampaigns,
        endOfFeed: newCampaigns.length < response.pageSize,
      };
    }

    case types.LOAD_MORE_CAMPAIGNS_SUCCESS: {
      const response = action.campaignsData as IGetFeed;
      const oldCampaigns = state.campaigns;
      const oldCampaignIds = oldCampaigns.map((c) => getCampaignSkipIdentifier(c));
      const newCampaigns = response.campaigns.filter((c) => !oldCampaignIds.includes(getCampaignSkipIdentifier(c)));
      return {
        ...state,
        ...response,
        campaigns: [...oldCampaigns, ...newCampaigns],
        endOfFeed: newCampaigns.length < response.pageSize,
      };
    }

    case types.CAMPAIGN_REPORTED_BROKEN:
      return {
        ...state,
        campaigns: state.campaigns.map((campaign) => {
          if (campaign.guid === action.payload.guid) {
            return {
              ...campaign,
              ...action.payload,
            };
          }
          return campaign;
        }),
      };

    case types.HIDE_CAMPAIGN:
      return {
        ...state,
        campaigns: state.campaigns.filter((campaign) => campaign.guid !== action.payload.guid),
      };

    default:
      return state;
  }
}
