export enum APPLICATION_THEME {
  THEME1 = 'theme1',
  THEME2 = 'theme2',
}

export enum FONT_WEIGHT {
  LIGHT = 300,
  NORMAL = 400,
  SEMI_BOLD = 600,
  BOLD = 700,
  EXTRA_BOLD = 800,
}
