import * as Redux from '@reduxjs/toolkit';
import { ThunkAction, ThunkDispatch } from '@reduxjs/toolkit';
import * as types from './action-types';
import * as AhApi from '../api/ah-api';
import { UserBalanceResponse } from '../../declarations/ah-api';
import { RootState } from '../store';

export interface UserBalanceAction extends Redux.Action {
  userBalance: UserBalanceResponse;
}

export interface UserBalanceValueAction extends Redux.Action {
  userBalance: number;
}

export type UserBalanceValueThunkDispatch = ThunkDispatch<any, any, UserBalanceValueAction>;

export function loadUserBalance(): ThunkAction<Promise<UserBalanceResponse>, RootState, void, any> {
  return async function (dispatch: Redux.Dispatch<UserBalanceAction>, getState) {
    const { userProfile } = getState();
    const userBalance = await AhApi.getUserBalance(userProfile);
    dispatch(loadUserBalanceSuccess(userBalance));
    return userBalance;
  };
}

export function loadUserBalanceSuccess(userBalance: UserBalanceResponse): UserBalanceAction {
  return { type: types.LOAD_USER_BALANCE_SUCCESS, userBalance };
}

export function updateUserBalance(userBalance: number): UserBalanceValueAction {
  return { type: types.UPDATE_USER_BALANCE, userBalance };
}
