import * as Redux from '@reduxjs/toolkit';
import { ThunkAction, ThunkDispatch } from '@reduxjs/toolkit';
import { createAction } from 'typesafe-actions';
import * as types from './action-types';
import * as AhApi from '../api/ah-api';
import { ajaxCallError, beginAjaxCall } from './ajax-status-actions';
import { RootState } from '../store';
import {
  loadTransactionsByPeriodError,
  loadTransactionsByPeriodRequest,
  loadTransactionsByPeriodSuccess,
  loadTransactionsMonthSuccess,
} from './transactions-month-actions';
import { formatDateYMD } from '../../utils/date';

export interface TransactionActions extends Redux.Action {
  transactionsInfo: AhApi.TransactionsInfo;
}
export type TransactionActionsThunkDispatch = ThunkDispatch<any, any, TransactionActions>;

export const nextTransactionPage = createAction('NEXT_TRANSACTION_PAGE');
export const previousTransactionPage = createAction('PREVIOUS_TRANSACTION_PAGE');
export const resetFilters = createAction('RESET_FILTERS');

export const pageRequest = createAction(
  'TRANSACTION_PAGE_REQUEST',
  (resolve) => (page: number) =>
    ({
      type: 'TRANSACTION_PAGE_REQUEST',
      payload: { page },
    }) as any
);

export function getTransactionPage(page: number): ThunkAction<Promise<void>, RootState, void, any> {
  return async function (dispatch: Redux.Dispatch) {
    dispatch(pageRequest(page));
    dispatch(loadTransactions() as any);
  };
}

export function getNextTransactions(): ThunkAction<void, RootState, void, any> {
  return function (dispatch: Redux.Dispatch<any>, getState: () => RootState) {
    dispatch(nextTransactionPage());
    dispatch(loadTransactions());
  };
}

export function getPreviousTransactions(): ThunkAction<Promise<void>, RootState, void, any> {
  return async function (dispatch: Redux.Dispatch<any>, getState: () => RootState) {
    dispatch(previousTransactionPage());
    dispatch(loadTransactions());
  };
}

export function getCompetitions(init?: Record<string, string>) {
  return async (dispatch: Redux.Dispatch<any>, getState: () => RootState) => {
    dispatch(beginAjaxCall());
    try {
      const { userProfile } = getState();
      dispatch(getCompetitionsFulfilled(await AhApi.getCompetitions(userProfile, init)));
    } catch {
      dispatch(ajaxCallError());
    }
  };
}

export function getOffersAndGames(init?: Record<string, string>) {
  return async (dispatch: Redux.Dispatch<any>, getState: () => RootState) => {
    dispatch(beginAjaxCall());
    try {
      const { userProfile } = getState();
      dispatch(getOffersAndGamesFulfilled(await AhApi.getOffersAndGames(userProfile, init)));
    } catch {
      dispatch(ajaxCallError());
    }
  };
}

export function getRedemptionTransactions(init?: Record<string, string>) {
  return async (dispatch: Redux.Dispatch<any>, getState: () => RootState) => {
    dispatch(beginAjaxCall());
    try {
      const { userProfile } = getState();
      dispatch(getRedemptionsFulfilled(await AhApi.getRedemptionTransactions(userProfile, init)));
    } catch {
      dispatch(ajaxCallError());
    }
  };
}

export function loadTransactions(): ThunkAction<Promise<void>, RootState, void, any> {
  return async function (dispatch: Redux.Dispatch<any>, getState: () => RootState) {
    const userProfileState = getState().userProfile;
    const transactionsFilteringState = getState().transactionsFiltering;
    dispatch(beginAjaxCall());
    const response = await AhApi.getTransactions(
      userProfileState,
      transactionsFilteringState.afterFilter,
      transactionsFilteringState.beforeFilter,
      transactionsFilteringState.page,
      transactionsFilteringState.pageSize
    ).catch(() => {
      dispatch(ajaxCallError());
    });
    if (response) {
      dispatch(loadTransactionsSuccess(response));
    }
  };
}

export function loadTransactionsByPeriod(
  transactionsMonth: AhApi.TransactionsMonth
): ThunkAction<Promise<void>, RootState, void, any> {
  return async function (dispatch: Redux.Dispatch<any>, getState: () => RootState) {
    const userProfileState = getState().userProfile;
    const after = formatDateYMD(transactionsMonth.transaction.year, transactionsMonth.transaction.month, 1);
    const before = formatDateYMD(transactionsMonth.transaction.year, transactionsMonth.transaction.month + 1, 1);
    dispatch(loadTransactionsByPeriodRequest(transactionsMonth));
    const response = await AhApi.getTransactions(userProfileState, after, before, 1, 1000, true).catch(() => {
      dispatch(loadTransactionsByPeriodError(transactionsMonth));
    });
    if (response) {
      dispatch(loadTransactionsByPeriodSuccess(response.transactions));
    }
  };
}

export function loadTransactionsMonth(): ThunkAction<Promise<void>, RootState, void, any> {
  return async function (dispatch: Redux.Dispatch<any>, getState: () => RootState) {
    dispatch(beginAjaxCall());
    const userProfileState = getState().userProfile;
    const response = await AhApi.getTransactionsMonth(userProfileState).catch(() => {
      dispatch(ajaxCallError());
    });
    if (response) {
      dispatch(loadTransactionsMonthSuccess(response.months));
    }
  };
}

export function getCompetitionsFulfilled(competitions: AhApi.TransactionsInfo['transactions']) {
  return { payload: { competitions }, type: types.GET_COMPETITIONS_FULFILLED };
}

export function getRedemptionsFulfilled(redemptions: AhApi.TransactionsInfo['transactions']) {
  return { payload: { redemptions }, type: types.GET_REDEMPTIONS_FULFILLED };
}

export function loadTransactionsSuccess(transactionsInfo: AhApi.TransactionsInfo): TransactionActions {
  return { type: types.LOAD_TRANSACTIONS_SUCCESS, transactionsInfo };
}

export function getOffersAndGamesFulfilled(offersAndGames: AhApi.OffersAndGamesTransaction[]) {
  return { type: types.GET_OFFERS_AND_GAMES_TRANSACTIONS_FULFILLED, payload: { offersAndGames } };
}
