import { Action } from '@reduxjs/toolkit';
import * as types from '../actions/action-types';

export type RouteTypeReducerState = 'private' | 'public' | 'any' | null;

export interface RouteTypeAction extends Action {
  routeType: RouteTypeReducerState;
}

export default function routeTypeReducer(
  state: RouteTypeReducerState = null,
  action: RouteTypeAction
): RouteTypeReducerState {
  switch (action.type) {
    case types.ROUTE_TYPE:
      return action.routeType;
    default:
      return state;
  }
}
