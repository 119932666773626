import {
  StyleSheet as AphroditeStyleSheet,
  StyleSheetTestUtils as AphroditeStyleSheetTestUtils,
  CSSProperties,
  CSSWideKeyword as AphroditeCSSWideKeyword,
  StyleDeclaration as AphroditeStyleDeclaration,
  StyleDeclarationValue as AphroditeStyleDeclarationValue,
} from 'aphrodite';

const AphroditeExtended = AphroditeStyleSheet.extend([
  // This was an idea to use wide scope selectors to mimic a CSS-Reset functionality
  // but it didn't work very well and caused conflicts
  // hence a different approach will be required, leaving this here to not repeat the same mistake :)
  // {
  //   selectorHandler: (_selector, baseSelector, generateSubtreeStyles) => {
  //     // catch only selectors starting with a space
  //     if (_selector[0] !== ' ') {
  //       return null;
  //     }
  //     // get rid of the space at the start
  //     const selector = _selector.slice(1);
  //     let fullSelector = `${baseSelector} ${selector}`;
  //     if (selector.indexOf(',') !== -1) {
  //       fullSelector = selector.split(',').map(s => `${baseSelector} ${s}`).join(', ');
  //     }
  //     // generate class like .surveyContainer_1n15crx div{...}
  //     return generateSubtreeStyles(fullSelector);
  //   }
  // }
]);

export const { StyleSheet } = AphroditeExtended;
export const StyleSheetTestUtils = AphroditeStyleSheetTestUtils;
export const { css } = AphroditeExtended;
export type AphroditeCSSProperties = CSSProperties;
export type CSSWideKeyword = AphroditeCSSWideKeyword;
export type StyleDeclaration<T = any> = AphroditeStyleDeclaration<T>;
export type StyleDeclarationValue = AphroditeStyleDeclarationValue;
