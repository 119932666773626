import { overrideBackToFeed } from '../components/back-to-feed';
import { NavigateFunction } from './browser-navigate';

export async function redirectToCampaign(navigate: NavigateFunction, campaignUuid: string) {
  const { pathname } = window.location;
  const targetPathname = `/survey/${campaignUuid}`;
  if (pathname !== targetPathname) {
    // PPD-2655: remember this survey and redirect to it after welcome campaign is completed
    const surveyAlreadyOpened = pathname.startsWith(`/survey/`);
    if (surveyAlreadyOpened) {
      overrideBackToFeed(pathname.slice(1), 'Continue to my campaign');
    }
    navigate(targetPathname + window.location.search);
  }
}
