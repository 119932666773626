import dayjs from 'dayjs';
import once from 'lodash/once';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { UserProfile } from '../survey-tool/declarations/auth-api';
import { CampaignCommon, CampaignGoResult } from '../survey-tool/declarations/campaign';
import { PanelInfo } from '../survey-tool/declarations/ah-api';
import { ensureProtocol } from './uri';
import { setCookie } from './cookies';
import { logInfo, logWarn } from './log';
import { addGtagTagId } from './gtag';
import { saveProfileMetadata } from '../store/actions/user-profile-actions';
import { isProduction } from '../app-config';

// this is called from eventOnAppLoad, so it should execute only once per page load
export function insertTrackingTags(profile: UserProfile) {
  const trackingIds = profile.trackingIds || {};

  appendImg(
    `https://tag.pprl.io/match/log?uid=${encodeURIComponent(profile.ppUuid)}` +
      `&usc=${encodeURIComponent(profile.instanceCode)}&tag=pplcorp`
  );

  if (trackingIds.hasOffersId) {
    (window as any).hasOffersId = trackingIds.hasOffersId;
    dataLayerPush({ event: 'hasOffersIdLoaded' });
    setCookie('has-offers-id', trackingIds.hasOffersId);
  }
}

let currencyCode = '';
let panelInfo: PanelInfo = {} as PanelInfo;

const _addedTagIds: string[] = [];
const _addedPixels: string[] = [];

export function onPanelInfoLoaded(_panelInfo: PanelInfo) {
  panelInfo = _panelInfo || panelInfo;
  currencyCode = panelInfo?.currency_code || currencyCode;

  const tagIds = (
    [panelInfo.app_settings.google_analytics_id, panelInfo.app_settings.google_tag_mngr_id].filter(
      (x) => typeof x === 'string'
    ) as string[]
  )
    .map((x) => x.split(','))
    .reduce((arr, item) => [...arr, ...item], [])
    .map((x) => x.trim())
    .filter((x) => x);

  for (const tagId of tagIds) {
    if (_addedTagIds.includes(tagId)) {
      continue;
    }
    if (tagId.startsWith('GTM-')) {
      window.dataLayer ||= [];
      window.dataLayer.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
      appendScript(`https://www.googletagmanager.com/gtm.js?id=${tagId}`);
    } else if (
      tagId.startsWith('G-') ||
      tagId.startsWith('UA-') ||
      tagId.startsWith('AW-') ||
      tagId.startsWith('DC-')
    ) {
      addGtagTagId(tagId);
    } else {
      logWarn(`Unrecognized tracking tag: ${tagId}`);
    }
    _addedTagIds.push(tagId);
  }

  const trackingPixelHtml = panelInfo.app_settings.tracking_pixel;
  if (trackingPixelHtml && !_addedPixels.includes(trackingPixelHtml)) {
    appendDiv(trackingPixelHtml);
    _addedPixels.push(trackingPixelHtml);
  }
}

export function onFeedItemsDisplayed() {
  // NOTE: this was executed every-time feed items were added in the old app
  // not sure what's its purpose and if we can execute it only once
  appendScript(`https://tags.crwdcntrl.net/c/7412/cc_af_ajax.js`);
}

const _onThankYouPageDisplayed = once((dispatch: ThunkDispatch<any, any, any>) => {
  // this is a custom event defined in GTM
  dataLayerPush({ event: 'welcomeCampaignCompleted' });
  dispatch(
    saveProfileMetadata({
      welcomeCampaignCompleted: dayjs().format(),
    })
  );
  if (!isProduction) {
    logInfo(`GTM event fired: welcomeCampaignCompleted`);
  }
});

export function onThankYouPageDisplayed(
  dispatch: ThunkDispatch<any, any, any>,
  userProfile: UserProfile,
  campaign: CampaignCommon,
  campaignGoResult: CampaignGoResult
) {
  if (userProfile.metadata?.welcomeCampaignCompleted) {
    return;
  }
  if (campaign.is_welcome && campaignGoResult.campaign_status === 'completed') {
    _onThankYouPageDisplayed(dispatch);
  }
}

export function endOfFeedReached(profile: UserProfile, filter: string, campaignsLength: number) {
  return recordAhApiEvent(profile, 'END_OF_FEED_REACHED', {
    filter,
    emptyFeed: campaignsLength === 0,
    campaignsLength,
  });
}

function recordAhApiEvent(profile: UserProfile, eventName: string, eventData: any) {
  const imgUrl = `${
    `${ensureProtocol(profile.instanceUrl)}/api/v1/${profile.instanceCode}` +
    `/events/record?eventName=${eventName}&pp-token=${profile.ppToken}&pp-panel=${profile.panelKey}&eventData=`
  }${encodeURIComponent(JSON.stringify(eventData))}`;
  appendImg(imgUrl);
}

function dataLayerPush(obj: any) {
  const w = window as any;
  w.dataLayer ??= [];
  w.dataLayer.push(obj);
}

function appendDiv(innerHTML: string) {
  const div = document.createElement('div');
  div.style.display = 'none';
  div.innerHTML = innerHTML;
  const scripts: HTMLScriptElement[] = [].slice.call(div.getElementsByTagName('script'));
  for (const s of scripts) {
    s.parentElement?.removeChild(s);
    if (s.src) {
      appendScript(s.src);
    } else {
      const script = document.createElement('script');
      script.text = s.text;
      document.body.appendChild(script);
    }
  }
  document.body.appendChild(div);
}

function appendImg(srcUrl: string) {
  const img = document.createElement('img');
  img.style.display = 'none';
  img.setAttribute('src', srcUrl);
  document.body.appendChild(img);
}

function appendScript(url: string, onload?: () => void | Promise<void>) {
  const script = document.createElement('script');
  if (onload) {
    script.onload = onload;
  }
  script.src = url;
  document.body.appendChild(script);
}
