import * as types from '../actions/action-types';
import initialState from './initial-state';

export interface NotificationState {
  payment: PaymentNotificationState;
}

export interface PaymentNotificationState {
  amount: number;
  show: boolean;
}

export default function notification(
  state: NotificationState = initialState.notification,
  action: any
): NotificationState {
  switch (action.type) {
    case types.SHOW_PAYMENT_NOTIFICATION:
      return {
        ...state,
        payment: { ...state.payment, ...action.payment },
      };

    default:
      return state;
  }
}
