export const LOAD_MORE_CAMPAIGNS_SUCCESS = 'LOAD_MORE_CAMPAIGNS_SUCCESS';
export const LOAD_CAMPAIGNS_SUCCESS = 'LOAD_CAMPAIGNS_SUCCESS';
export const LOAD_TRANSACTIONS_SUCCESS = 'LOAD_TRANSACTIONS_SUCCESS';
export const LOAD_PAYPAL_EMAIL_SUCCESS = 'LOAD_PAYPAL_EMAIL_SUCCESS';
export const LOAD_TRANSACTIONS_MONTH_SUCCESS = 'LOAD_TRANSACTIONS_MONTH_SUCCESS';
export const LOAD_TRANSACTIONS_BY_PERIOD_SUCCESS = 'LOAD_TRANSACTIONS_BY_PERIOD_SUCCESS';
export const LOAD_TRANSACTIONS_BY_PERIOD_REQUEST = 'LOAD_TRANSACTIONS_BY_PERIOD_REQUEST';
export const LOAD_TRANSACTIONS_BY_PERIOD_ERROR = 'LOAD_TRANSACTIONS_BY_PERIOD_ERROR';
export const LOAD_REDEMPTIONS_SUCCESS = 'LOAD_REDEMPTIONS_SUCCESS';
export const LOAD_REDEMPTIONS_AVAILABILITY_SUCCESS = 'LOAD_REDEMPTIONS_AVAILABILITY_SUCCESS';
export const LOAD_USER_PROFILE_SUCCESS = 'LOAD_USER_PROFILE_SUCCESS';
export const SAVE_USER_PROFILE_SUCCESS = 'SAVE_USER_PROFILE_SUCCESS';
export const LOAD_USER_BALANCE_SUCCESS = 'LOAD_USER_BALANCE_SUCCESS';
export const REEDEMED_SUCCESS = 'REEDEMED_SUCCESS';
export const LOAD_BANK_DATA_SUCCESS = 'LOAD_BANK_DATA_SUCCESS';
export const VALIDATE_SMARTFUEL_CARD = 'VALIDATE_SMARTFUEL_CARD';
export const UPDATE_USER_BALANCE = 'UPDATE_USER_BALANCE';
export const LOAD_SURVEY_THEME_NOT_FOUND = 'LOAD_SURVEY_THEME_NOT_FOUND';
export const LOAD_SURVEY_THEME_CSS = 'LOAD_SURVEY_THEME_CSS';
export const BEGIN_AJAX_CALL = 'BEGIN_AJAX_CALL';
export const AJAX_CALL_ERROR = 'AJAX_CALL_ERROR';
export const SHOW_PAYMENT_NOTIFICATION = 'SHOW_PAYMENT_NOTIFICATION';
export const LOAD_SURVEY_THEMES_SUCCESS = 'LOAD_SURVEY_THEMES_SUCCESS';
export const CAMPAIGN_REPORTED_BROKEN = 'CAMPAIGN_REPORTED_BROKEN';
export const HIDE_CAMPAIGN = 'HIDE_CAMPAIGN';
export const GET_COMPETITIONS_FULFILLED = 'GET_COMPETITIONS_FULFILLED';
export const GET_REDEMPTIONS_FULFILLED = 'GET_REDEMPTIONS_FULFILLED';
export const ELIGIBILITY_TO_REFER_ENABLED = 'ELIGIBILITY_TO_REFER_ENABLED';
export const ONBOARDING_CLEAR_DATA = 'ONBOARDING_CLEAR_DATA';
export const ONBOARDING_CAMPAIGNS_LOADED = 'ONBOARDING_CAMPAIGNS_LOADED';
export const ONBOARDING_CAMPAIGN_COMPLETED = 'ONBOARDING_CAMPAIGN_COMPLETED';
export const ONBOARDING_ANSWER_SUBMITTED = 'ONBOARDING_ANSWER_SUBMITTED';
export const ONBOARDING_LOADING = 'ONBOARDING_LOADING';
export const ONBOARDING_COMPLETED = 'ONBOARDING_COMPLETED';
export const ONBOARDING_FETCH = 'ONBOARDING_FETCH';
export const ONBOARDING_ERROR = 'ONBOARDING_ERROR';
export const LOAD_INSTANCE_AND_PANEL_INFO_SUCCESS = 'LOAD_INSTANCE_AND_PANEL_INFO_SUCCESS';
export const UPDATE_PANEL_INFO = 'UPDATE_PANEL_INFO';
export const ROUTE_TYPE = 'ROUTE_TYPE';
export const GET_OFFERS_AND_GAMES_TRANSACTIONS_FULFILLED = 'GET_OFFERS_AND_GAMES_TRANSACTIONS_FULFILLED';
