import * as types from '../actions/action-types';
import { RedeemAction } from '../actions/redeem-actions';
import { RedemptionResult } from '../../declarations/ah-api';

export default function redeemReducer(
  state: RedemptionResult = {} as RedemptionResult,
  action: RedeemAction
): RedemptionResult {
  switch (action.type) {
    case types.REEDEMED_SUCCESS:
      return action.redemptionResult;
    default:
      return state;
  }
}
