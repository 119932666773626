import { getQueryObj, hasOnboardingParametersInQuery, isWindowOnboardingUrl, setQueryObj } from './utils/uri';
import { browserStorageGet, browserStorageRemove, browserStorageSet, isBrowserStorageOk } from './utils/storage';
import { isProduction } from './app-config';

export function retrieveToken(): string | null {
  // retrieve from query first
  const query = getQueryObj();

  // both 'token' and 'pp-token' names are supported in query
  let ppToken: string | null | undefined = query.token || query['pp-token'];

  // if present in the query, store it in the cookie
  if (ppToken) {
    storeToken(ppToken);
  }

  if (ppToken && isBrowserStorageOk()) {
    // remove from query
    delete query.token;
    delete query['pp-token'];
    // update the query
    setQueryObj(query);
  }

  if (query.refresh) {
    delete query.refresh;
    // update the query
    setQueryObj(query);
  }

  if (ppToken) {
    // return value signalizes, whether we found the token or not
    return ppToken;
  }

  // special case for /onboarding?provider=xxx&userId=yyy
  if (isWindowOnboardingUrl() && hasOnboardingParametersInQuery()) {
    return clearToken();
  }

  // retrieve from storage
  ppToken = browserStorageGet('pp-token', null);

  // if it's a stringified format
  if (ppToken?.startsWith('"')) {
    ppToken = JSON.parse(ppToken);
  }

  // fix for weird PPD-11780 that happens only on ios devices
  if (typeof ppToken === 'string') {
    ppToken = ppToken.replace(/[^-0-9a-f]+/gi, '').slice(0, 36);
  }

  return ppToken ?? null;
}

export function storeToken(ppToken: string) {
  if (!isProduction) {
    if (typeof ppToken !== 'string') {
      throw new Error(`ppToken is not a string: ${ppToken}`);
    }
    if (ppToken !== ppToken.replace(/[^-0-9a-f]+/gi, '').slice(0, 36)) {
      throw new Error(`ppToken is not a valid uuid: ${ppToken}`);
    }
  }
  browserStorageSet('pp-token', ppToken);
}

export function clearToken(): null {
  browserStorageRemove('pp-token');
  return null;
}
