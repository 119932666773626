import { CSSProperties, FC } from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import { useAppTranslation } from '../../i18n';

const GenericLoader: FC<{
  iconOnly?: boolean;
  style?: CSSProperties;
}> = ({ iconOnly, style }) => {
  const { t } = useAppTranslation('survey-tool');
  const classes = useStyles();
  return (
    <div style={style} className={clsx(classes.loading)}>
      <div className={clsx(classes.spinner)}>
        <div className={clsx(classes.bounce, classes.bounce1)} />
        <div className={clsx(classes.bounce, classes.bounce2)} />
        <div className={clsx(classes.bounce)} />
      </div>
      {!iconOnly && <div className={clsx(classes.text)}>{t('loading', 'Loading')}</div>}
    </div>
  );
};

export const GenericLoaderNoI18N: FC<{
  style?: CSSProperties;
}> = ({ style }) => {
  const classes = useStyles();
  return (
    <div style={style} className={clsx(classes.loading)}>
      <div className={clsx(classes.spinner)}>
        <div className={clsx(classes.bounce, classes.bounce1)} />
        <div className={clsx(classes.bounce, classes.bounce2)} />
        <div className={clsx(classes.bounce)} />
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  loading: {
    flexGrow: 1,
    transform: 'scale(.5)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  text: {
    fontSize: '24px',
    paddingTop: '15px',
    color: '#888',
    textAlign: 'center',
  },
  spinner: {
    minWidth: '70px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  bounce: {
    width: '18px',
    height: '18px',
    backgroundColor: '#888',
    borderRadius: '100%',
    display: 'inline-block',
    animationIterationCount: 'infinite',
    animationFillMode: 'forwards',
    animationDuration: '1.4s',
    animationTimingFunction: 'ease-in-out',
    animationName: '$bounce',
  },
  '@keyframes bounce': {
    '0%': {
      transform: 'scale(0)',
    },
    '40%': {
      transform: 'scale(1)',
    },
    '80%': {
      transform: 'scale(0)',
    },
    '100%': {
      transform: 'scale(0)',
    },
  },
  bounce1: {
    animationDelay: '-0.32s',
  },
  bounce2: {
    animationDelay: '-0.16s',
  },
});

export default GenericLoader;
