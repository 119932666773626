import * as types from '../actions/action-types';
import initialState from './initial-state';
import { SurveyThemesAction } from '../actions/survey-themes-actions';

interface SurveyThemesState {
  surveyThemes?: { [surveyThemeUuid: string]: any };
}

export default function surveyThemesReducer(
  state: SurveyThemesState = initialState.surveyThemes,
  action: SurveyThemesAction
) {
  switch (action.type) {
    case types.LOAD_SURVEY_THEMES_SUCCESS:
      return { ...state, ...action.surveyThemes };
    default:
      return state;
  }
}
