import * as types from '../actions/action-types';
import initialState from './initial-state';
import { TransactionsInfo } from '../api/ah-api';
import { TransactionActions } from '../actions/transaction-actions';

export default function transactionReducer(
  state: TransactionsInfo = initialState.transactionsInfo,
  action: TransactionActions
): TransactionsInfo {
  switch (action.type) {
    case types.LOAD_TRANSACTIONS_SUCCESS: {
      return action.transactionsInfo;
    }

    default:
      return state;
  }
}
