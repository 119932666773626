import * as cookies from './cookies';

type USED_KEYS = 'storage-test' | 'pp-token' | 'domainInfo' | 'instanceInfo' | 'panelInfo' | 'onboarding';
type STORAGE_LOCATION = 'local-storage' | 'cookie';

function logError(msg: string) {
  // eslint-disable-next-line no-console
  console.error(msg);
}

function stringify(key: USED_KEYS, val: any) {
  if (val == null) {
    return null;
  }
  if (key === 'pp-token') {
    return val;
  }
  try {
    return JSON.stringify(val);
  } catch (err) {
    logError(`storage.stringify(${key}) couldn't stringify: ${val}`);
    return val;
  }
}

function parse(key: USED_KEYS, val: any) {
  if (val == null) {
    return null;
  }
  if (key === 'pp-token') {
    return val;
  }
  if (typeof val === 'string') {
    try {
      return JSON.parse(val);
    } catch (err) {
      logError(`storage.parse(${key}) couldn't parse: ${val} json: ${JSON.stringify(val)}`);
    }
  }
  return val;
}

function getLocalStorage(): Storage | null {
  // window.localStorage is null in android webview
  return window.localStorage ?? null;
}

function localStorageSet<T>(key: USED_KEYS, val: T): boolean {
  const ls = getLocalStorage();
  if (!ls) {
    return false;
  }
  try {
    const stringifiedVal = stringify(key, val);
    if (stringifiedVal != null) {
      ls.setItem(key, stringifiedVal);
    } else {
      localStorageRemove(key);
      return true;
    }

    // double-check we actually stored, what we wanted to store
    if (val !== localStorageGet(key)) {
      localStorageRemove(key);
      return false;
    }

    return true;
  } catch (err) {
    const strErr = err?.toString?.();
    if (strErr?.includes('QuotaExceededError')) {
      return false;
    }
    logError(`localStorageSet(${key}) threw: ${strErr}`);
    return false;
  }
}

function localStorageGet<T>(key: USED_KEYS): T | null {
  try {
    const val = getLocalStorage()?.getItem(key);
    return val ? parse(key, val) : null;
  } catch (err) {
    logError(`localStorageGet(${key}) threw: ${err}`);
    localStorageRemove(key);
    return null;
  }
}

function localStorageRemove(key: USED_KEYS): void {
  try {
    getLocalStorage()?.removeItem(key);
  } catch (err) {
    logError(`localStorageRemove(${key}) threw: ${err}`);
  }
}

function cookieSet<T>(key: USED_KEYS, val: T): boolean {
  try {
    const stringifiedVal = stringify(key, val);
    if (stringifiedVal != null) {
      cookies.setCookie(key, stringifiedVal);
    } else {
      cookieRemove(key);
    }
    return true;
  } catch (err) {
    logError(`cookieSet(${key}) threw: ${err}`);
    return false;
  }
}

function cookieGet<T>(key: USED_KEYS): T | null {
  try {
    const val = cookies.getCookie(key);
    return val ? parse(key, val) : null;
  } catch (err) {
    logError(`cookieGet(${key}) threw: ${err}`);
    cookieRemove(key);
    return null;
  }
}

function cookieRemove(key: USED_KEYS): void {
  try {
    cookies.removeCookie(key);
  } catch (err) {
    logError(`cookieRemove(${key}) threw: ${err}`);
  }
}

const inMemoryStorage: Partial<Record<USED_KEYS, string>> = {};

function inMemorySet<T>(key: USED_KEYS, json: T) {
  const stringifiedVal = stringify(key, json);
  if (stringifiedVal != null) {
    inMemoryStorage[key] = stringifiedVal;
  } else {
    inMemoryRemove(key);
  }
}

function inMemoryGet<T>(key: USED_KEYS): T | null {
  const val = inMemoryStorage[key];
  return val ? parse(key, val) : null;
}

function inMemoryRemove(key: USED_KEYS): void {
  delete inMemoryStorage[key];
}

export function browserStorageSet<T>(key: USED_KEYS, json: T, where?: STORAGE_LOCATION) {
  let stored = false;
  if (!stored && (!where || where === 'local-storage')) {
    stored = localStorageSet(key, json);
  }
  if (!stored && (!where || where === 'cookie')) {
    stored = cookieSet(key, json);
  }
  if (!stored) {
    _isBrowserStorageOk = false;
  }
  inMemorySet(key, json);
}

export function browserStorageGet<T, D>(key: USED_KEYS, defaultValue: D): T | D {
  return localStorageGet(key) ?? cookieGet(key) ?? inMemoryGet(key) ?? defaultValue;
}

export function browserStorageRemove(key: USED_KEYS) {
  localStorageRemove(key);
  cookieRemove(key);
  inMemoryRemove(key);
}

let _isBrowserStorageOk = true;

browserStorageSet('storage-test', 'storage-test', 'local-storage');
browserStorageRemove('storage-test');

const ua = window.navigator.userAgent;
const isSafari = ua.includes('Safari') && !ua.includes('Chrome') && !ua.includes('CriOS');

export function isBrowserStorageOk() {
  return _isBrowserStorageOk && !isSafari;
}
