export const NBSP = '\u00A0'; // non-breaking space

export function trimmedForAhApi(str: string): string | null {
  if (typeof str !== 'string') {
    return str;
  }
  // make sure strings are trimmed and without weird whitespace
  const result = str.trim().replace(/\s+/g, ' ');
  // never return '', return null instead
  return result || null;
}

export function ensureHttps(url: string) {
  if (typeof url !== 'string') {
    return url;
  }
  if (url.startsWith('http:')) {
    return url.replace(/^http:/, 'https:');
  }
  return url;
}
