import { AnyAction, ThunkAction } from '@reduxjs/toolkit';
import decorator from '../../styles/style.settings.decorator';
import { LOAD_INSTANCE_AND_PANEL_INFO_SUCCESS, UPDATE_PANEL_INFO } from '../actions/action-types';
import { onPanelInfoLoaded } from '../../utils/tracking-tags';
import { PanelInfo } from '../../survey-tool/declarations/ah-api';
import { getInstanceInfoByPanelUuid } from '../api/ah-api';
import { loadSurveyTheme } from '../actions/survey-theme-actions';
import { browserStorageSet } from '../../utils/storage';
import { RootState } from '../store';

function fixPanelInfo(panelInfo: PanelInfo) {
  if (!panelInfo) {
    return panelInfo;
  }
  panelInfo.app_theme ??= {} as any;
  panelInfo.app_theme.color_cta ||= '#000000';
  panelInfo.app_theme.font_color_cta ||= '#FFFFFF';
  return panelInfo;
}

export function panelInfoLoadedReducer(state = false, action: AnyAction): boolean {
  switch (action.type) {
    case LOAD_INSTANCE_AND_PANEL_INFO_SUCCESS:
      return true;
    default:
      return state;
  }
}

export function panelInfoReducer(state: PanelInfo = null as any as PanelInfo, action: AnyAction): PanelInfo {
  switch (action.type) {
    case LOAD_INSTANCE_AND_PANEL_INFO_SUCCESS:
    case UPDATE_PANEL_INFO:
      action.panelInfo = fixPanelInfo(action.panelInfo);
      decorator.setPanelInfo(action.panelInfo);
      onPanelInfoLoaded(action.panelInfo);
      // this is being used in goToLoginUrl method, do not remove
      browserStorageSet('panelInfo', action.panelInfo, 'local-storage');
      return action.panelInfo;
    default:
      return state;
  }
}

export const setSelectedPanel =
  (panelUuid: string): ThunkAction<Promise<void>, RootState, void, any> =>
  async (dispatch, getState) => {
    if (panelUuid === getState().panelInfo?.guid) {
      return;
    }
    const instanceInfo = await getInstanceInfoByPanelUuid(panelUuid);
    const panelInfo = instanceInfo.panels[panelUuid]!;
    dispatch({ type: LOAD_INSTANCE_AND_PANEL_INFO_SUCCESS, instanceInfo, panelInfo });
    dispatch(loadSurveyTheme(panelInfo));
  };
