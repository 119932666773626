import { useCallback, useState, FC } from 'react';
import defaultsDeep from 'lodash/defaultsDeep';
import { SxProps } from '@mui/material';
import Button, { ButtonProps } from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { isPromise } from '../utils/is-promise';
import useEventListener from '../utils/react-hooks/useEventListener';
import useIsMounted from '../utils/react-hooks/useIsMounted';

const sxButtonLoading: SxProps = {
  '&.MuiButton-root:disabled': {
    cursor: 'wait',
    pointerEvents: 'auto',
  },
};

export interface PPButtonProps extends Omit<ButtonProps, 'onClick'> {
  onClick?: (event: MouseEvent) => void | Promise<void>;
  loading?: boolean;
}

export const PPButton: FC<PPButtonProps> = ({
  color = 'primary', // drives colors of the button
  onClick: _onClick, // should return a promise, a loading state will be shown until that promise is resolved
  loading: _loading, // notify that loading is in progress and button should be disabled with a wait icon
  className: _className,
  disabled: _disabled,
  sx: _sx,
  children, // children to be rendered
  ...btnProps // rest of the props gets passed to Button component
}) => {
  const [btn, setBtn] = useState<HTMLButtonElement | null>();
  const isMounted = useIsMounted();
  const [onClickInProgress, setOnClickInProgress] = useState<boolean>(false);

  const onClick = useCallback(
    (event: MouseEvent): void => {
      if (typeof _onClick !== 'function') {
        return;
      }
      event.stopPropagation();
      const maybePromise = _onClick(event);
      if (isPromise(maybePromise)) {
        setOnClickInProgress(true);
        maybePromise.finally(() => {
          setTimeout(() => {
            if (isMounted()) {
              setOnClickInProgress(false);
            }
          }, 1);
        });
      }
    },
    [isMounted, _onClick]
  );
  // NOTE: we need to use useEventListener to support survey widget implementation of survey-tool
  // this helps to avoid wrapping the element with <Clickable> component
  useEventListener('click', onClick, btn);

  const loading = _loading || onClickInProgress;
  let className = _className;
  let disabled = _disabled;
  let sx = _sx;

  if (loading) {
    className ??= '';
    className += ' PP-loading';
    className = className.trim();
    disabled = true;
    sx = defaultsDeep({}, sxButtonLoading, sx);
  }

  return (
    <Button ref={setBtn} {...btnProps} color={color} className={className} disabled={disabled} sx={sx}>
      {children}
      {loading ? <CircularProgress size={btn ? btn.clientHeight * 0.75 : undefined} /> : null}
    </Button>
  );
};
