import Box from '@mui/material/Box';
import { useCallback, FC } from 'react';
import { getWelcomeCampaigns } from '../store/api/ah-api';
import { PPButton } from '../survey-tool/components/pp-button';
import { SurveyBoxGenericFooterBtnWidth } from '../survey-tool/components/survey-footer/survey-box-generic-footer';
import { UserProfile } from '../survey-tool/declarations/auth-api';
import { SurveyArguments } from '../survey-tool/survey-container';
import { useAppNavigate } from '../utils/browser-navigate';
import { redirectToCampaign } from '../utils/redirects';
import { useAppTranslation } from '../i18n';

export interface SubmitButtonProps {
  args: SurveyArguments;
  userProfile: UserProfile | null;
  isOnboarding?: boolean;
}

let redirectToSetPath: string | null = null;
let redirectToSetText: string | null = null;
const redirectToDefaultPath = 'feed';
const redirectToDefaultText = 'Take me back to my feed';

export function overrideBackToFeed(path: string, text: string) {
  redirectToSetPath = path || null;
  redirectToSetText = text || null;
}

export const BackToFeed: FC<SubmitButtonProps> = ({ args, userProfile, isOnboarding }) => {
  const { t } = useAppTranslation();
  const navigate = useAppNavigate();
  const { campaignGoResult, services } = args;

  const onClick = useCallback(
    async (event: MouseEvent) => {
      event.preventDefault();

      if (isOnboarding) {
        services.redirectTo('');
        return;
      }

      if (userProfile && campaignGoResult?.thank_you?.is_welcome) {
        // we're closing a welcome campaign, we should re-check if there're more welcome campaigns
        const response = await getWelcomeCampaigns(userProfile);
        if (response.length > 0) {
          // some welcome campaigns have been found, redirect to it
          void redirectToCampaign(navigate, response[0].guid);
          return;
        }
      }

      if (redirectToSetPath) {
        services.redirectTo(redirectToSetPath);
        redirectToSetPath = null;
        redirectToSetText = null;
        return;
      }

      services.redirectTo(redirectToDefaultPath);
    },
    [navigate, campaignGoResult, services, userProfile, isOnboarding]
  );

  return (
    <Box sx={{ margin: '10px', minWidth: SurveyBoxGenericFooterBtnWidth }}>
      <PPButton onClick={onClick} sx={{ width: '100%' }}>
        {redirectToSetText || t('takeMeBackToMyFeed', redirectToDefaultText)}
      </PPButton>
    </Box>
  );
};
