import { AphroditeCSSProperties } from '../utils/aphrodite';

/* eslint no-multi-spaces:0 */
/* eslint key-spacing:0 */

export const icons = {
  connectPoints: '"\\e9cc"',
  transactionsNcaNormal: '"\\e9cb"',
  home32: '"\\e938"',
  rewards32: '"\\e962"',
  referrals32: '"\\e9a8"',
  transactions32: '"\\e971"',
  logout32: '"\\e942"',
  settings32: '"\\e96a"',
  star16: '"\\e98a"',
  satisfaction: '"\\e9c2"',
  satisfaction2: '"\\e9c4"',
  satisfaction3: '"\\e9c6"',
  offers32: '"\\e94c"',
  menu24: '"\\e988"',
  transactionGBP32: '"\\e907"',
  balanceGBP16: '"\\e908"',
  balance16: '"\\e909"',
  balanceEuro16: '"\\e9ac"',
  close16: '"\\e913"',
  close24: '"\\e914"',
  dropdownArrow16: '"\\e91f"',
  settings16: '"\\e969"',
  logout16: '"\\e941"',
  help16: '"\\e97a"',
  duration16: '"\\e921"',
  clock: '"\\f017"',
  deleteCircle16: '"\\e918"',
  most: '"\\e9c6"',
  least: '"\\e9c2"',
  next16: '"\\e94a"',
  previous16: '"\\e956"',
  reorder16: '"\\e960"',
  like: '"\\e9c1"',
  add: '"\\e902"',
  faceBook: '"\\e9c8"',
  faceBook32: '"\\e9a2"',
  twitter: '"\\e99f"',
  twitter32: '"\\e9a7"',
  linkedin: '"\\e99e"',
  google: '"\\e9c9"',
  google2: '"\\e9ca"',
  linkedin32: '"\\e9a5"',
  addCircle16: '"\\e903"',
  user32: '"\\e978"',
  mobile32: '"\\e948"',
  error16: '"\\e926"',
  errorCircle16: '"\\e928"',
  check16: '"\\e911"',
  password32: '"\\e951"',
  email32: '"\\e924"',
  paypal32: '"\\e953"',
  photoCard32: '"\\e954"',
  uploadPhoto32: '"\\e976"',
  bankAccount32: '"\\e90a"',
  externalLink16: '"\\e92a"',
  smartfuel16: '"\\e9c7"',
  notification16: '"\\e94b"',
  pureprofile: '"\\e999"',
};

export const getIcon = (icon: any): AphroditeCSSProperties => ({
  fontFamily: 'Pureprofile',
  fontStyle: 'normal',
  'vertical-align': 'middle',
  '-webkit-font-smoothing': 'antialiased',
  ':before': {
    content: icon,
  },
});

export const getIconStyles = (icon: any) =>
  ({
    fontFamily: 'Pureprofile',
    fontStyle: 'normal',
    verticalAlign: 'middle',
    fontSmooth: 'antialiased',
    '&::before': {
      content: icon,
    },
  }) as const;
