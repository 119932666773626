import { Dayjs } from 'dayjs';
import { createAction } from 'typesafe-actions';
import { TransactionFilterType } from '../reducers/transaction-filtering-reducer';

export const onSelectFilter = createAction(
  'TRANSACTION_FILTERING_ON_SELECT_FILTER',
  (resolve) => (filter: TransactionFilterType) =>
    ({
      type: 'TRANSACTION_FILTERING_ON_SELECT_FILTER',
      payload: { filter },
    }) as any
);

export const onSelectBefore = createAction(
  'TRANSACTION_FILTERING_ON_SELECT_BEFORE',
  (resolve) => (date: Dayjs | null) =>
    ({
      type: 'TRANSACTION_FILTERING_ON_SELECT_BEFORE',
      payload: { date },
    }) as any
);

export const onSelectAfter = createAction(
  'TRANSACTION_FILTERING_ON_SELECT_AFTER',
  (resolve) => (date: Dayjs | null) =>
    ({
      type: 'TRANSACTION_FILTERING_ON_SELECT_AFTER',
      payload: { date },
    }) as any
);
