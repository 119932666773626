import Cookies, { CookieGetOptions, CookieSetOptions } from 'universal-cookie';

const cookies = new Cookies();

function getDefaultOptions(): CookieSetOptions {
  return {
    expires: new Date(2099, 11, 31),
    secure: true,
    httpOnly: false,
    sameSite: 'strict',
  };
}

export function setCookie(name: string, value: string, options?: CookieSetOptions) {
  return cookies.set(name, value, {
    ...getDefaultOptions(),
    ...options,
  });
}

export function getCookie(name: string, options?: CookieGetOptions): string | null {
  return cookies.get(name, options);
}

export function removeCookie(name: string, options?: CookieSetOptions) {
  return cookies.remove(name, options);
}
