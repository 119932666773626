import { useCallback, FC } from 'react';
import Box from '@mui/material/Box';
import { useSurveyContextSelector } from '../../survey-context';
import { CenterBox } from '../../utils/center-box';
import { SurveyBoxGenericFooterBtnWidth } from './survey-box-generic-footer';
import { PPButton } from '../pp-button';

export const GoHomeButton: FC = () => {
  const services = useSurveyContextSelector((ctx) => ctx.args.services);
  const onClick = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();
      services.redirectTo('');
    },
    [services]
  );
  return (
    <CenterBox>
      <Box sx={{ flexGrow: 1, maxWidth: SurveyBoxGenericFooterBtnWidth, margin: '10px' }}>
        <PPButton onClick={onClick} sx={{ width: '100%' }}>
          Go to Homepage
        </PPButton>
      </Box>
    </CenterBox>
  );
};
