/* eslint-disable max-len */
import * as Redux from '@reduxjs/toolkit';
import * as types from './action-types';
import * as AhApi from '../api/ah-api';

export interface TransactionMonthActions extends Redux.Action {
  transactions: AhApi.TransactionListing[] | AhApi.TransactionsMonthListing[];
}
export interface TransactionMonthDetailsActions extends Redux.Action {
  transactionMonth: AhApi.TransactionsMonth;
}

export function loadTransactionsMonthSuccess(transactions: AhApi.TransactionsMonthListing[]) {
  return { type: types.LOAD_TRANSACTIONS_MONTH_SUCCESS, transactions };
}

export function loadTransactionsByPeriodSuccess(transactions: AhApi.TransactionListing[]) {
  return { type: types.LOAD_TRANSACTIONS_BY_PERIOD_SUCCESS, transactions };
}

export function loadTransactionsByPeriodRequest(transactionMonth: AhApi.TransactionsMonth) {
  return { type: types.LOAD_TRANSACTIONS_BY_PERIOD_REQUEST, transactionMonth };
}

export function loadTransactionsByPeriodError(transactionMonth: AhApi.TransactionsMonth) {
  return { type: types.LOAD_TRANSACTIONS_BY_PERIOD_ERROR, transactionMonth };
}
