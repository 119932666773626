import endsWith from 'lodash/endsWith';
import { FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { gtagPageView } from './utils/gtag';

// analytics stuff
const logPageView = (pathname: string, search: string): void => {
  if (pathname === '/') {
    // NOTE: this will redirect to /feed, we don't want doubled page views
    return;
  }
  const pathnameWithoutTrailingSlash = endsWith(pathname, '/') ? pathname.slice(0, -1) : pathname;
  gtagPageView(pathnameWithoutTrailingSlash + search);
};

export const ReactPageViews: FC = () => {
  const location = useLocation();
  useEffect(() => {
    logPageView(location.pathname, location.search);
  }, [location.pathname, location.search]);
  return null;
};
