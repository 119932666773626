import { ThunkAction } from '@reduxjs/toolkit';
import * as types from './action-types';
import * as AhApi from '../api/ah-api';
import { redirectToCampaign } from '../../utils/redirects';
import { UserProfile } from '../../survey-tool/declarations/auth-api';
import { loadSurveyTheme } from './survey-theme-actions';
import { goToAcceptTermsAndConditions, goToValidateEmail } from '../../utils/login-logout-uri';
import { RootState } from '../store';
import { browserNavigate } from '../../utils/browser-navigate';

export function loadInstanceInfo(userProfile: UserProfile): ThunkAction<Promise<void>, RootState, void, any> {
  return async function (dispatch, getState) {
    const { panelKey } = userProfile;
    if (!panelKey) {
      throw new Error(`panelKey is not present on the userProfile`);
    }
    const instanceInfo = await AhApi.getInstanceInfoByPanelUuid(panelKey);
    const { panels } = instanceInfo;
    const panelInfo = panels[panelKey];
    if (!panelInfo) {
      throw new Error(`instanceInfo doesn't contain users panel ${panelKey}`);
    }

    // dispatch before doing any checks
    dispatch({ type: types.LOAD_INSTANCE_AND_PANEL_INFO_SUCCESS, instanceInfo, panelInfo });
    dispatch(loadSurveyTheme(panelInfo));

    const s = getState();
    if (s.routeType !== 'any') {
      if (!userProfile.acceptedTermsConditions && panelInfo.app_settings.terms_required) {
        return goToAcceptTermsAndConditions(panelInfo, userProfile);
      }
      if (!userProfile.emailValidated && panelInfo.app_settings.email_validation_reminder) {
        return goToValidateEmail(panelInfo, userProfile);
      }
    }

    // we need to check welcome campaigns, since welcome campaign might have been just completed
    if (s.routeType === 'private') {
      const response = await AhApi.getWelcomeCampaigns(userProfile);
      if (response.length > 0) {
        // some welcome campaigns have been found, redirect to it
        void redirectToCampaign(browserNavigate, response[0].guid);
      }
    }
  };
}
