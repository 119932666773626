import { EligibleToRedeem } from '../api/auth-api';

const initialState = {
  ajaxCallsInProgress: 0,
  instance: [],
  notification: {
    payment: {
      amount: 0,
      show: false,
    },
  },
  mobile: {
    eligibleToRedeem: 'not-verified' as EligibleToRedeem,
    mobileNumber: '',
    verificationStatus: '',
    verifiedMobileNumber: '',
    verifiedMobileNumberAt: '',
  },
  campaign: {
    id: '',
    type: '',
    campaign_status: '',
    invitation: { name: '' },
    question: {
      answers: [],
      text: '',
      descr: '',
      control_type: '',
      image: { url: '' },
      videoid: '',
      max_fields: '',
      min_chars: '',
      max_chars: '',
      min_answers: '',
      min_num: '',
      max_num: '',
      html: '',
      spend_min: '',
      spend_period: [],
      freq_min: 0,
      freq_max: 0,
      freq_type: '',
      freq_period: [],
      scale_answers: 0,
      scale_labels: [],
    },
    progress: { answered: 0, questions: 1 },
    thank_you: {},
  },
  surveyThemes: {},
  bankData: [],
  availability: {
    minimum: 20,
  },
  transactionsFiltering: {
    after: '',
    before: '',
    currentFilter: null,
  },
  transactionsInfo: {
    results: 0,
    transactions: [],
  },
  locationLookup: [],
  customStyles: {
    root: {},
    header: {
      css: {
        surveyTitle: {},
      },
      data: {
        message: '',
      },
    },
    mainView: {},
    surveyBox: {
      surveyBoxHeader: {},
      surveyBoxProgressBar: {},
      surveyBoxFooter: {
        buttonActive: {},
        buttonInactive: {},
      },
      surveyControl: {
        selectRadio: {},
        selectClickBar: {},
        selectDropdown: {
          selectDropdownDefault: {},
          selectDropdownRowActive: {},
        },
        multiSelect: {
          multiSelectListRowDefault: {},
          multiSelectListRowActive: {},
        },
      },
    },
    footer: {
      css: {},
      links: [],
    },
  },
  auth: {
    appLoaded: false,
    redirectTo: '',
  },
  competitions: [],
};

export default initialState;
