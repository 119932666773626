/* eslint no-bitwise:0 */

import omitBy from 'lodash/omitBy';
import { clr } from '../survey-tool/styles/settings';
import { PanelInfo } from '../survey-tool/declarations/ah-api';

interface StyleSettingsInterface {
  themeID: string;
  web_app_primary: string;
  web_app_secondary: string;
  highlight: string;
  dropDownArrow: string; // -- CTA color
  deleteIcon: string; // -- CTA color
  font_url: string;
  font_family: string;
  headerFontColor: string;
  linkFontColor: string;
  textFontColor: string;
  link: string;
  logo: string;
  favicon: string;
  loadingLogo: string;
  logoIcon: string;
}
class StyleSettingsDecorator {
  private clr: Readonly<typeof clr & StyleSettingsInterface>;

  // we construct the webAppStyle out of the data that we will retrieve from the backend
  private webAppStyle: StyleSettingsInterface = {
    themeID: '',
    web_app_primary: '#475369',
    web_app_secondary: clr.primaryDark,
    highlight: '#4169e1', // -- CTA color
    dropDownArrow: '#4169e1', // -- CTA color
    deleteIcon: '#4169e1', // -- CTA color
    font_url: 'https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i',
    font_family: `'Open Sans', 'Tahoma', 'Geneva', 'sans-serif'`,
    headerFontColor: '#FFFFFF',
    linkFontColor: '#909090',
    textFontColor: '#444444',
    link: '#909090', // takes the linkFontColor
    logo: 'https://cloudcdn.pureprofile.com/image/upload/v1/__assets_master__/a3d15f39cd4d4c54b604d3873d967951',
    favicon: 'https://cloudcdn.pureprofile.com/image/upload/v1/__assets_master__/b1a0c316ad7f4a679c2eee615814466c',
    loadingLogo: 'https://cloudcdn.pureprofile.com/image/upload/v1/__assets_master__/70e53b653333473da37d1b759e6d5cfc',
    logoIcon: 'https://cloudcdn.pureprofile.com/image/upload/v1/__assets_master__/7f3bb833eb92472f826d418da424b0eb',
  };

  public setPanelInfo(panelInfo: PanelInfo) {
    let extendWebAppStyleWith: Partial<StyleSettingsInterface> = {
      themeID: panelInfo.app_settings.theme,
      web_app_primary: panelInfo?.app_theme.color_primary,
      web_app_secondary: panelInfo?.app_theme.color_secondary,
      highlight: panelInfo?.app_theme.color_cta,
      dropDownArrow: panelInfo?.app_theme.font_color_secondary,
      deleteIcon: panelInfo?.app_theme.font_color_secondary,
      font_url: panelInfo?.app_theme.panel_font_url,
      font_family: panelInfo?.app_theme.panel_font_family,
      headerFontColor: panelInfo?.app_theme.header_font_color,
      linkFontColor: panelInfo?.app_theme.link_font_color,
      textFontColor: panelInfo?.app_theme.text_font_color,
      link: panelInfo?.app_theme.link_font_color,
      logo: panelInfo?.app_theme.logo_url,
      favicon: panelInfo?.app_theme.logo_favicon_url,
      loadingLogo: panelInfo?.app_theme.logo_loader_icon_url,
      logoIcon: panelInfo?.app_theme.logo_inline_url,
    };

    // remove null values from the settings
    extendWebAppStyleWith = omitBy(extendWebAppStyleWith, (val) => val == null || val === '');
    // extend webAppStyle with only non-null values
    this.clr = {
      ...this.webAppStyle,
      ...clr,
      ...extendWebAppStyleWith,
    } as Readonly<typeof clr & StyleSettingsInterface>;
  }

  LightenDarkenColor(coll: string, amt: number) {
    let col = coll;
    let usePound = false;

    if (col.startsWith('#')) {
      col = col.slice(1);
      usePound = true;
    }

    const num = parseInt(col, 16);

    let r = (num >> 16) + amt;

    if (r > 255) {
      r = 255;
    } else if (r < 0) {
      r = 0;
    }

    let b = ((num >> 8) & 0x00ff) + amt;

    if (b > 255) {
      b = 255;
    } else if (b < 0) {
      b = 0;
    }

    let g = (num & 0x0000ff) + amt;

    if (g > 255) {
      g = 255;
    } else if (g < 0) {
      g = 0;
    }

    return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
  }

  getStyle() {
    return this.clr;
  }

  public constructor() {
    // console.trace();
    // console.log('constructing settings');

    // adding favicon
    // if (document.head != null && this.webAppStyle.favicon !== '') {
    // const favlink = document.createElement('link');
    // favlink.href = this.webAppStyle.favicon;
    // favlink.rel = 'shortcut icon';
    // document.head.appendChild(favlink);
    // }

    // create local clr
    this.clr = {
      ...clr,
      ...this.webAppStyle,
    };

    // take care of the fonts
    const link = document.createElement('link');
    link.href = this.webAppStyle.font_url;
    link.rel = 'stylesheet';

    if (document.head != null) {
      document.head.appendChild(link);
      const html = document.getElementsByTagName('html')[0];
      if (html.style != null) {
        html.style.fontFamily = this.webAppStyle.font_family;
      }
    }
  }
}

const decorator = new StyleSettingsDecorator();

export default decorator;
