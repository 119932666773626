import { getType } from 'typesafe-actions';
import cloneDeep from 'lodash/cloneDeep';
import dayjs from 'dayjs';
import { onSelectBefore, onSelectAfter, onSelectFilter } from '../actions/transaction-filtering-actions';
import {
  pageRequest,
  nextTransactionPage,
  previousTransactionPage,
  resetFilters,
} from '../actions/transaction-actions';

type TransactionReducerAction =
  | ReturnType<typeof onSelectBefore>
  | ReturnType<typeof onSelectAfter>
  | ReturnType<typeof onSelectFilter>
  | ReturnType<typeof nextTransactionPage>
  | ReturnType<typeof previousTransactionPage>
  | ReturnType<typeof pageRequest>
  | ReturnType<typeof resetFilters>;

export type TransactionFilterType = 'ALL_ACTIVITY' | 'MONTHLY' | 'MOST_RECENT';

export type TransactionsFiltering = {
  page: number;
  pageSize: number;
  before: string;
  after: string;
  beforeFilter: string;
  afterFilter: string;
  currentFilter: TransactionFilterType | null;
};

const initialTransactionFilteringState: TransactionsFiltering = {
  after: '',
  before: '',
  beforeFilter: '',
  afterFilter: '',
  page: 1,
  pageSize: 10,
  currentFilter: 'MOST_RECENT',
};

export const transactionFilteringReducer = (
  state: TransactionsFiltering = initialTransactionFilteringState,
  action: TransactionReducerAction
): TransactionsFiltering => {
  switch (action.type) {
    case getType(onSelectBefore):
      return {
        ...state,
        beforeFilter: action.payload.date
          ? dayjs(new Date(action.payload.date.toString())).add(1, 'days').format('YYYY-MM-DD')
          : '',
        before: action.payload.date ? action.payload.date.format() : '',
        page: 1,
      };
    case getType(onSelectAfter):
      return {
        ...state,
        afterFilter: action.payload.date ? action.payload.date.format('YYYY-MM-DD') : '',
        after: action.payload.date ? action.payload.date.format() : '',
        page: 1,
      };
    case getType(resetFilters):
      return {
        ...state,
        beforeFilter: '',
        afterFilter: '',
        before: '',
        after: '',
      };
    // return { ...state, after: action.payload.date ? action.payload.date.format('YYYY-MM-DD') : ''};
    case getType(onSelectFilter): {
      const clonedState = cloneDeep(state);

      if (clonedState.currentFilter !== action.payload.filter) {
        clonedState.before = '';
        clonedState.after = '';
        clonedState.page = 1;
      }

      clonedState.currentFilter = action.payload.filter;
      return clonedState;
    }
    case getType(pageRequest): {
      const clonedState = cloneDeep(state);
      clonedState.page = action.payload.page;
      return clonedState;
    }
    case getType(nextTransactionPage): {
      const clonedState = cloneDeep(state);
      clonedState.page++;
      return clonedState;
    }
    case getType(previousTransactionPage): {
      const clonedState = cloneDeep(state);
      clonedState.page--;
      if (clonedState.page < 1) {
        clonedState.page = 1;
      }
      return clonedState;
    }
    default:
      return state;
  }
};

export default transactionFilteringReducer;
