import { useEffect, FC } from 'react';
import * as QueryString from 'query-string';
import isEmpty from 'lodash/isEmpty';
import { PanelInfo } from '../survey-tool/declarations/ah-api';
import { UserProfile } from '../survey-tool/declarations/auth-api';
import { getPureprofileGatewayUrl } from './app-config';
import { browserStorageGet, browserStorageSet } from './storage';
import { isOnboardingPanel } from '../onboarding/onboarding-constants';
import { storeOnboardingData } from '../onboarding/onboarding-data';
import { ensureProtocol, getQueryObj } from './uri';
import { DomainInfo, getDomainInfo } from '../store/api/auth-api';
import { logError } from './log';

function isPureprofilePanel() {
  return window.location.origin.endsWith('my.pureprofile.com');
}

function getGatewayUrl() {
  return isPureprofilePanel() ? getPureprofileGatewayUrl() : window.location.origin;
}

function isGatewayUrl(str: string) {
  return str === getGatewayUrl();
}

function isPureprofileGatewayUrl(path: string) {
  return !!(path && path.includes('account.pureprofile.com'));
}

async function getInfo() {
  let domainInfo;
  try {
    domainInfo = browserStorageGet<DomainInfo, null>('domainInfo', null);
    if (!domainInfo) {
      domainInfo = await getDomainInfo(window.location.hostname);
      if (domainInfo && !isEmpty(domainInfo)) {
        browserStorageSet('domainInfo', domainInfo, 'local-storage');
      }
    }
  } catch (err: any) {
    logError(err);
  }
  let panelInfo;
  try {
    panelInfo = browserStorageGet<PanelInfo, null>('panelInfo', null);
  } catch (err: any) {
    logError(err);
  }
  return { domainInfo, panelInfo };
}

export const GoToLoginUrl: FC = () => {
  useEffect(() => {
    goToLoginUrl().catch((loginUrlErr) => logError(loginUrlErr));
  }, []);
  return null;
};

export async function goToLoginUrl(): Promise<void> {
  const { domainInfo, panelInfo } = await getInfo();

  let loginPageUrl = domainInfo?.login_page_url || panelInfo?.login_page_url;
  let webAppLogin = domainInfo?.web_app_login;

  if (!loginPageUrl && webAppLogin) {
    loginPageUrl = `${window.location.origin}/login`;
  }

  if (!loginPageUrl) {
    loginPageUrl = `${getGatewayUrl()}/login`;
  }

  if (loginPageUrl === `${window.location.origin}/login`) {
    webAppLogin = true;
  }

  if ((webAppLogin || isPureprofileGatewayUrl(loginPageUrl)) && !loginPageUrl.includes('?')) {
    const query: Record<string, string | undefined> = {};
    // login & go back to this page (or to redirect page in the uri if present)
    query.redirect = getQueryObj().redirect;
    if (!query.redirect && !['/login', '/logout', '/error'].includes(window.location.pathname)) {
      query.redirect = window.location.href;
    }
    // pass the referrer onto the login page so we know where user is coming from
    query.referrer = getQueryObj().referrer || window.document.referrer || undefined;
    loginPageUrl += `?${QueryString.stringify(query)}`;
  }

  let goTo = ensureProtocol(loginPageUrl);
  if (goTo.endsWith('?')) {
    goTo = goTo.slice(0, -1);
  }
  if (window.location.href !== goTo) {
    window.location.href = goTo;
  }
}

export function _goToAfterLogoutUrl(_logoutUrl: string) {
  let logoutUrl = _logoutUrl;
  if (isGatewayUrl(logoutUrl)) {
    if (!logoutUrl.includes('/login')) {
      logoutUrl += '/login';
    }
    const { redirect } = getQueryObj();
    if (redirect) {
      logoutUrl += `?redirect=${encodeURIComponent(redirect)}`;
    }
  }
  window.location.href = ensureProtocol(logoutUrl);
}

export async function afterLogoutRedirect() {
  const { domainInfo, panelInfo } = await getInfo();
  const logoutPageUrl = domainInfo?.logout_page_url || panelInfo?.logout_page_url || getGatewayUrl();
  return _goToAfterLogoutUrl(logoutPageUrl);
}

export function goToAcceptTermsAndConditions(
  panelInfo: PanelInfo | null = browserStorageGet<PanelInfo, null>('panelInfo', null),
  userProfile: UserProfile
) {
  if (window.location.pathname !== '/accept-terms-and-conditions') {
    window.location.href = ensureProtocol(
      `${getGatewayUrl()}/accept-terms-and-conditions?token=${userProfile.ppToken}&lang=${userProfile.languageCode}`
    );
  }
}

export function goToValidateEmail(
  panelInfo: PanelInfo | null = browserStorageGet<PanelInfo, null>('panelInfo', null),
  userProfile: UserProfile
) {
  if (isOnboardingPanel(userProfile.panelKey)) {
    if (window.location.pathname !== '/onboarding') {
      storeOnboardingData({ userProfile });
      window.location.href = '/onboarding';
    }
    return;
  }
  if (window.location.pathname !== '/validate-email') {
    window.location.href = ensureProtocol(
      `${getGatewayUrl()}/validate-email?token=${userProfile.ppToken}&lang=${userProfile.languageCode}`
    );
  }
}
