/* eslint max-lines:0 */

// document.querySelector("style[data-aphrodite]").sheet.cssRules

import { AphroditeCSSProperties } from '../utils/aphrodite';
import { footerHeight, headerHeight, link } from '../styles/base.elements';
import { getIcon, icons } from '../styles/icons';
import { clr, removeSpinner, screenSize, spacing } from '../styles/settings';
import { FONT_WEIGHT } from './enums';
import { PartialSurveyToolStyleObject } from './style-declaration';

export const SCREEN_MAX_WIDTH_767 = '@media only screen and (max-width: 767px)';
export const SURVEY_BOX_CONTAINER_RADIUS = '8px';

const defaultButton: AphroditeCSSProperties = {
  background: clr.focus,
  border: '0',
  borderRadius: '3px',
  color: `${clr.extraLightBase} !important`,
  'font-weight': FONT_WEIGHT.LIGHT,
  'text-align': 'center',
  padding: '10px',
  width: '100%',
  ':hover': {
    cursor: 'pointer',
  },
  ':disabled': {
    cursor: 'not-allowed',
  },
};

const cssReset = (elementName: 'i' | 'input'): AphroditeCSSProperties => {
  const result: AphroditeCSSProperties = {
    boxSizing: 'border-box',
  };
  if (elementName === 'i') {
    result.fontStyle = 'normal';
  }
  if (elementName === 'input') {
    result.fontFamily = 'inherit';
    result.fontWeight = 'inherit';
    result.fontSize = 'inherit';
    result.color = clr.select;
  }
  return result;
};

const _default: PartialSurveyToolStyleObject = {
  marginLeftAuto: {
    marginLeft: 'auto',
  },
  commonPadding: {
    padding: '22px',
  },
  commonPaddingHeight250: {
    padding: 0,
  },
  trackingPixelContainer: {
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: -1,
    maxWidth: '0px',
    maxHeight: '0px',
  },
  // loading
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    width: '100%',
    height: '100%',
    backgroundColor: '#ffffff',
    flex: '1 1 0%',
  },
  // level-1
  surveyContainer: {
    // surveyContainer parent should have display:flex set
    flexGrow: 1,

    position: 'relative',
    boxSizing: 'border-box',

    backgroundRepeat: 'no-repeat',
    backgroundSize: 'auto',
    backgroundPositionX: 'center',
    backgroundPositionY: 'top',

    display: 'flex',
    flexDirection: 'column',
  },
  surveyBackgroundImage: {
    [SCREEN_MAX_WIDTH_767]: {
      display: 'none !important',
    },
  },
  // level-2
  headerRoot: {
    backgroundColor: '#475369',
    color: '#FFFFFF',
    height: `${headerHeight}px`,
    position: 'fixed',
    left: 0,
    right: 0,
    top: 0,
    zIndex: 99,
  },
  surveyBoxWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flex: '1 1 auto',
    [SCREEN_MAX_WIDTH_767]: {
      marginTop: '60px !important',
    },
  },
  surveyBoxWrapperWidget: {
    marginTop: 0,
    padding: '100px 0 50px 0',
  },
  footerRoot: {
    minHeight: `${footerHeight}px`,
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    color: clr.footerText,
    fontSize: '1.1rem',
    width: '100%',
  },
  footerRootLogoTop: {
    position: 'initial',
    minHeight: 'auto',
    paddingTop: 0,
  },
  surveyBoxGenericFooter: {
    width: '100%',
    minHeight: '42px',
    display: 'flex',
    justifyContent: 'center !important' as any,
    [SCREEN_MAX_WIDTH_767]: {
      backgroundColor: 'transparent !important',
    },
  },
  // level-3
  surveyBoxContainer: {
    background: '#fff',
    borderRadius: SURVEY_BOX_CONTAINER_RADIUS,
    color: '#333333',
    display: 'inline-block',
    fontFamily: 'inherit',
    fontStyle: 'normal',
    fontWeight: FONT_WEIGHT.LIGHT,
    marginBottom: 75,
    marginLeft: 0,
    marginRight: 0,
    marginTop: 150,
    maxWidth: '679px',
    width: '100%',
    zIndex: 49,
    [SCREEN_MAX_WIDTH_767]: {
      border: 'none !important',
      boxShadow: 'none !important',
      display: 'flex !important',
      flexDirection: 'column !important',
      height: '100%',
      marginBottom: '16px !important',
      marginTop: '0 !important',
      maxWidth: 'unset !important',
    },
  },
  surveyBoxContainerWidget: {
    boxShadow: 'none',
    maxWidth: '100%',
    marginTop: 0,
    marginBottom: 0,
  },
  // footer
  surveyBoxProgressBar: {
    display: 'flex',
    height: '10px',
  },
  surveyBoxProgressBarDisplayNone: {
    display: 'none',
  },
  footerLogo: {
    imageUrl: 'https://cloudcdn.pureprofile.com/image/upload/q_auto,f_auto/survey-tool-assets/pureprofile.png',
    width: '100%',
    maxWidth: '150px',
  },
  footerLogoContainerLogoTop: {
    top: 0,
    paddingTop: '20px',
    width: '100%',
    position: 'absolute',
  },
  footerTerms: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: clr.footer,
    padding: '0.6rem',
    width: '100%',
    minHeight: 60,
  },
  footerLink: {
    color: '#337ab7',
    textDecoration: 'underline',
    ':hover': {
      color: '#337ab7',
      textDecoration: 'underline',
    },
    ':visited': {
      color: '#337ab7',
      textDecoration: 'underline',
    },
  },
  footerLinkSeparator: {
    marginLeft: '10px',
    marginRight: '10px',
  },
  // general
  cursorPointer: {
    ':hover': {
      cursor: 'pointer',
    },
  },
  cursorProgress: {
    cursor: 'progress',
  },
  fadeIn: {
    opacity: 0,
    transition: '0.5s linear',
  },
  fadeOut: {
    opacity: 1,
    transition: '0.5s linear',
  },
  fadeOutEase: {
    opacity: 0,
    transition: 'all 1s ease',
  },
  hr: {
    color: `${clr.dimBase10} !important`,
    opacity: 0.5,
    margin: '2rem 0.5rem !important',
  },
  button: {
    ...defaultButton,
  },
  disabledButton: {
    opacity: 0.5,
    ':hover': {
      cursor: 'not-allowed',
    },
  },
  link: {
    ...link,
  },
  linkButton: {
    ...defaultButton,
    borderRadius: '0.3rem',
    display: 'block',
    padding: '9px 13px',
    textAlign: 'center',
  },
  upperCase: {
    textTransform: 'uppercase',
  },
  noBoxShadow: {
    boxShadow: 'none',
  },
  surveyBoxHeaderContainer: {
    backgroundColor: '#475369',
    borderTopLeftRadius: SURVEY_BOX_CONTAINER_RADIUS,
    borderTopRightRadius: SURVEY_BOX_CONTAINER_RADIUS,
    color: '#FFFFFF',
    position: 'relative',
    padding: '2.0rem 2.2rem',
    backgroundSize: '100%',
    backgroundRepeat: 'no-repeat',
    [SCREEN_MAX_WIDTH_767]: {
      boxShadow: '#475369', // TODO: theme.shadows[4],
      position: 'sticky !important',
      top: '60px !important',
      zIndex: 98,
    },
    wordBreak: 'break-word',
  },
  surveyBoxHeaderOverlay: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: '#000000',
    opacity: 0.5,
  },
  surveyBoxHeaderText: {
    fontSize: '2.1rem',
    fontWeight: FONT_WEIGHT.LIGHT,
    overflowWrap: 'break-word',
    position: 'relative',
    wordBreak: 'break-word',
    zIndex: 1,
  },
  surveyBoxHeaderSubtext: {
    fontSize: '12px',
    color: '#C9CDD7',
    marginTop: '1.5rem',
    position: 'relative',
    zIndex: 1,
  },
  surveyBoxProgressBarComplete: {
    backgroundColor: '#20D6C9',
  },
  surveyBoxProgressBarIncomplete: {
    backgroundColor: '#39475C',
  },
  surveyBoxHeaderOfferImage: {
    position: 'relative',
    zIndex: 1,
    width: '25%',
  },
  surveyBoxFooterError: {
    textAlign: 'center',
    color: '#FFFFFF',
    backgroundColor: clr.validationError,
  },
  surveyBoxFooterFooter: {
    boxShadow: 'none',
    backgroundColor: '#475369',
  },
  surveyBoxFooterFooterWidget: {
    backgroundColor: '#FFFFFF',
  },
  surveyBoxFooterPercentage: {
    display: 'none',
  },
  surveyControlWrapper: {
    borderRadius: 'inherit',
    flex: 1,
    display: 'flex',
    ':nth-child(1n) > div': {
      width: '100%',
    },
    wordBreak: 'break-word',
  },
  surveyControlWrapperDisabled: {
    border: '2px solid #f22c44',
    pointerEvents: 'none',
    userSelect: 'none',
  },
  headerNav: {
    fontSize: '1.4rem',
    fontWeight: FONT_WEIGHT.LIGHT,
    height: '100%',
    [screenSize.smartphone]: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
    },
    [screenSize.desktopOrTablet]: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },
  headerTitle: {
    fontSize: '1.8rem',
    marginLeft: '1%',
    [screenSize.smartphone]: {
      display: 'none',
    },
  },
  headerBalanceIcon1: {
    fontFamily: 'Pureprofile',
    fontStyle: 'normal',
    'vertical-align': 'middle',
    ':before': {
      content: '"\\e971"',
    },
  },
  headerTopRightMenu: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '1.4rem',
    fontWeight: FONT_WEIGHT.LIGHT,
    height: '100%',
    [screenSize.smartphone]: {
      justifyContent: 'center',
      width: '100%',
    },
    [screenSize.desktopOrTablet]: {
      justifyContent: 'space-between',
    },
  },
  headerTopRightLogo: {
    display: 'none',
    [screenSize.smartphone]: {
      display: 'inline-block',
      height: 'auto',
      maxHeight: 32,
      maxWidth: '100%',
      objectFit: 'scale-down',
      width: '90px',
    },
  },
  headerTopRightAvatar: {
    [screenSize.smartphone]: {
      display: 'none',
    },
  },
  headerTopRightAvatarImage: {
    borderRadius: '5px',
    height: '36px',
    width: 'auto',
    margin: '6px 10px',
  },
  headerTopRightUsername: {
    [screenSize.smartphone]: {
      display: 'none',
    },
  },
  headerTopRightUsernameText: {
    marginLeft: '1rem',
    marginRight: '1.5em',
  },
  headerTopRightSeparator: {
    borderRight: '1px solid #5e6c85',
    height: '100%',
    display: 'block',
    [screenSize.smartphone]: {
      display: 'none',
    },
  },
  headerTopRightBalance: {
    [screenSize.smartphone]: {
      display: 'none',
    },
  },
  headerTopRightClose: {
    display: 'flex',
    [screenSize.smartphone]: {
      position: 'absolute',
      right: 0,
      top: 0,
    },
  },
  headerTopRightLogout: {
    [screenSize.smartphone]: {
      position: 'absolute',
      right: 0,
      display: 'flex',
      top: 10,
    },
  },
  headerBalanceContainer: {
    color: clr.balance,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0 1rem',
  },
  headerBalanceIcon2: {
    ...getIcon(icons.balance16),
    marginRight: '1rem',
  },
  headerBalanceText: {
    marginRight: '1rem',
    letterSpacing: '0.44px',
    [screenSize.smartphone]: {
      display: 'none',
    },
  },
  headerBalancePayout: {
    color: clr.negative,
    fontWeight: FONT_WEIGHT.BOLD,
    lineHeight: 1,
  },
  invitationContainer: {
    color: '#000',
    animationDuration: '1.5s',
    animationName: {
      from: {
        opacity: 0,
      },
      to: {
        opacity: 1,
      },
    } as any,
    [screenSize.desktopOrTablet]: {
      minWidth: '3.58rem',
    },
  },
  invitationHeaderContainer: {
    padding: '1.6rem 2rem',
  },
  invitationHeaderNameContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  invitationHeaderName: {
    fontWeight: FONT_WEIGHT.SEMI_BOLD,
    marginBottom: spacing.space1,
    color: clr.footerText,
    ':hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  },
  invitationHeaderSkip: {
    color: clr.label,
    fontSize: '1.2rem',
    ':hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  },
  invitationHeaderText: {
    fontSize: '1.4rem',
  },
  invitationImageContainer: {
    fontSize: '1.2rem',
    minHeight: 65,
    ':hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  },
  invitationImageContainerDisabled: {
    opacity: 0.5,
    pointerEvents: 'none',
    userSelect: 'none',
  },
  invitationImageLink: {
    position: 'relative',
    minHeight: 20,
  },
  invitationImageImage: {
    height: 'auto',
    maxWidth: '100%',
    width: '100%',
  },
  invitationImageEmptyImage: {
    background: 'linear-gradient(to bottom,#6d8498 0,#576a7b 100%)',
    height: '18rem',
    maxWidth: '100%',
  },
  invitationFooterContainer: {
    color: clr.dimBase4,
    fontSize: '1.2rem',
    fontWeight: FONT_WEIGHT.SEMI_BOLD,
    lineHeight: '1.5rem',
    verticalAlign: 'middle',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    padding: '5px 10px 10px 10px',
  },
  invitationFooterContainerDisabled: {
    opacity: 0.5,
    pointerEvents: 'none',
    userSelect: 'none',
  },
  invitationFooterText: {
    display: 'block',
    lineHeight: '32px',
  },
  invitationFooterDots: {
    fontSize: 20,
    marginRight: 30,
    display: 'inline-block',
    lineHeight: '33px',
  },
  invitationFooterButtonContainer: {
    position: 'relative',
    lineHeight: '28px',
    display: 'inline-block',
    minWidth: 250,
  },
  invitationFooterButtonBackgroundImage: {
    position: 'absolute',
    right: 0,
    zIndex: 0,
  },
  invitationFooterResumeContainer: {
    display: 'flex',
  },
  invitationFooterButtonText: {
    display: 'inline-block',
    lineHeight: '33px',
    zIndex: 1,
    position: 'relative',
    color: clr.extraLightBase,
  },
  invitationFooterButtonTooltip: {
    backgroundColor: 'rgba(34, 34, 34, 0.9)',
    borderRadius: 4,
    fontSize: 12,
  },
  invitationFooterHelpIcon: {
    ...getIcon(icons.help16),
    marginRight: 8,
    fontWeight: FONT_WEIGHT.LIGHT,
  },
  invitationFooterTextWrapper: {
    display: 'inline-flex',
    alignItems: 'center',
    ':not(:first-child)': {
      marginLeft: 8,
    },
  },
  invitationFooterDurationIcon: {
    ...getIcon(icons.duration16),
    marginRight: 8,
  },
  surveyBoxWidgetHeaderInvitation: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  surveyBoxWidgetHeaderInvitationTitle: {
    fontWeight: FONT_WEIGHT.SEMI_BOLD,
  },
  surveyBoxWidgetHeaderInvitationSkip: {
    color: clr.label,
    fontSize: '1.2rem',
    ':hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  },
  surveyBoxWidgetHeaderInvitationText: {
    fontSize: '1.4rem',
    marginBottom: spacing.space3,
  },
  surveyBoxWidgetHeaderQuestionContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
  },
  surveyBoxWidgetHeaderQuestionText: {
    fontSize: '2.5rem',
  },
  surveyPayoutContainer: {
    borderRadius: 4,
    color: clr.extraLightBase,
    fontSize: 12,
    minHeight: 40,
    minWidth: 96,
    padding: '4px 8px',
    position: 'absolute',
    right: 8,
    textAlign: 'center',
    top: 9,
  },
  surveyPayoutContainerTheme2: {
    position: 'relative',
    minHeight: 28,
    height: 28,
    lineHeight: '28px',
    padding: 0,
    top: 0,
    right: 0,
    borderRadius: 0,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  surveyPayoutRange: {
    alignItems: 'center',
    display: 'flex',
    fontSize: '1.4rem',
    fontWeight: FONT_WEIGHT.BOLD,
    justifyContent: 'center',
  },
  surveyNotification: {
    position: 'fixed',
    top: '8rem',
    right: '5rem',
    zIndex: 99,
    width: '22rem',
  },
  surveyNotificationContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'fixed',
    border: `1px solid ${clr.primary}`,
    borderRadius: '.5rem',
    color: clr.primary,
    fontSize: '1.4rem',
    fontWeight: FONT_WEIGHT.NORMAL,
  },
  surveyNotificationBellContainer: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: clr.primary,
    padding: '0.6rem',
  },
  surveyNotificationBellIcon: {
    ...getIcon(icons.notification16),
    color: clr.extraLightBase,
  },
  surveyNotificationTextContainer: {
    background: clr.extraLightBase,
    borderRadius: '0 .5rem .5rem 0',
    flexShrink: 0,
    padding: '9px 7px',
  },
  surveyNotificationFadeIn: {
    animationDuration: '1.5s',
    animationName: {
      '0%': {
        marginRight: '-15%',
        opacity: 0,
      },
      '100%': {
        marginRight: 0,
        opacity: 1,
      },
    } as any,
  },
  surveyNotificationFadeOut: {
    animationDuration: '1.5s',
    animationName: {
      '0%': {
        marginRight: 0,
        opacity: 1,
      },
      '100%': {
        marginRight: '-15%',
        opacity: 0,
      },
    } as any,
  },
  surveyControlInputTextWrapper: {
    marginBottom: spacing.space2,
    position: 'relative',
  },
  surveyControlInputTextInput: {
    ...cssReset('input'),
    borderRadius: '0.3rem',
    boxShadow: '0 1px 1px 0 rgba(0,0,0,0.08)',
    lineHeight: spacing.space8,
    height: spacing.space8,
    padding: '0 10px',
    width: '100%',
    ':focus': {
      outline: 'none',
      boxShadow: '0 1px 3px 0 rgba(0,0,0,.2) inset',
      '::-webkit-input-placeholder': { color: 'transparent' },
      ':-ms-input-placeholder': { color: 'transparent' },
      '::placeholder': { color: 'transparent' },
    },
    '::-ms-clear': {
      display: 'none',
    },
  },
  surveyControlBorderError: {
    border: `0.1rem solid ${clr.validationError}`,
  },
  surveyControlInputTextError: {
    border: `0.1rem solid ${clr.validationError}`,
  },
  surveyControlInputTextOk: {
    border: `0.1rem solid ${clr.answerBorder}`,
  },
  surveyControlInputDeleteIcon: {
    ...getIcon(icons.deleteCircle16),
    color: clr.dropDownArrow,
    cursor: 'pointer',
    fontSize: '1.9rem',
    position: 'absolute',
    top: '13px',
    right: '12px',
  },
  surveyControlInputValidationError: {
    color: clr.validationError,
    fontSize: '1.1rem',
    padding: spacing.space1,
  },
  surveyControlMostLeastMostAnswerActive: {
    background: '#1FD6C9',
    color: '#FFFFFF',
  },
  surveyControlMostLeastLeastAnswerActive: {
    background: '#333333',
    color: '#FFFFFF',
  },
  surveyControlRankingIcon: {
    color: '#4169E1',
  },
  surveyControlRankingSelectedOrder: {
    background: '#1FD6C9',
    color: '#FFF',
  },
  surveyControlRankingSelectedRow: {
    border: '2px solid #1FD6C9',
  },
  surveyControlMultiImageListRowDefault: {
    ':active': {
      border: '1px solid #1AC0B4',
    },
    ':hover': {
      border: '1px solid #1AC0B4',
    },
  },
  surveyControlMultiImageListRowActive: {
    position: 'relative',
    border: '1px solid #1AC0B4',
    ':before': {
      content: '""',
      position: 'absolute',
      top: 0,
      right: 0,
      borderLeft: '55px solid transparent',
      borderTop: '40px solid #1ac0b4',
      zIndex: 98,
    },
    ':after': {
      position: 'absolute',
      top: '4px',
      right: '4px',
      color: '#fff',
      fontFamily: 'Pureprofile',
      fontStyle: 'normal',
      content: '"\\e97e"',
    },
  },
  surveyControlSelectClickBarListRowDefault: {
    background: 'linear-gradient(180deg, #ffffff 50%, #fafafa 50%)',
  },
  surveyControlSelectClickBarListRowActive: {
    background: '#20D6C9',
    color: '#FFFFFF',
  },
  surveyControlSelectDropdownDefault: {
    background: 'linear-gradient(180deg, #ffffff 50%, #fafafa 50%)',
  },
  surveyControlSelectDropdownRowActive: {
    ':hover': {
      background: '#20D6C9',
      color: '#FFFFFF',
    },
  },
  surveyControlFrequencyPeriodSelectPeriodActive: {
    background: '#20D6C9',
    color: '#FFFFFF',
  },
  surveyControlFrequencyPeriodRoot: {
    color: '#1ac0b4',
    height: 32,
    marginTop: 48,
    width: '90%',
  },
  surveyControlFrequencyPeriodProgressBar: {
    background: '#3c475b',
    color: '#d8d8d8',
    height: '20px',
    opacity: 1,
  },
  surveyControlLocationResultsListRow: {
    color: '#4539E5',
    ':hover': {
      background: '#0097cf',
      color: '#FFFFFF',
    },
  },
  surveyControlMultiSelect: {
    color: '#20D6C9',
  },
  surveyControlMultiSelectRow: {
    paddingBottom: spacing.space2,
  },
  surveyControlMultiSelectInputFocus: {
    ':focus': {
      outline: clr.primary,
      border: `2px solid ${clr.primary}`,
      '::-webkit-input-placeholder': { color: 'transparent' },
      ':-ms-input-placeholder': { color: 'transparent' },
      '::placeholder': { color: 'transparent' },
    },
  },
  surveyControlSelectRadio: {
    color: '#20D6C9',
  },
  surveyControlSelectRadioInputFocus: {
    ':focus': {
      boxShadow: '0 1px 3px 0 rgba(0,0,0,.2) inset',
      outline: 'none',
    },
  },
  surveyControlSelectRadioRow: {
    paddingBottom: spacing.space3,
  },
  surveyControlGridHeader: {
    marginBottom: 24,
    padding: '0 32px',
    position: 'relative',
  },
  surveyControlGridHeaderTitle: {
    fontSize: '2rem',
    lineHeight: 1.5,
    marginBottom: 8,
  },
  surveyControlGridHeaderImage: {
    marginBottom: 8,
    maxWidth: 164,
  },
  surveyControlGridHeaderSubtitle: {
    fontSize: '1.25rem',
    lineHeight: 1.5,
    margin: '0 !important',
  },
  surveyControlGridHeaderNav: {
    height: '100%',
    left: 0,
    position: 'absolute',
    top: 0,
    width: '100%',
  },
  surveyControlGridHeaderNavList: {
    alignItems: 'stretch',
    display: 'flex',
    height: '100%',
    justifyContent: 'space-between',
    width: '100%',
  },
  surveyControlGridHeaderNavListItem: {
    alignItems: 'center',
    display: 'flex',
  },
  surveyControlGridHeaderNavListItemLink: {
    display: 'inline-block',
    height: 32,
    width: 32,
  },
  surveyControlSelectClickBarInputFocus: {
    ':focus': {
      outline: clr.primary,
      border: `2px solid ${clr.primary}`,
      '::-webkit-input-placeholder': { color: 'transparent' },
      ':-ms-input-placeholder': { color: 'transparent' },
      '::placeholder': { color: 'transparent' },
    },
  },
  surveyControlSelectDropdownInputFocus: {
    ':focus': {
      outline: clr.primary,
      border: `2px solid ${clr.primary}`,
      '::-webkit-input-placeholder': { color: 'transparent' },
      ':-ms-input-placeholder': { color: 'transparent' },
      '::placeholder': { color: 'transparent' },
    },
  },
  surveyControlDateRoot: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    minHeight: '24.5rem',
    padding: '2.2rem',
  },
  surveyControlDateMonth: {
    [screenSize.desktopOrTablet]: {
      minWidth: '26.1rem',
    },
    [screenSize.smartphone]: {
      minWidth: '30.1rem',
    },
  },
  surveyControlDateSecondary: {
    [screenSize.desktopOrTablet]: {
      minWidth: '11.4rem',
    },
    [screenSize.smartphone]: {
      minWidth: '30.1rem',
    },
  },
  surveyControlFrequencyPeriodLabel: {
    color: 'grey',
    fontSize: '1.4rem',
    fontWeight: FONT_WEIGHT.SEMI_BOLD,
    margin: `0 1.5rem 1.5rem ${spacing.space3}`,
    textTransform: 'uppercase',
  },
  surveyControlPeriodLabel: {
    color: 'grey',
    fontSize: '1.4rem',
    fontWeight: FONT_WEIGHT.SEMI_BOLD,
    margin: `0 1.5rem 1.5rem ${spacing.space3}`,
  },
  surveyControlPeriodLabelPeriodContainer: {
    display: 'flex',
    [screenSize.smartphone]: {
      flexWrap: 'wrap',
    },
  },
  surveyControlPeriodLabelLargeContainer: {
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
  },
  surveyControlPeriodLabelPeriod: {
    background: 'linear-gradient(180deg,#fff 50%,#fafafa 50%)',
    borderRadius: '0.3rem',
    boxShadow: '0 0.1rem 0.4rem 0 rgba(0,0,0,.3)',
    cursor: 'pointer',
    margin: `0 1.5rem 1.5rem ${spacing.space2}`,
    minHeight: '2.4rem',
    padding: '1.1rem 1.2rem',
    textAlign: 'center',
  },
  surveyControlPeriodLabelSmallPeriod: {
    [screenSize.desktopOrTablet]: {
      width: '20%',
    },
    [screenSize.smartphone]: {
      width: '100%',
    },
  },
  surveyControlPeriodLabelLargePeriod: {
    width: '100%',
  },
  surveyControlFrequencySpendContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    [screenSize.smartphone]: {
      alignContent: 'flex-start',
      minHeight: '39.5rem',
    },
  },
  surveyControlFrequencySpendInput: {
    marginBottom: spacing.space3,
    minWidth: '27.8rem',
    padding: '0 1.5rem',
    width: '50%',
  },
  surveyControlFrequencyLabel: {
    color: clr.label,
    fontSize: '1.2rem',
    marginBottom: spacing.space2,
  },
  surveyControlImageMultiListRowIMageContainer: {
    ':after': {
      zIndex: 1001,
    },
    ':before': {
      zIndex: 1001,
    },
    border: `1px solid ${clr.answerBorder}`,
    borderRadius: 3,
    marginBottom: spacing.space2,
    position: 'relative',
  },
  surveyControlImageMultiListRowImageWrapper: {
    height: 192,
    userSelect: 'none',
  },
  surveyControlImageMultiListRowImage: {
    display: 'block',
    height: 192,
    maxWidth: 257,
    margin: 'auto',
    objectFit: 'cover',
    width: 'auto',
  },
  surveyControlImageMultiListRowText: {
    backgroundColor: '#f2f2f2',
    bottom: 0,
    boxSizing: 'border-box',
    color: '#465268',
    fontFamily: '"Open Sans", sans-serif',
    fontSize: 14,
    fontWeight: FONT_WEIGHT.SEMI_BOLD,
    minHeight: 32,
    padding: 10,
    left: 0,
    lineHeight: 2.25,
    position: 'static',
    textAlign: 'center',
    width: '100%',
  },
  surveyControlImageMultiImageBox: {
    boxSizing: 'content-box',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  surveyControlimageMultiGrid: {
    [screenSize.desktopOrTablet]: {
      width: '48%',
    },
    [screenSize.smartphone]: {
      width: '100%',
    },
  },
  surveyControlImageMultiList: {
    width: '100%',
  },
  surveyControlImageSelImageBox: {
    boxSizing: 'content-box',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  surveyControlImageSelGrid: {
    [screenSize.desktopOrTablet]: {
      width: '48%',
    },
    [screenSize.smartphone]: {
      width: '100%',
    },
  },
  surveyControlImageSelList: {
    width: '100%',
  },
  surveyControlImageImage: {
    maxWidth: '100%',
    verticalAlign: 'middle',
  },
  surveyControlInputCheckboxLabel: {
    display: 'flex',
    alignItems: 'flex-start',
    cursor: 'pointer',
    color: clr.select,
    paddingBottom: spacing.space1,
    textAlign: 'left',
    ':nth-child(1n) > svg': {
      marginTop: 5,
      width: '5%',
    },
  },
  surveyControlInputCheckboxInput: {
    display: 'none',
  },
  surveyControlInputCheckboxText: {
    marginLeft: 8,
    textAlign: 'left',
    width: '90%',
  },
  surveyControlInputRadioText: {
    marginLeft: 8,
    textAlign: 'left',
    width: '90%',
  },
  surveyControlInputRadioLabel: {
    display: 'flex',
    alignItems: 'flex-start',
    cursor: 'pointer',
    color: clr.select,
    paddingBottom: spacing.space1,
    ':nth-child(1n) > svg': {
      marginTop: 5,
      width: '5%',
    },
  },
  surveyControlInputRadioInput: {
    display: 'none',
  },
  surveyControlIntelliSenseAnswerDeleteIcon: {
    ...getIcon(icons.deleteCircle16),
    color: clr.dimBase2,
    cursor: 'pointer',
    fontSize: '1.9rem',
    position: 'absolute',
    right: '3px',
    top: '6px',
  },
  surveyControlIntelliSenseAnswerAnswer: {
    border: `1px solid ${clr.dimBase2}`,
    borderRadius: '20px',
    fontSize: '1.4rem',
    marginBottom: spacing.space2,
    marginRight: spacing.space2,
    padding: '0.6rem 0',
    paddingLeft: '2rem',
    paddingRight: '6rem',
    position: 'relative',
  },
  surveyControlIntelliSenseAnswerLastAnswer: {
    backgroundColor: clr.primary,
    borderColor: clr.primary,
    color: clr.extraLightBase,
  },
  surveyControlIntelliSenseAnswerLastWhiteColor: {
    color: clr.extraLightBase,
  },
  surveyControlIntelliSenseInputRoot: {
    width: '100%',
  },
  surveyControlIntelliSenseInputInput: {
    ...cssReset('input'),
    border: `0.1rem solid ${clr.answerBorder}`,
    borderRadius: '0.3rem',
    boxShadow: '0 1px 1px 0 rgba(0,0,0,0.08)',
    lineHeight: spacing.space8,
    height: spacing.space8,
    marginBottom: spacing.space1,
    padding: '0 1rem',
    width: '100%',
    ':focus': {
      outline: 'none',
      '::-webkit-input-placeholder': { color: 'transparent' },
      ':-ms-input-placeholder': { color: 'transparent' },
      '::placeholder': { color: 'transparent' },
    },
    '::-ms-clear': {
      display: 'none',
    },
  },
  surveyControlIntelliSenseInputLabel: {
    color: clr.base,
    fontSize: '12px',
    marginBottom: spacing.space5,
    paddingLeft: spacing.space1,
  },
  surveyControlIntelliSenseInputDeleteIcon: {
    ...getIcon(icons.deleteCircle16),
    color: clr.dropDownArrow,
    cursor: 'pointer',
    fontSize: '1.9rem',
    position: 'absolute',
    top: '13px',
    right: '12px',
  },
  surveyControlIntelliSenseLookupResultResults: {
    background: '#FFFFFF',
    border: `0.1rem solid ${clr.answerBorder}`,
    borderRadius: '0.3rem',
    boxShadow: '0 1px 1px 0 rgba(0,0,0,0.08)',
    fontSize: '1.2rem',
    position: 'absolute',
    left: '0',
    top: '43px',
    width: '100%',
  },
  surveyControlIntelliSenseLookupResultLocationBox: {
    borderRadius: '3px',
    cursor: 'pointer',
    padding: '0 1rem',
    ':hover': {
      background: '#0097cf',
      color: '#FFFFFF',
    },
  },
  surveyControlIntelliSenseLookupResultLocation: {
    fontSize: '1.4rem',
    lineHeight: '4.5rem',
  },
  surveyControlIntelliSenseMultiRoot: {
    [screenSize.smartphone]: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
  },
  surveyControlIntelliSenseMultiContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    position: 'relative',
  },
  surveyControlIntelliSenseRoot: {
    [screenSize.smartphone]: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
  },
  surveyControlIntelliSenseLocationRoot: {
    position: 'relative',
  },
  surveyControlLocationRoot: {
    position: 'relative',
  },
  surveyControlLocationInput: {
    ...cssReset('input'),
    '::-webkit-input-placeholder': { color: '#d8d8d8' },
    ':-ms-input-placeholder': { color: '#d8d8d8' },
    '::placeholder': { color: '#d8d8d8' },
    border: `0.1rem solid ${clr.answerBorder}`,
    borderRadius: '0.3rem',
    boxShadow: '0 1px 1px 0 rgba(0,0,0,0.08)',
    lineHeight: spacing.space8,
    height: spacing.space8,
    marginBottom: spacing.space5,
    padding: '10px',
    width: '100%',
    opacity: 1,
    ':focus': {
      outline: 'none',
      boxShadow: '0 1px 3px 0 rgba(0,0,0,.2) inset',
      '::-webkit-input-placeholder': { color: 'transparent' },
      ':-ms-input-placeholder': { color: 'transparent' },
      '::placeholder': { color: 'transparent' },
    },
    '::-ms-clear': {
      display: 'none',
    },
  },
  surveyControlLocationDeleteIcon: {
    ...getIcon(icons.deleteCircle16),
    color: clr.dropDownArrow,
    cursor: 'pointer',
    fontSize: '1.9rem',
    position: 'absolute',
    top: '13px',
    right: '12px',
  },
  surveyControlLocationLookupResultResults: {
    fontSize: '1.2rem',
  },
  surveyControlLocationLookupResultResultsTitle: {
    color: clr.label,
    marginBottom: spacing.space5,
  },
  surveyControlLocationLookupResultLocationBox: {
    cursor: 'pointer',
  },
  surveyControlLocationLookupResultLocation: {
    fontSize: '1.4rem',
    lineHeight: '4.5rem',
  },
  surveyControlMostLeastListRowRoot: {
    border: '#ececec 1px solid',
    borderRadius: '0.3rem',
    color: '#626262',
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
    marginBottom: spacing.space2,
    minHeight: '8.6rem',
    overflow: 'hidden',
    transition: 'all .2s linear',
  },
  surveyControlMostLeastListRowActiveAnswer: {
    [screenSize.smartphone]: {
      marginRight: '-37px',
    },
  },
  surveyControlMostLeastListRowHideAnswers: {
    [screenSize.smartphone]: {
      marginRight: '-162px',
    },
  },
  surveyControlMostLeastListRowShowAnswers: {
    [screenSize.smartphone]: {
      marginRight: '0px',
      marginLeft: '-120px',
    },
  },
  surveyControlMostLeastListRowBox: {
    background: '#F4F4F4',
    cursor: 'pointer',
    fontSize: '1rem',
    padding: '2rem 1rem',
    transition: 'all .2s linear',
    textAlign: 'center',
    minWidth: '7rem',
    width: '7rem',
  },
  surveyControlMostLeastListRowCentralBox: {
    borderRight: '#ececec 1px solid',
    borderLeft: '#ececec 1px solid',
    cursor: 'default',
    padding: '2rem',
    width: '100%',
    [screenSize.smartphone]: {
      order: -2,
    },
  },
  surveyControlMostLeastListRowMostIcon: {
    ...getIcon(icons.most),
    display: 'block',
    fontSize: '1.8rem',
    margin: 0,
  },
  surveyControlMostLeastListRowLeastIcon: {
    ...getIcon(icons.least),
    display: 'block',
    fontSize: '1.8rem',
    margin: 0,
  },
  surveyControlMostLeastListRowAlignIconLeft: {
    [screenSize.smartphone]: {
      minWidth: '3.6rem',
      width: '3.6rem',
    },
  },
  surveyControlMostLeastListRowHideAnswerText: {
    [screenSize.smartphone]: {
      display: 'none',
    },
  },
  surveyControlMostLeastListRowLeastAnswerSelectedSmartphone: {
    [screenSize.smartphone]: {
      order: -1,
    },
  },
  surveyControlMostLeastListRowLeastAnswerMarkedSmartphone: {
    [screenSize.smartphone]: {
      order: 1,
    },
  },
  surveyControlMostLeastAnswersContainer: {
    overflow: 'hidden',
  },
  surveyControlMultiSliderRoot: {
    marginBottom: '-2.5rem',
  },
  surveyControlMultiSliderLimitContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '0 2rem',
    transform: 'translateY(3.5rem)',
    userSelect: 'none',
    [screenSize.smartphone]: {
      fontSize: '1rem',
      margin: '1.5rem 1rem',
    },
  },
  surveyControlBottomValidationError: {
    color: clr.negative,
    background: clr.bottomValidationError,
    borderRadius: '5px',
    fontSize: '1.1rem',
    padding: '12px',
    width: '100%',
  },
  surveyControlNPSScaleContainer: {
    paddingTop: spacing.space4,
    textAlign: 'center',
  },
  surveyControlNPSRow: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: spacing.space3,
    boxShadow: '0 2px 4px rgba(0,0,0,.5)',
    borderRadius: '3px',
  },
  surveyControlNPSLabels: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  surveyControlNPSLabel: {
    color: 'grey',
    fontSize: '1.2rem',
    letterSpacing: '2px',
  },
  surveyControlNPSNumericRoot: {
    border: '1px solid #E7E7E7',
    height: '4.4rem',
    padding: '10px',
    width: '9.09%',
  },
  surveyControlNPSNumericActive: {
    background: clr.primary,
    border: 'none',
    color: clr.extraLightBase,
  },
  surveyControlPayoutNewsPayout: {
    position: 'absolute',
    top: '-1.4rem',
    right: '1rem',
    background: 'url(https://cloudcdn.pureprofile.com/image/upload/q_auto,f_auto/news-connect-app/points-sm-2.png)',
    backgroundSize: '6rem',
    backgroundRepeat: 'no-repeat',
    borderRadius: '3px',
    color: clr.extraLightBase,
    fontSize: '1rem',
    minHeight: '66px',
    minWidth: '60px',
    padding: '1rem 0',
    textAlign: 'center',
  },
  surveyControlPayoutNewsPayoutRange: {
    fontSize: '1.4rem',
    fontWeight: FONT_WEIGHT.BOLD,
  },
  surveyControlRankingListItemRow: {
    display: 'flex',
    flexGrow: 1,
  },
  surveyControlRankingListItemOrder: {
    backgroundColor: '#e6e6e8',
    borderRadius: '0.5rem',
    fontWeight: FONT_WEIGHT.SEMI_BOLD,
    height: '4rem',
    lineHeight: '3.6rem',
    marginRight: '2%',
    textAlign: 'center',
    minWidth: '4rem',
  },
  surveyControlRankingListItemOption: {
    background: 'linear-gradient(180deg,#fff 50%,#fafafa 50%)',
    border: '0.2rem solid transparent',
    borderRadius: '0.5rem',
    boxShadow: '0 1px 4px 0 rgba(0,0,0,.3)',
    display: 'flex',
    justifyContent: 'space-between',
    flexGrow: 1,
    minHeight: '4rem',
    lineHeight: '3.6rem',
    paddingLeft: spacing.space2,
    wordBreak: 'break-word',
  },
  surveyControlRankingListItemIcon: {
    ...getIcon(icons.reorder16),
    fontSize: '1.6rem',
    minWidth: '2rem',
  },
  surveyControlRankingListRow: {
    alignItems: 'center',
    minHeight: '4rem',
    marginBottom: spacing.space2,
  },
  surveyControlRankingContainer: {
    fontSize: '1.2rem',
  },
  surveyControlRankingPlaceHolder: {
    backgroundColor: '#F8F8F8',
    border: '1px solid #CCC',
    color: '#CCC',
    ':nth-child(1n) > *': {
      display: 'none',
    },
  },
  surveyControlRankingItemClass: {
    display: 'flex',
  },
  surveyControlRankingDragged: {
    opacity: 0.7,
    transform: 'scale(0.98, 0.95)',
  },
  surveyControlCustomScaleBox: {
    [screenSize.desktopOrTablet]: {
      minWidth: '1rem',
    },
    [screenSize.smartphone]: {
      padding: '0 0.6rem',
    },
  },
  surveyControlCustomScaleCustomIcon: {
    color: 'black',
    cursor: 'pointer',
    display: 'block',
    width: '44px',
    lineHeight: '44px',
    borderRadius: 4,
    border: '1px solid #eee',
    fontSize: '1.6rem',
    marginBottom: 4,
  },
  surveyControlCustomScaleIconActive: {
    background: '#20D6C9',
    color: '#FFFFFF',
  },
  surveyControlCustomScaleLabel: {
    color: clr.star,
    fontSize: '1rem',
    fontWeight: FONT_WEIGHT.NORMAL,
  },
  surveyControlLikeDislikeBox: {
    [screenSize.desktopOrTablet]: {
      minWidth: '11rem',
    },
    [screenSize.smartphone]: {
      padding: '0 0.6rem',
    },
  },
  surveyControlLikeDislikeLikeIcon: {
    ...getIcon(icons.like),
    color: clr.lightBase,
    cursor: 'pointer',
    display: 'block',
    fontSize: '9.0rem',
    height: '13rem',
    marginBottom: spacing.space2,
  },
  surveyControlLikeDislikeLeftPadding: {
    paddingLeft: '12rem',
  },
  surveyControlLikeDislikeRightPadding: {
    paddingRight: '12rem',
  },
  surveyControlLikeDislikeDislike: {
    paddingBottom: '3.7rem',
    transform: 'rotate(180deg)',
  },
  surveyControlLikeDislikeIconActive: {
    color: clr.primary,
  },
  surveyControlLikeDislikeLabel: {
    color: clr.star,
    fontSize: '1rem',
    fontWeight: FONT_WEIGHT.NORMAL,
  },
  surveyControlLikertBox: {
    [screenSize.desktopOrTablet]: {
      minWidth: '1rem',
    },
    [screenSize.smartphone]: {
      padding: '0 0.6rem',
    },
  },
  surveyControlLikertNumber: {
    color: clr.lightBase,
    cursor: 'pointer',
    display: 'block',
    fontSize: '3.2rem',
    marginBottom: spacing.space2,
  },
  surveyControlLikertIconActive: {
    color: clr.primary,
  },
  surveyControlLikertLabel: {
    color: clr.star,
    fontSize: '1rem',
    fontWeight: FONT_WEIGHT.NORMAL,
  },
  surveyControlScaleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    paddingTop: spacing.space4,
    textAlign: 'center',
    [SCREEN_MAX_WIDTH_767]: {
      flexDirection: 'column',
    },
  },
  surveyControlScaleSmiley: {
    [screenSize.desktopOrTablet]: {
      minWidth: '11rem',
    },
    [screenSize.smartphone]: {
      padding: '0 0.6rem',
    },
  },
  surveyControlScaleSmileyIcon: {
    ...getIcon(icons.satisfaction),
    color: clr.lightBase,
    cursor: 'pointer',
    display: 'block',
    fontSize: '5.0rem',
    marginBottom: spacing.space2,
  },
  surveyControlScaleSmiley2: {
    ...getIcon(icons.satisfaction2),
  },
  surveyControlScaleSmiley3: {
    ...getIcon(icons.satisfaction3),
  },
  surveyControlScaleSmileyIconActive: {
    color: clr.primary,
  },
  surveyControlScaleSmileyLabel: {
    color: clr.star,
    fontSize: '1rem',
    fontWeight: 400,
  },
  surveyControlScaleStar: {},
  surveyControlScaleStarIcon: {
    ...getIcon(icons.star16),
    color: clr.lightBase,
    cursor: 'pointer',
    display: 'block',
    fontSize: '3.2rem',
    marginBottom: spacing.space2,
    [screenSize.smartphone]: {
      fontSize: '2.3rem',
    },
  },
  surveyControlScaleStarIconActive: {
    color: clr.starActive,
  },
  surveyControlScaleStarLabel: {
    color: clr.star,
    fontSize: '1rem',
    fontWeight: FONT_WEIGHT.NORMAL,
  },
  surveyControlOtherInputRoot: {
    position: 'relative',
    marginBottom: spacing.space2,
  },
  surveyControlOtherInputClickInput: {
    ...cssReset('input'),
    appearance: 'none',
    boxShadow: '0 0.1rem 0.4rem 0 rgba(0, 0, 0, 0.3)',
    border: '2px solid transparent',
    borderRadius: '3px',
    cursor: 'pointer',
    display: 'inline-block',
    margin: '0',
    padding: '10px',
    textAlign: 'left',
    width: '100%',
    '::-webkit-input-placeholder': { color: '#d8d8d8' },
    ':-ms-input-placeholder': { color: '#d8d8d8' },
    '::placeholder': { color: '#d8d8d8' },
    '::-ms-clear': {
      display: 'none',
    },
  },
  surveyControlOtherInputActive: {
    background: clr.primary,
    opacity: 1,
  },
  surveyControlOtherInputDeleteIcon: {
    ...getIcon(icons.deleteCircle16),
    color: clr.dropDownArrow,
    cursor: 'pointer',
    fontSize: '16px',
    position: 'absolute',
    top: 'calc(50% - 8px)',
    right: 12,
  },
  surveyControlSelectClickBarListRowClickInput: {
    ':not(:last-child)': {
      marginBottom: spacing.space2,
    },
    ':nth-child(1n) > img': {
      marginTop: 5,
    },
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    boxShadow: '0 0.1rem 0.4rem 0 rgba(0, 0, 0, 0.3)',
    borderRadius: '3px',
    color: clr.select,
    cursor: 'pointer',
    padding: '1.1rem 1.2rem',
    textAlign: 'left',
    transition: '0.2s background',
  },
  surveyControlSelectClickBarListRowRadio: {
    height: '1.6rem',
    width: '1.6rem',
  },
  surveyControlSelectClickBarListRowInput: {
    display: 'none',
  },
  surveyControlSliderPadding: {
    background: clr.negative,
    padding: '22px',
  },
  surveyControlStepperContainer: {
    display: 'flex',
    justifyContent: 'center',
    lineHeight: '20rem',
    position: 'relative',
  },
  surveyControlStepperLeft: {
    cursor: 'pointer',
    fontSize: '4.5rem',
    height: '20rem',
    userSelect: 'none',
    width: '10rem',
    borderRadius: '200px 0 0 200px',
    color: clr.extraLightBase,
    paddingLeft: '2rem',
  },
  surveyControlStepperRight: {
    cursor: 'pointer',
    fontSize: '4.5rem',
    height: '20rem',
    userSelect: 'none',
    width: '10rem',
    background: clr.primary,
    borderRadius: '0 200px 200px 0',
    color: clr.extraLightBase,
    paddingRight: '1rem',
    textAlign: 'right',
  },
  surveyControlStepperInnerCircle: {
    background: clr.extraLightBase,
    borderRadius: '100%',
    fontSize: '3rem',
    height: '100px',
    lineHeight: '100px',
    position: 'absolute',
    textAlign: 'center',
    top: '9.5rem',
    width: '10rem',
    zIndex: 1,
  },
  surveyControlStepperDisabled: {
    background: '#d2d2d2',
    cursor: 'not-allowed',
  },
  surveyControlStepperEnabled: {
    background: clr.primary,
    ':hover': {
      background: clr.primaryDark,
    },
  },
  surveyControlStepperPadding: {
    background: clr.negative,
    padding: '44px',
  },
  surveyControlTextAreaRoot: {
    display: 'flex',
    position: 'relative',
  },
  surveyControlTextAreaInput: {
    '::-webkit-input-placeholder': { color: '#d8d8d8' },
    ':-ms-input-placeholder': { color: '#d8d8d8' },
    '::placeholder': { color: '#d8d8d8' },
    border: '1px solid #ccc',
    borderRadius: '0.3rem',
    boxShadow: '0 1px 1px 0 rgba(0,0,0,0.08)',
    height: spacing.space14,
    lineHeight: spacing.space4,
    fontFamily: 'inherit',
    padding: '1rem',
    width: '100%',
    ':focus': {
      outline: 'none',
      boxShadow: '0 1px 3px 0 rgba(0,0,0,.2) inset',
      '::-webkit-input-placeholder': { color: 'transparent' },
      ':-ms-input-placeholder': { color: 'transparent' },
      '::placeholder': { color: 'transparent' },
    },
  },
  surveyControlTextFieldRoot: {
    minHeight: '245px',
  },
  surveyControlTextImageWrapper: {
    margin: '10px 0',
  },
  surveyControlTextImage: {
    display: 'inline',
    maxWidth: '100%',
    height: 'auto',
  },
  surveyControlTextPaddingTop: {
    paddingTop: '44px',
  },
  surveyControlThankyouCommentTextField: {
    fontSize: '1.2rem',
    textAlign: 'left',
  },
  surveyControlThankyouLabel: {
    marginBottom: spacing.space2,
  },
  surveyControlThankyouComment: {
    ...cssReset('input'),
    border: '1px solid #ceced0',
    padding: '10px',
    borderRadius: '3px',
    width: '100%',
    ':focus': {
      outline: 'none',
    },
  },
  surveyControlThankyouPayoutRoot: {
    border: `1px solid ${clr.primaryDark}`,
    borderRadius: '5px',
    fontSize: '1.2rem',
    fontWeight: FONT_WEIGHT.SEMI_BOLD,
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    margin: '20px auto',
    maxWidth: '443px',
    textAlign: 'center',
  },
  surveyControlThankyouPayoutBox: {
    color: clr.dark,
    flexGrow: 1,
    minHeight: '11.5rem',
    padding: '25px 10px 15px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  surveyControlThankyouPayoutLabel: {
    marginBottom: spacing.space1,
  },
  surveyControlThankyouPayoutValue: {
    fontSize: 28,
    fontWeight: FONT_WEIGHT.SEMI_BOLD,
  },
  surveyControlThankyouPayoutBalance: {
    background: clr.extraLightBase,
    borderLeft: `1px solid ${clr.primaryDark}`,
  },
  surveyControlVideoRoot: {
    maxWidth: '100%',
    verticalAlign: 'middle',
  },
  surveyControlDropdownRoot: {
    marginBottom: spacing.space1,
    verticalAlign: 'middle',
  },
  surveyControlDropdownDropdown: {
    boxShadow: `0 0.1rem 0.2rem ${clr.dropDownBorder}`,
    borderRadius: '3px',
    cursor: 'pointer',
    textAlign: 'left',
    display: 'flex',
    overflow: 'hidden',
    position: 'relative',
    width: '100%',
    justifyContent: 'space-between',
    height: '50px',
    lineHeight: '50px',
    padding: '0 1.2rem',
    border: `1px solid ${clr.dropDownBorder}`,
  },
  surveyControlDropdownOuterList: {
    position: 'relative',
    width: '100%',
    zIndex: 1,
  },
  surveyControlDropdownInnerList: {
    // PPD-3527 - position needs to be fixed, otherwise it won't break out of parent element to overlay the footer
    position: 'fixed',
    overflow: 'auto',
    maxHeight: '300px',
    background: '#FFF',
  },
  surveyControlDropdownLi: {
    alignItems: 'center',
    border: `1px solid ${clr.dropDownBorder}`,
    borderTop: 'none',
    display: 'flex',
    lineHeight: 1.5,
    minHeight: 50,
    padding: '6px 12px',
    textAlign: 'left',
  },
  surveyControlDropdownShow: {
    display: 'block',
  },
  surveyControlDropdownHide: {
    display: 'none',
  },
  surveyControlDropdownArrowIcon: {
    ...getIcon(icons.dropdownArrow16),
    fontSize: '1.3rem',
    color: clr.deleteIcon,
  },
  surveyControlInputNumberSpendHolder: {
    borderRadius: '0.3rem',
    boxShadow: 'inset 0px 1px 4px 0px rgba(0, 0, 0, 0.3)',
    display: 'flex',
    alignItems: 'center',
    height: '5rem',
    padding: '0.3rem 1rem',
    textAlign: 'left',
  },
  surveyControlInputNumberCurrency: {
    color: clr.label,
    lineHeight: '42px',
    marginRight: 8,
  },
  surveyControlInputNumberInputText: {
    ...cssReset('input'),
    border: 'none',
    flex: 1,
    height: '100%',
    padding: 0,
    ':focus': {
      outline: 'none',
      '::-webkit-input-placeholder': { color: 'transparent' },
      ':-ms-input-placeholder': { color: 'transparent' },
      '::placeholder': { color: 'transparent' },
    },
    '::-webkit-input-placeholder': { color: '#d8d8d8' },
    ':-ms-input-placeholder': { color: '#d8d8d8' },
    '::placeholder': { color: '#d8d8d8' },
    width: 'calc(100% - 52px)',
    ...removeSpinner,
  },
  surveyControlInputNumberError: {
    border: `0.1rem solid ${clr.validationError}`,
  },
  surveyControlInputNumberOk: {
    border: `0.1rem solid ${clr.answerBorder}`,
  },
  thankYouRoot: {
    color: '#626262',
    textAlign: 'center',
    minHeight: '49.8rem',
    borderTopLeftRadius: SURVEY_BOX_CONTAINER_RADIUS,
    borderTopRightRadius: SURVEY_BOX_CONTAINER_RADIUS,
    background: clr.negative,
  },
  surveyControlInputNumberValidationError: {
    color: clr.validationError,
    fontSize: '1.1rem',
    marginTop: spacing.space1,
  },
  thankYouTitle: {
    fontSize: '2.8rem',
    fontWeight: FONT_WEIGHT.LIGHT,
    marginBottom: spacing.space1,
  },
  thankYouRatingWrapper: {
    margin: '0',
  },
  thankYouRatingContainer: {
    marginBottom: spacing.space3,
  },
  thankYouRatingText: {
    fontSize: '2.2rem',
    marginBottom: spacing.space3,
  },
  thankYouRatingStars: {
    display: 'flex',
    justifyContent: 'center',
  },
  thankYouMessage: {
    fontWeight: FONT_WEIGHT.SEMI_BOLD,
    marginBottom: spacing.space6,
  },
  thankYouImageWrapper: {},
  thankYouImage: {
    maxWidth: '100%',
  },
  thankYouStarIcon: {
    ...getIcon(icons.offers32),
    color: '#909090',
    cursor: 'pointer',
    display: 'block',
    fontSize: '3.5rem',
    marginBottom: spacing.space2,
  },
  thankYouStarIconActive: {
    color: clr.starActive,
    ...getIcon(icons.star16),
  },
  thankYouStarIconLabel: {
    color: clr.star,
    fontSize: '1rem',
    fontWeight: FONT_WEIGHT.NORMAL,
  },
  thankYouCloseCampaign: {
    marginBottom: spacing.space2,
    textAlign: 'right',
    ':hover': {
      cursor: 'pointer',
    },
  },
  thankYouCloseCampaignIcon: {
    ...getIcon(icons.close24),
  },
  thankYouDisqualifiedMessageContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  insightHeaderText: {
    margin: 0,
    padding: 0,
  },
  insightHeaderLeadText: {
    margin: 0,
    padding: 0,
  },
  barInsight: {
    margin: '10px',
  },
  barInsightRow: {
    display: 'flex',
    boxSizing: 'border-box',
    height: '36px',
    margin: '2px 0',
    padding: '3px 5px 3px 3px',
  },
  barInsightRowSelected: {
    padding: '1px 3px 1px 1px',
    border: '2px solid #000',
    borderRadius: '3px',
  },
  barInsightRowLeft: {
    display: 'block',
    borderRadius: '3px',
    backgroundColor: '#AAA',
  },
  barInsightRowLeftColoured: {
    height: '100%',
    borderRadius: '3px 0 0 3px',
  },
  barInsightRowLeftGrey: {
    display: 'flex',
    alignItems: 'center',
    transform: 'translateY(-100%)',
    height: '100%',
    ':nth-child(1n) > div': {
      fontSize: '13px',
    },
  },
  barInsightRowLeftLabel: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textAlign: 'left',
    color: '#FFF',
    padding: '0 8px',
  },
  barInsightRowRight: {
    ':nth-child(1n) > div': {
      fontSize: '13px',
    },
  },
  barInsightRowRightLabel: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: '100%',
  },
  barInsightCount: {
    paddingLeft: '5px',
    textAlign: 'left',
  },
};

export default _default;
