import omit from 'lodash/omit';
import { parseUrl, stringifyUrl } from 'query-string';
import { logError } from './log';

// https://support.google.com/analytics/answer/10271001
window.dataLayer ??= [];
window.gtag = function gtag() {
  // eslint-disable-next-line prefer-rest-params
  window.dataLayer.push(arguments);
} as Gtag.Gtag;
window.gtag('js', new Date());

function appendScript(url: string, onload?: () => void | Promise<void>) {
  const script = document.createElement('script');
  if (onload) {
    script.onload = onload;
  }
  script.src = url;
  document.body.appendChild(script);
}

function sanitizePageView(url: string) {
  if (!url) {
    return url;
  }
  const parsed = parseUrl(url);
  const url2 = stringifyUrl({ url: parsed.url, query: omit(parsed.query, ['token', 'refresh']) });
  return url2;
}

const addedGtagTagIds: string[] = [];

export function addGtagTagId(tagId: string) {
  if (!tagId.startsWith('G-') && !tagId.startsWith('UA-') && !tagId.startsWith('AW-') && !tagId.startsWith('DC-')) {
    logError(`Invalid tagId supplied to addGtagTagId: ${tagId}`);
    return;
  }
  if (addedGtagTagIds.includes(tagId)) {
    // no-op
    return;
  }
  if (addedGtagTagIds.length === 0) {
    // only for first tag
    appendScript(`https://www.googletagmanager.com/gtag/js?id=${tagId}`);
  }
  // first, second, third, etc...
  gtag('config', tagId, {
    send_page_view: false,
  });
  addedGtagTagIds.push(tagId);
  // log current page view after tagId is added to addedGtagTagIds
  if (window.location.pathname !== '/') {
    // we don't want to send root page views, since root redirects to /feed and then our page view count doubles
    const page_path = sanitizePageView(window.location.pathname + window.location.search);
    gtag('event', 'page_view', { page_path, send_to: tagId });
  }
}

export function gtagSetUserId(userId: string) {
  for (const tagId of addedGtagTagIds) {
    gtag('config', tagId, {
      user_id: userId,
    });
  }
}

export function gtagPageView(page_path: string) {
  if (!page_path) {
    return;
  }
  for (const tagId of addedGtagTagIds) {
    gtag('event', 'page_view', { page_path: sanitizePageView(page_path), send_to: tagId });
  }
}

export function gtagEvent({
  eventName,
  eventParams,
}: {
  eventName: Gtag.EventNames | string;
  eventParams?: Gtag.ControlParams | Gtag.EventParams | Gtag.CustomParams;
}) {
  for (const tagId of addedGtagTagIds) {
    gtag('event', eventName, { ...eventParams, send_to: tagId });
  }
}
