import Axios, { AxiosError, AxiosRequestConfig, AxiosResponse, Method } from 'axios';
import extend from 'lodash/extend';
import omitBy from 'lodash/omitBy';
import pick from 'lodash/pick';
import { parseUrl } from 'query-string';
import { clearToken, retrieveToken } from '../../auth-token';
import { browserNavigate } from '../../utils/browser-navigate';
import { logError, submitData } from '../../utils/log';
import { goToLoginUrl } from '../../utils/login-logout-uri';
import { isNativeApp } from '../../utils/native-app';
import { ensureProtocol, queryObjToString } from '../../utils/uri';
import i18n from '../../i18n';

export const UNDER_MAINTENANCE_STATUS_CODES: number[] = [502];

export interface TokenHolder {
  ppToken: string;
  instanceCode: string;
  instanceUrl: string;
}

export interface ApiCallConfig extends AxiosRequestConfig {
  method: Method;
  url: string;
  query?: { [key: string]: string };
  doNotRemoveAuthCookie?: true;
  doNotAllowConcurrentRequests?: true;
}

export interface ClientError extends Error {
  statusCode: number;
  name: 'ClientError';
  message: string;
  data?: {
    code?: string;
    [others: string]: unknown;
  };
}

export interface ServerError extends Error {
  statusCode: number;
  name: 'ServerError';
  message: string;
  data?: {
    code?: string;
    [others: string]: unknown;
  };
}

export function isClientErr(err: any): err is ClientError {
  return err.name === 'ClientError';
}

export function isServerErr(err: any): err is ServerError {
  return err.name === 'ServerError';
}

let _rampartToken: string | null | undefined;

export function getRampartToken() {
  if (
    _rampartToken === undefined &&
    window.localStorage // keep this check, window.localStorage is null in android webview
  ) {
    try {
      _rampartToken = window.localStorage.getItem('rampart-token') ?? null;
    } catch (err) {
      _rampartToken = null;
    }
  }
  return _rampartToken;
}

// BoomError is standard API threwn error
interface BoomError {
  statusCode?: number;
  error?: string;
  message?: string;
  details?: Array<{
    message?: string;
  }>;
  data?: any;
}

const inProgress: Record<string, boolean> = {};

function getKey(cfg: ApiCallConfig) {
  return `${cfg.method} ${cfg.url}`;
}

function didStart(cfg: ApiCallConfig) {
  const key = getKey(cfg);
  if (inProgress[key] && cfg.doNotAllowConcurrentRequests) {
    throw new Error(`Attempt to duplicate request ${key}`);
  }
  inProgress[key] = true;
}

function didFinish(cfg: ApiCallConfig) {
  const key = getKey(cfg);
  delete inProgress[key];
}

function getViewport(): { width: string | number; height: string | number; dpr: number } {
  if (
    window.visualViewport &&
    typeof window.visualViewport.width === 'number' &&
    typeof window.visualViewport.height === 'number'
  ) {
    return {
      width: window.visualViewport.width.toFixed(0),
      height: window.visualViewport.height.toFixed(0),
      dpr: window.devicePixelRatio || 1,
    };
  }
  return {
    width: Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0),
    height: Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0),
    dpr: window.devicePixelRatio || 1,
  };
}

export async function ApiCall<T>(cfg: ApiCallConfig): Promise<AxiosResponse<T>> {
  cfg.headers ??= {};
  const isWidget = window.location.pathname.startsWith('/widgets/');
  cfg.headers['X-App-Name'] = isWidget ? 'pureprofile-app-widget' : 'pureprofile-app';
  cfg.headers['X-App-Lang'] = i18n.language;
  const vp = getViewport();
  cfg.headers['X-Viewport-Width'] = vp.width;
  cfg.headers['X-Viewport-Height'] = vp.height;
  cfg.headers['X-DPR'] = vp.dpr;
  if (isNativeApp()) {
    cfg.headers['X-PP-Native-App'] = true;
  }

  // attach ppToken if possible
  const ppToken = retrieveToken();
  if (ppToken && !cfg.headers['pp-token']) {
    cfg.headers['pp-token'] = ppToken;
  }

  // cleanup headers from nulls and undefineds
  cfg.headers = omitBy(cfg.headers, (val) => val == null);

  // set some defaults, withCredentials can be forced to false from above
  if (cfg.withCredentials == null) {
    cfg.withCredentials = true;
  }

  // ensure https
  cfg.url = ensureProtocol(cfg.url, 'https:');

  // process query object (not supported by axios itself)
  if (cfg.query) {
    const { url, query } = parseUrl(cfg.url);
    extend(query, cfg.query);
    cfg.url = `${url}?${queryObjToString(query)}`;
  }

  let result;
  try {
    didStart(cfg);
    result = await Axios.request<T>(cfg);
  } catch (_err) {
    const err = _err as AxiosError<BoomError>;
    const statusCode = err.response?.status ?? 0;

    const report = () => {
      if (err && typeof err === 'object') {
        (err as any).axiosCfg = pick(cfg, ['method', 'url']);
        (err as any).axiosResponse = { statusCode };
        submitData('error', {
          href: window.location.href,
          axiosErr: err,
        });
      }
    };

    const isLogin = window.location.pathname.startsWith('/login');
    const isRegistration = window.location.pathname.startsWith('/join');
    const isLoginOrRegistration = isLogin || isRegistration;

    if (statusCode === 401 && !cfg.doNotRemoveAuthCookie) {
      clearToken();
      if (!isLoginOrRegistration) {
        await goToLoginUrl().catch((loginUrlErr) => logError(loginUrlErr));
        throw err;
      }
    }

    if (statusCode === 500) {
      report();
      throw new Error('Oops, something went wrong');
    }

    if (UNDER_MAINTENANCE_STATUS_CODES.includes(statusCode)) {
      if (window.location.pathname !== '/maintenance') {
        browserNavigate('/maintenance');
      }
    }

    if (statusCode?.toString().startsWith('4')) {
      const errorMessage = err.response?.data?.message;
      const detailedMessage = err.response?.data?.details?.[0]?.message;
      const errorData = err.response?.data?.data;

      if (errorMessage) {
        const clientError = new Error(detailedMessage || errorMessage) as ClientError;
        clientError.statusCode = statusCode;
        clientError.name = 'ClientError';
        clientError.data = errorData;
        throw clientError;
      }
    }

    if (statusCode?.toString().startsWith('5')) {
      const errorMessage = err.response?.data?.message;
      const detailedMessage = err.response?.data?.details?.[0]?.message;
      const errorData = err.response?.data?.data;

      if (errorMessage) {
        const serverError = new Error(detailedMessage || errorMessage) as ServerError;
        serverError.statusCode = statusCode;
        serverError.name = 'ServerError';
        serverError.data = errorData;
        report();
        throw serverError;
      }
    }

    report();
    throw err;
  } finally {
    didFinish(cfg);
  }
  return result;
}
