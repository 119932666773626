/* eslint-disable no-process-env */

export interface AppConfig {
  authApiHost: string;
}

export type APP_ENV = 'production' | 'staging' | 'development' | 'test';

export const appEnv: APP_ENV = (process.env.REACT_APP_ENV as APP_ENV) || 'development';

export const isProduction = appEnv === 'production';

export const isLocalDevelopment = process.env.NODE_ENV !== 'production';

export function appConfig(): AppConfig {
  if (appEnv === 'production') {
    return {
      authApiHost: 'https://pp-auth-api.pureprofile.com',
    };
  }
  if (appEnv === 'staging') {
    return {
      authApiHost: 'https://staging-auth-api.pureprofile.com',
    };
  }
  if (appEnv === 'development') {
    return {
      authApiHost: 'https://dev-auth-api.pureprofile.com',
    };
  }
  throw new Error(`Unexpected appEnv=${appEnv}`);
}

export const { authApiHost } = appConfig();
export const RECAPTCHA_SITE_NAME = 'v2_tickbox';
export const RECAPTCHA_SITE_KEY = '6LcfRnsaAAAAAEwNgbSDCoNFCMIcEkCZgFfHkcLW';

export const imageUuidToUrl = (imageUuid: string) => {
  const bucketRoot = 'https://cloudcdn.pureprofile.com/image/upload/f_auto,q_auto/v1';
  let bucketName: string;
  if (appEnv === 'development') {
    bucketName = '__assets_develop__';
  } else if (appEnv === 'staging') {
    bucketName = '__assets_staging__';
  } else {
    bucketName = '__assets_master__';
  }
  return `${bucketRoot}/${bucketName}/${imageUuid}`;
};
