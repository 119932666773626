/* eslint-disable jsx-a11y/label-has-associated-control */

import { useCallback, FC } from 'react';

import Box from '@mui/material/Box';
import { CenterBox } from '../../utils/center-box';
import { SurveyBoxGenericFooterBtnWidth } from './survey-box-generic-footer';
import { PPButton } from '../pp-button';

export interface SubmitButtonProps {
  onClick?: (e: MouseEvent) => Promise<void> | void;
  label?: string;
}

function inIFrame() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

const DEFAULT_ON_CLICK = () => {
  if (inIFrame()) {
    if (window.parent && typeof window.parent.postMessage === 'function') {
      window.parent.postMessage('close-window', '*');
    }
  }
  window.close();
};

const DEFAULT_LABEL = `Close preview`;

export const CloseButton: FC<SubmitButtonProps> = ({ onClick, label }) => {
  const defaultOnClick = useCallback(DEFAULT_ON_CLICK, []);
  return (
    <CenterBox>
      <Box sx={{ flexGrow: 1, maxWidth: SurveyBoxGenericFooterBtnWidth, margin: '10px' }}>
        <PPButton onClick={onClick || defaultOnClick} sx={{ width: '100%' }}>
          {label || DEFAULT_LABEL}
        </PPButton>
      </Box>
    </CenterBox>
  );
};
