import isPlainObject from 'lodash/isPlainObject';
import isArray from 'lodash/isArray';
import { AdminApiSurveyTheme } from '../declarations/admin-api';
import { FooterLink } from '../survey-container';
import { PartialSurveyToolStyleObject } from './style-declaration';
import { ensureHttps } from '../utils/string';

export function adminApiSurveyThemeGetFooterLinks(theme: AdminApiSurveyTheme | null | undefined): FooterLink[] {
  const arr: FooterLink[] = [];
  if (theme == null) {
    return arr;
  }
  for (const num of [1, 2, 3, 4]) {
    const t: any = theme;
    const label = t[`footer_label_${num}`];
    const text = t[`footer_text_${num}`];
    const url = t[`footer_url_${num}`];
    if (text && url) {
      arr.push({ label, text, url });
    }
  }
  return arr;
}

export function stripNulls<T>(input: T): T {
  const obj: any = input;
  for (const key of Object.keys(obj)) {
    if (isPlainObject(obj[key]) || isArray(obj[key])) {
      obj[key] = stripNulls(obj[key]);
      continue;
    }
    if (obj[key] == null || obj[key] === '') {
      delete obj[key];
      continue;
    }
  }
  return obj;
}

const maybeImageUrl = (url: string | undefined) => (url ? `url("${ensureHttps(url)}")` : undefined);

export function adminApiSurveyThemeToSurveyToolStyleObject(
  t: AdminApiSurveyTheme | null | undefined
): PartialSurveyToolStyleObject {
  if (t == null) {
    return {};
  }
  const result: PartialSurveyToolStyleObject = {
    surveyContainer: {
      // backgroundImage: maybeImageUrl(t.main_view_root_background_image),
      color: t.main_root_color,
      fontFamily: t.main_root_font_family,
      fontStyle: t.main_root_font_style as any,
      fontWeight: t.main_root_font_weight as any,
    },
    surveyContainerLogoTop: {
      background: t.survey_box_footer_logo_background_color,
      padding: t.survey_box_footer_logo_padding,
    },
    surveyBackgroundImage: {
      backgroundImage: maybeImageUrl(t.main_view_root_background_image),
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      height: '350px',
      left: 0,
      position: 'absolute',
      top: 0,
      width: '100%',
      zIndex: -1,
    },
    headerRoot: {
      backgroundColor: t.header_root_css_background_color,
      color: t.header_root_css_color,
    },
    headerTitle: {
      fontSize: t.header_root_survey_title_font_size,
    },
    headerBalanceContainer: {
      background: t.header_balance_background,
      fontFamily: t.header_balance_font_family,
      height: t.header_balance_height,
      'vertical-align': t.header_balance_verical_align,
      width: t.header_balance_width,
      ':before': {
        content: t.header_balance_before_content,
      },
    },
    surveyBoxHeaderContainer: {
      backgroundColor: t.survey_box_header_background_color,
      backgroundImage: maybeImageUrl(t.survey_box_header_background_image_url),
      color: t.survey_box_header_color,
      '@media (min-width: 768px) and (min-height: 718px)': {
        color: t.survey_box_header_color,
      },
    },
    surveyBoxHeaderOverlay: {
      backgroundColor: t.survey_box_header_background_image_overlay_color,
      opacity: t.survey_box_header_background_image_overlay_opacity,
    },
    surveyBoxHeaderSubtext: {
      color: t.survey_box_header_subtext_color,
      fontSize: t.survey_box_header_subtext_font_size,
    },
    surveyBoxHeaderOfferImage: {
      imageUrl: t.survey_box_header_offer_image_url,
    },
    surveyBoxProgressBarComplete: {
      backgroundColor: t.survey_box_progress_complete_background_color,
    },
    surveyBoxProgressBarIncomplete: {
      backgroundColor: t.survey_box_progress_incomplete_background_color,
    },
    surveyBoxFooterFooter: {
      backgroundColor: t.survey_box_footer_background_color,
    },
    surveyBoxFooterPercentage: {
      alignSelf: t.survey_box_footer_percentage_align_self as any,
      display: t.survey_box_footer_percentage_display as any,
      fontSize: t.survey_box_footer_percentage_font_size,
      textAlign: t.survey_box_footer_percentage_text_align as any,
      width: t.survey_box_footer_percentage_width,
    },
    surveyControlSelectClickBarListRowDefault: {
      background: t.survey_control_clickbar_row_default_background,
      boxShadow: t.survey_control_clickbar_row_default_box_shadow,
      color: t.survey_control_clickbar_row_default_color,
      border: t.survey_control_clickbar_row_default_border,
      borderRadius: t.survey_control_clickbar_row_default_border_radius,
      fontSize: t.survey_control_clickbar_row_default_font_size,
      fontWeight: t.survey_control_clickbar_row_default_font_weight,
      ':active': {
        background: t.survey_control_clickbar_row_default_background_active,
        color: t.survey_control_clickbar_row_default_color_active,
      },
    },
    surveyControlCustomScaleIconActive: {
      background: t.survey_control_clickbar_row_active_background,
      color: t.survey_control_clickbar_row_active_color,
    },
    surveyControlOtherInputActive: {
      background: t.survey_control_clickbar_row_active_background,
    },
    surveyControlSelectClickBarInputFocus: {
      ':focus': {
        outline: t.survey_control_clickbar_row_active_background,
        border: `2px solid ${t.survey_control_clickbar_row_active_background}`,
        '::-webkit-input-placeholder': { color: 'transparent' },
        ':-ms-input-placeholder': { color: 'transparent' },
        '::placeholder': { color: 'transparent' },
      },
    },
    surveyControlSelectClickBarListRowActive: {
      background: t.survey_control_clickbar_row_active_background,
      color: t.survey_control_clickbar_row_active_color,
    },
    surveyControlSelectClickBarIconActive: {
      backgroundImage: t.survey_control_clickbaricon_active_background_image,
      backgroundPosition: t.survey_control_clickbaricon_active_background_position,
    },
    surveyControlSelectDropdownDefault: {
      background: t.survey_control_dropdown_default_background,
    },
    surveyControlSelectDropdownRowActive: {
      ':hover': {
        background: t.survey_control_dropdown_row_active_background_hover,
        color: t.survey_control_dropdown_row_active_color_hover,
      },
    },
    surveyControlFrequencyPeriodSelectPeriodActive: {
      background: t.survey_control_freq_period_active_background,
      color: t.survey_control_freq_period_active_color,
    },
    surveyControlFrequencyPeriodProgressBar: {
      background: t.survey_control_freq_period_progress_background,
      color: t.survey_control_freq_period_progress_color,
    },
    surveyControlFrequencyPeriodValueLabel: {
      color: t.survey_control_freq_period_active_background,
    },
    surveyControlLocationResultsListRow: {
      color: t.survey_control_location_row_color,
      ':hover': {
        background: t.survey_control_location_row_hover_background,
        color: t.survey_control_location_row_hover_color,
      },
    },
    surveyControlMostLeastLeastAnswerActive: {
      background: t.survey_control_most_least_least_active_background,
      color: t.survey_control_most_least_least_active_color,
    },
    surveyControlMostLeastMostAnswerActive: {
      background: t.survey_control_most_least_most_active_background,
      color: t.survey_control_most_least_most_active_color,
    },
    surveyControlMultiSelect: {
      color: t.survey_control_multi_color,
    },
    surveyControlMultiImageListRowDefault: {
      ':active': {
        border: t.survey_control_multi_image_row_default_border_active,
      },
      ':hover': {
        border: t.survey_control_multi_image_row_default_border_hover,
      },
    },
    surveyControlMultiImageListRowActive: {
      border: t.survey_control_multi_image_row_active_border,
      position: t.survey_control_multi_image_row_active_position as any,
      ':before': {
        borderLeft: t.survey_control_multi_image_row_active_before_border_left,
        borderTop: t.survey_control_multi_image_row_active_before_border_top,
        content: t.survey_control_multi_image_row_active_before_content,
        position: t.survey_control_multi_image_row_active_before_position as any,
        right: t.survey_control_multi_image_row_active_before_right,
        top: t.survey_control_multi_image_row_active_before_top,
      },
      ':after': {
        borderRight: t.survey_control_multi_image_row_active_after_border_right,
        borderTop: t.survey_control_multi_image_row_active_after_border_top,
        color: t.survey_control_multi_image_row_active_after_color,
        content: t.survey_control_multi_image_row_active_after_content,
        fontFamily: t.survey_control_multi_image_row_active_after_font_family,
        position: t.survey_control_multi_image_row_active_after_position as any,
      },
    },
    surveyControlSelectRadio: {
      color: t.survey_control_radio_color,
    },
    surveyControlRankingIcon: {
      color: t.survey_control_ranking_icon_color,
    },
    surveyControlRankingSelectedOrder: {
      background: t.survey_control_ranking_select_order_background,
      color: t.survey_control_ranking_select_order_color,
    },
    surveyControlRankingSelectedRow: {
      border: t.survey_control_ranking_select_row_border,
    },
    footerRoot: {
      background: t.survey_box_footer_logo_background_color,
    },
    footerLink: {
      color: t.footer_css_link_color,
      textDecoration: t.footer_css_link_text_decoration_hover,
      ':hover': {
        color: t.footer_css_link_color,
        textDecoration: t.footer_css_link_text_decoration_hover,
      },
      ':visited': {
        color: t.footer_css_link_color,
        textDecoration: t.footer_css_link_text_decoration_hover,
      },
    },
    footerLogo: {
      imageUrl: t.survey_box_footer_logo_image_url,
    },
  };
  return stripNulls(result);
}
