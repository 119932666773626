import { ComponentType, FC, useEffect, useState } from 'react';
import flatten from 'lodash/flatten';
import isEmpty from 'lodash/isEmpty';
import { retrieveToken, storeToken } from '../auth-token';
import { fetchDomainInfo } from '../store/reducers/domain-info-reducer';
import { refreshProfile } from '../store/actions/user-profile-actions';
import { loadInstanceInfo } from '../store/actions/instance-info-action';
import { loadUserBalance } from '../store/actions/user-balance-actions';
import { setSelectedPanel } from '../store/reducers/panel-info-reducer';
import Error500 from '../components/error500/error500';
import { useAppDispatch, useAppSelector } from '../store';
import { loadSurveyTheme } from '../store/actions/survey-theme-actions';
import { logError } from '../utils/log';
import GenericLoader from '../survey-tool/components/generic-loader';
import { useAppNavigate } from '../utils/browser-navigate';
import { ROUTE_TYPE } from '../store/actions/action-types';
import { RouteTypeReducerState } from '../store/reducers/route-type-reducer';
import { useAppTranslation } from '../i18n';
import { preserveWindowQuery } from '../utils/uri';

const usePrivateOrPublic = (routeType: RouteTypeReducerState) => {
  const { i18n } = useAppTranslation();
  const dispatch = useAppDispatch();
  const currentRouteType = useAppSelector((s) => s.routeType);
  if (currentRouteType !== routeType) dispatch({ type: ROUTE_TYPE, routeType });

  // any vars
  const domainInfo = useAppSelector((state) => state.domainInfo);

  // private vars
  const [ppToken] = useState<string | null>(retrieveToken);
  const userProfile = useAppSelector((state) => state.userProfile);
  const userBalance = useAppSelector((state) => state.userBalance);
  const instanceInfo = useAppSelector((state) => state.instanceInfo);
  const panelInfo = useAppSelector((state) => state.panelInfo);
  const surveyThemeLoaded = useAppSelector((state) => state.surveyThemeLoaded);

  // public vars
  const [loadingPrivate, setLoadingPrivate] = useState<boolean>(true);
  const [loadingPublic, setLoadingPublic] = useState<boolean>(true);
  const [isPrivate, setIsPrivate] = useState<boolean>(false);
  const [isPublic, setIsPublic] = useState<boolean>(false);
  const [e, setE] = useState<Error | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (e) {
      setError(e.message);
    }
  }, [e]);

  useEffect(() => {
    dispatch(fetchDomainInfo()).catch((err) => setE(err));
  }, [dispatch]);

  useEffect(() => {
    if (domainInfo && !isEmpty(domainInfo)) {
      const currentLanguage = i18n.language;

      const allLanguages = flatten(domainInfo?.panels?.map((p) => p.languages)).filter((l) => l != null);
      if (allLanguages.length === 0) {
        if (currentLanguage !== 'en') {
          i18n.changeLanguage('en');
        }
        return;
      }

      const hasCurrentLanguage = allLanguages.find((l) => l.language_code === currentLanguage);
      if (!hasCurrentLanguage) {
        const hasDefault = allLanguages.find((l) => l.is_default);
        const hasEnglish = allLanguages.find((l) => l.language_code === 'en' || l.language_code.startsWith('en-'));
        i18n.changeLanguage(hasDefault?.language_code ?? hasEnglish?.language_code ?? allLanguages[0].language_code);
      }
    }
  }, [i18n, domainInfo]);

  useEffect(() => {
    if (ppToken) {
      setLoadingPublic(false);
      if (!userProfile || userProfile.ppToken !== ppToken) {
        if (ppToken) {
          storeToken(ppToken);
        }
        dispatch(refreshProfile()).catch((err) => setE(err));
      }
    } else {
      // we don't have ppToken
      setLoadingPrivate(false);
    }
  }, [dispatch, ppToken, userProfile]);

  useEffect(() => {
    if (userProfile) {
      // userProfile was loaded
      if (!userBalance) {
        dispatch(loadUserBalance()).catch((err) => setE(err));
      } else if (
        (userProfile.instanceKey && (!instanceInfo || instanceInfo.key !== userProfile.instanceKey)) ||
        (userProfile.panelKey && (!panelInfo || panelInfo.guid !== userProfile.panelKey))
      ) {
        // this will also load panelInfo
        dispatch(loadInstanceInfo(userProfile)).catch((err) => setE(err));
      } else if (!surveyThemeLoaded) {
        dispatch(loadSurveyTheme(panelInfo)).catch((err) => setE(err));
      }
    }
  }, [dispatch, userProfile, userBalance, instanceInfo, panelInfo, surveyThemeLoaded]);

  useEffect(() => {
    if (ppToken && userProfile && userBalance && instanceInfo && panelInfo) {
      setLoadingPrivate(false);
      setIsPrivate(true);
    }
  }, [ppToken, userProfile, userBalance, instanceInfo, panelInfo]);

  useEffect(() => {
    if (domainInfo && !loadingPrivate) {
      // domainInfo was loaded
      if (!panelInfo) {
        const firstPanel = domainInfo.panels?.[0];
        if (firstPanel) {
          dispatch(setSelectedPanel(firstPanel.uuid)).catch((err) => setE(err));
        } else {
          setLoadingPublic(false);
          setE(new Error(`Unrecognized domain: ${window.location.hostname}`));
        }
      }
    }
  }, [dispatch, domainInfo, loadingPrivate, panelInfo]);

  useEffect(() => {
    if (domainInfo && !loadingPrivate && instanceInfo && panelInfo) {
      setLoadingPublic(false);
      setIsPublic(true);
    }
  }, [domainInfo, loadingPrivate, instanceInfo, panelInfo]);

  return {
    loading: loadingPrivate || loadingPublic,
    isPrivate,
    isPublic: isPrivate ? false : isPublic,
    error,
  };
};

export interface RouteProps {
  component: ComponentType;
}

export const PrivateRoute: FC<RouteProps> = ({ component }) => {
  const navigate = useAppNavigate();
  const { loading, isPrivate, isPublic, error } = usePrivateOrPublic('private');
  if (error) {
    return <Error500 title={error} />;
  }
  if (loading) {
    return <GenericLoader />;
  }
  if (isPublic) {
    navigate(preserveWindowQuery('/login', { redirect: `https://${window.location.host}${window.location.pathname}` }));
    return null;
  }
  if (!isPrivate) {
    logError(`Invalid state in PrivateRoute`);
    navigate(preserveWindowQuery('/error'));
    return null;
  }
  const Component = component as any;
  return <Component />;
};

export const PublicRoute: FC<RouteProps> = ({ component }) => {
  const navigate = useAppNavigate();
  const { loading, isPrivate, isPublic, error } = usePrivateOrPublic('public');
  if (error) {
    return <Error500 title={error} />;
  }
  if (loading) {
    return <GenericLoader />;
  }
  if (isPrivate) {
    navigate(preserveWindowQuery('/feed'));
    return null;
  }
  if (!isPublic) {
    logError(`Invalid state in PublicRoute`);
    navigate(preserveWindowQuery('/error'));
    return null;
  }
  const Component = component as any;
  return <Component />;
};

export const AnyRoute: FC<RouteProps> = ({ component }) => {
  const { loading, error } = usePrivateOrPublic('any');
  if (error) {
    return <Error500 title={error} />;
  }
  if (loading) {
    return <GenericLoader />;
  }
  const Component = component as any;
  return <Component />;
};
