import { AnyAction, ThunkAction } from '@reduxjs/toolkit';
import { DomainInfo, getDomainInfo } from '../api/auth-api';
import { browserStorageSet } from '../../utils/storage';
import { RootState } from '../store';

const DOMAIN_INFO_LOADED = 'DOMAIN_INFO_LOADED';

export type DomainInfoState = DomainInfo | null;

export function domainInfoReducer(state: DomainInfoState = null, action: AnyAction): DomainInfoState {
  switch (action.type) {
    case DOMAIN_INFO_LOADED:
      browserStorageSet('domainInfo', action.domainInfo, 'local-storage');
      return action.domainInfo;
    default:
      return state;
  }
}

export const fetchDomainInfo =
  (hostname?: string): ThunkAction<Promise<DomainInfo>, RootState, void, any> =>
  async (dispatch, getState) => {
    const domainInfo = await getDomainInfo(hostname || window.location.hostname);

    const isLivetribeUrl = window.location.pathname.startsWith('/livetribe-reset-password'); // PUR-101
    if (isLivetribeUrl) {
      domainInfo.panels = domainInfo.panels.filter((p) => p.code === 'pp-nz');
    }

    dispatch({ type: DOMAIN_INFO_LOADED, domainInfo });
    return domainInfo;
  };
