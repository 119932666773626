import { getType } from 'typesafe-actions';
import cloneDeep from 'lodash/cloneDeep';
import * as types from '../actions/action-types';
import { UserProfile } from '../../survey-tool/declarations/auth-api';
import { socialNetworkConnected, socialNetworkDisconnected } from '../actions/user-profile-actions';
import { ReferralState } from '../../declarations/interfaces/ReferralState';

export type UserState = UserProfile & ReferralState;

/*
type UserReducerAction = ReturnType<typeof socialNetworkConnected> |
  ReturnType<typeof socialNetworkDisconnected> |
  ReturnType<typeof connectSocialNetwork> |
  ReturnType<typeof disconnectSocialNetwork>;
*/

export default function userReducer(state: UserState = null as any as UserState, action: any): UserState {
  let userProfile = null;
  switch (action.type) {
    // NOTE: do not extend ...state because props can be deleted from profile,
    // load method always returns us the whole profile
    case types.LOAD_USER_PROFILE_SUCCESS:
      return { ...action.userProfile };

    // NOTE: do not extend ...state because props can be deleted from profile,
    // save method always returns us the whole profile
    case types.SAVE_USER_PROFILE_SUCCESS:
      return { ...state, ...action.userProfile };

    case types.VALIDATE_SMARTFUEL_CARD:
      return { ...state, metadata: { smartFuelCard: action.smartFuelCard } };

    case getType(socialNetworkConnected):
      userProfile = cloneDeep(state);
      userProfile.oauthConnections ??= [];
      if (!userProfile.oauthConnections.includes(action.payload.socialNetwork)) {
        userProfile.oauthConnections.push(action.payload.socialNetwork);
      }
      return { ...state, ...userProfile };

    case getType(socialNetworkDisconnected):
      userProfile = cloneDeep(state);
      userProfile.oauthConnections ??= [];
      userProfile.oauthConnections = userProfile.oauthConnections.filter(
        (connection) => connection !== action.payload.socialNetwork
      );
      return { ...state, ...userProfile };

    case types.ELIGIBILITY_TO_REFER_ENABLED:
      return { ...state, ...{ isEligibleToRefer: true } };

    default:
      return state;
  }
}
