import { useRef, useEffect } from 'react';
import { useNavigate, To } from 'react-router-dom';

export type NavigateFunction = (absolutePath: string) => void;

export const browserNavigate: NavigateFunction = (absolutePath: string) => {
  window.location.href = absolutePath;
};

// this should be used in favor of useNavigate until https://github.com/remix-run/react-router/issues/7634 is fixed
export const useAppNavigate = () => {
  const navigate = useNavigate();
  const navigateRef = useRef({ navigate });

  useEffect(() => {
    navigateRef.current.navigate = navigate;
  }, [navigate]);

  const appNavigate = useRef((to: To) => {
    if (!to || typeof to !== 'string') {
      throw new Error(`appNavigate called without an argument or argument is not a string: ${to}`);
    }
    if (to.startsWith('https:') || to === '/' || to.includes('?')) {
      // navigation using query variables should occur by a full page refresh
      window.location.href = to;
      return;
    }
    return navigateRef.current.navigate(to);
  }).current;

  return appNavigate;
};
